import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ setCaptchaValue, recaptchaRef }) => {
  // final-changes
  return (
    <div>
      <ReCAPTCHA
        sitekey="6Ldsy08eAAAAAKaPjNqd880Hd2dO5hbCosFtgA0d"
        onChange={(val) => {
          setCaptchaValue(val);
        }}
        ref={recaptchaRef}
        required
      />
    </div>
  );
};

export default Captcha;
