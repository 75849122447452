import { useWeb3React } from "@web3-react/core";
import { useState, useEffect } from "react";
import { TokenUri } from "../../components/myContract";
import {
  useMarketplaceWriteContract,
  useNftContractWrite,
} from "../../hooks/useContract";
import {
  getMarketplaceAddress,
  getNftAddress,
} from "../../utils/addressHelpers";
import { ApproveNft, CreateOrder } from "../../utils/contractFunctions";
import { ReactComponent as CloseIcon } from "../../assets/images/closeIcon.svg";
import { useHistory } from "react-router-dom";

import Web3 from "web3";
import Modal from "../../components/Modal";
import FormInput from "../../components/FormInput1";
import CustomButton from "../../components/CustomButton";
import axios from "axios";

const WalletCard = ({ tokenId, setTotalNfts, setTxLoading, txLoading }) => {
  const history = useHistory();
  const nftAddress = getNftAddress();
  const { account } = useWeb3React();
  const nftContractw = useNftContractWrite(nftAddress);
  const [tokenUri, setTokenUri] = useState(null);
  const [nftStatus, setnftStatus] = useState(false);
  const marketplaceAddress = getMarketplaceAddress();
  const [nftImageUrl, setNftImageUrl] = useState(null);

  const mpContractw = useMarketplaceWriteContract();
  const [orderOfAssetIdValue, setOrderOfAssetIdValue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nftOrderPrice, setNftOrderPrice] = useState();
  const [walletNfts, setWalletNfts] = useState(null);
  const web3 = new Web3(window.ethereum);

  const getStatus = async () => {
    if (tokenId) {
      await nftContractw.methods
        .isApprovedForAll(account, marketplaceAddress)
        .call()
        .then((response) => {
          setnftStatus(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    getStatus();
  }, [nftContractw, nftStatus]);

  const getTokenUri = async (e) => {
    if (tokenId) {
      await TokenUri(nftContractw, tokenId)
        .then((res) => {
          setTokenUri(res);
        })
        .catch((error) => {
          console.log("tokenuri error", error);
        });
    }
  };

  function trimAdd(add = "0x00", l = 5) {
    return (
      String(add).slice(0, 2) +
      String(add).slice(2, 2 + l) +
      "..." +
      String(add).slice(add.length - l, add.length)
    );
  }

  const getTotalNfts = async () => {
    await nftContractw.methods
      .balanceOf(account)
      .call()
      .then((res) => {
        setTotalNfts(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let isOrderbyAssetIdExist;
  if (orderOfAssetIdValue) {
    isOrderbyAssetIdExist = parseInt(orderOfAssetIdValue.price);
  }

  const CreateOrderSubmit = async (e) => {
    e.preventDefault();

    if (nftStatus && tokenId && !isOrderbyAssetIdExist) {
      await CreateOrder(
        mpContractw,
        nftAddress,
        tokenId,
        nftOrderPrice,
        account,
        setTxLoading
      );
      setNftOrderPrice();
      await getTotalNfts();
    }
  };

  const fetchWalletNftsbyTokenId = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    axios
      .get(`https://emillionsnft.com/api/minted/${tokenId}`, {
        headers: headers,
      })
      .then((res) => {
        setWalletNfts(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const ApproveAllNfts = async () => {
    await ApproveNft(nftContractw, marketplaceAddress, account, setTxLoading);
    await fetchWalletNftsbyTokenId();
    history.go(0);
  };

  useEffect(() => {
    fetchWalletNftsbyTokenId();
  }, []);

  useEffect(() => {
    const getOrderDetailsOfAssetId = async () => {
      const fetchOrderDetails = await mpContractw.methods
        .orderByAssetId(nftAddress, tokenId)
        .call();
      setOrderOfAssetIdValue(fetchOrderDetails);
    };
    getOrderDetailsOfAssetId();
    getTokenUri();
  }, [tokenUri, nftImageUrl, setTotalNfts, nftStatus]);

  return (
    <>
      <div className="artwork-card">
        <div
          className="background-image"
          style={{ backgroundImage: `url(${walletNfts?.ipfsLink})` }}
        ></div>
        <div className="card-info">
          <div className="card-title">{walletNfts?.title}</div>
          <div className="card-sub-title">{walletNfts?.collections}</div>
          {walletNfts ? (
            <div className="card-para">
              {walletNfts.description.length > 80
                ? `${walletNfts.description.slice(0, 80)}...`
                : walletNfts.description}{" "}
            </div>
          ) : (
            ""
          )}
          <div className="card-price">
            {/* <button className="btn" >Sold</button> */}
            {walletNfts ? <p>{walletNfts.nftOrderPrice} ETH</p> : ""}
          </div>

          <div className="txn-details">
            {nftStatus ? (
              <button className="btn" onClick={() => setIsModalOpen(true)}>
                {txLoading ? "Creating..." : "Create Order"}
              </button>
            ) : (
              <button className="btn" onClick={ApproveAllNfts}>
                {txLoading ? "Approving..." : "Approve NFT"}
              </button>
            )}
          </div>
        </div>

        {isModalOpen && (
          <Modal>
            <form onSubmit={CreateOrderSubmit} className="collection_formModal">
              <h2>({trimAdd(account)})</h2>
              <FormInput
                name="priceinEth"
                value={nftOrderPrice}
                label="PriceInEth"
                handleChange={(e) => setNftOrderPrice(e.target.value)}
                max="20"
              />
              <CustomButton type="submit" size="sm">
                Create Order
              </CustomButton>
            </form>
            <div className="mod-closeIcon">
              <CloseIcon onClick={() => setIsModalOpen(false)} />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default WalletCard;
