import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/style/collection-item.css";
import Modal from "../components/Modal1";
import FormInput from "./FormInput1";
import {
  ApproveNft,
  TokenUri,
} from "./myContract";
import {
  useMarketplaceWriteContract,
  useNftContractWrite,
} from "../hooks/useContract";
import { useWeb3React } from "@web3-react/core";
import { getMarketplaceAddress, getNftAddress } from "../utils/addressHelpers";
import LoadingSpinner from "./loading-spinner";
import { CreateOrder } from "../utils/contractFunctions";
import Web3 from "web3";

const CollectionItem = ({ tokenId, setTotalNfts }) => {
  const { account } = useWeb3React();
  const mpContractw = useMarketplaceWriteContract();
  const nftAddress = getNftAddress();
  const marketplaceAddress = getMarketplaceAddress();

  const nftContractw = useNftContractWrite(nftAddress);
  const [nftStatus, setnftStatus] = useState(0);
  const [orderOfAssetIdValue, setOrderOfAssetIdValue] = useState(null);
  const [tokenUri, setTokenUri] = useState(null);
  const [nftImageUrl, setNftImageUrl] = useState(null);
  const [nftMetadata, setNftMetadata] = useState([]);
  const [txLoading, setTxLoading] = useState(false);

  const getStatus = async () => {
    await nftContractw.methods
      .getApproved(tokenId)
      .call()
      .then((response) => {
        if (response === marketplaceAddress) {
          setnftStatus(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStatus();
  }, [nftContractw, nftStatus]);

  const [modal, setModal] = useState(false);

  const [_createOrder, set_createOrder] = useState({
    priceinEth: "",
    expireAt: "180",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    set_createOrder({ ..._createOrder, [name]: value });
  };

  function trimAdd(add = "0x00", l = 5) {
    return (
      String(add).slice(0, 2) +
      String(add).slice(2, 2 + l) +
      "..." +
      String(add).slice(add.length - l, add.length)
    );
  }

  const getTimestamp = () => {
    const timestamp = Date.now();
    return parseInt(timestamp / 1000);

    // current timestamp
  };

  const getTotalNfts = async () => {
    await nftContractw.methods
      .balanceOf(account)
      .call()
      .then((res) => {
        setTotalNfts(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let isOrderbyAssetIdExist;
  if (orderOfAssetIdValue) {
    isOrderbyAssetIdExist = parseInt(orderOfAssetIdValue.price);
  }

  const CreateOrderSubmit = async (e) => {
    e.preventDefault();
    // setModal(false)
    const { priceinEth } = _createOrder;
    const web3 = new Web3(window.ethereum);
    const weiPrice = web3.utils.toWei(priceinEth.toString());

    const assetId = tokenId;

    if (nftStatus && !isOrderbyAssetIdExist) {
      await CreateOrder(
        mpContractw,
        nftAddress,
        assetId,
        weiPrice,
        account,
        setTxLoading
      );
      set_createOrder({ priceinEth: "", expireAt: "180" });
      await getTotalNfts();
    }
  };

  const ApproveMyNft = async (e) => {
    e.preventDefault();
    await ApproveNft(
      nftContractw,
      marketplaceAddress,
      tokenId,
      account,
      setTxLoading
    );
    await getStatus();
  };

  useEffect(() => {
    const getTokenUri = async (e) => {
      await TokenUri(nftContractw, tokenId)
        .then((res) => {
          setTokenUri(res);
        })
        .catch((error) => {
          console.log("tokenuri error", error);
        });
    };

    if (tokenUri) {
      const fetchNftData = async () => {
        axios
          .get(`https://ipfs.io/ipfs/${tokenUri.slice(7)}`)
          .then((response) => {
            setNftMetadata(response.data);
            setNftImageUrl(response.data.image);
          });
      };
      fetchNftData();
    }

    const getOrderDetailsOfAssetId = async () => {
      const fetchOrderDetails = await mpContractw.methods
        .orderByAssetId(nftAddress, tokenId)
        .call();
      setOrderOfAssetIdValue(fetchOrderDetails);
    };
    getOrderDetailsOfAssetId();
    getTokenUri();
  }, [tokenUri, nftImageUrl, setTotalNfts]);

  return (
    <div className="collection-item">
      {modal && (
        <Modal handleClose={() => setModal(false)}>
          <form onSubmit={CreateOrderSubmit} className="collection_formModal">
            <h2>({trimAdd(nftAddress)})</h2>

            <FormInput
              name="priceinEth"
              value={_createOrder.priceinEth}
              label="priceinEth (uint256)"
              handleChange={onInputChange}
            />

            <div className="date-picker">
              <label className="form-input-label">expiresAt</label>
              <select
                defaultValue={_createOrder.expireAt}
                onChange={(e) =>
                  set_createOrder({ ..._createOrder, expireAt: e.target.value })
                }
              >
                <option value="1">1 day</option>
                <option value="3">3 day</option>
                <option value="7">1 week</option>
                <option value="90">3 month</option>
                <option value="180">6 month</option>
              </select>
            </div>
            <button type="submit" className="get-btn">
              Create Order
            </button>
          </form>
        </Modal>
      )}

      <div className="collection-img">
        <p>{tokenId}</p>
        {nftImageUrl && (
          <img src={`https://ipfs.io/ipfs/${nftImageUrl.slice(7)}`} alt="" />
        )}
      </div>
      <div className="collection-footer">
        {parseInt(nftStatus) ? (
          <span
            className="collection-btn cf-order"
            onClick={() => setModal(!modal)}
          >
            {txLoading ? "Creating..." : "Create Order"}
          </span>
        ) : (
          <span className="collection-btn cf-approve" onClick={ApproveMyNft}>
            {txLoading ? "Approving..." : "Approve Nft"}
          </span>
        )}
      </div>
      {txLoading && <LoadingSpinner />}
    </div>
  );
};

export default CollectionItem;
