import About from '../pages/about';
import ArtistMain from '../pages/artists';
import Artist from '../pages/artists/artist-component';
import Featured from '../pages/featured';
import Home from '../pages/home';
import Legacy from '../pages/legacy';
import LegacyDetails from '../pages/legacy/legacyDetails';
import Login from '../pages/login';
import ResetPassword from '../pages/reset-password';
import SignUp from '../pages/sign-up';


const routes = [
    {
        path: `/`,
        component: Home,
        exact: true,
        breadcrumbName: 'Home'
    },
    {
        path: `/artist`,
        component: ArtistMain,
        breadcrumbName: 'Artists',
        routes: [
            {
                path: '/artist/:id',
                component: Artist,
                breadcrumbName: ''
            }
        ]
    },
    {
        path: `/featured`,
        component: Featured,
        breadcrumbName: 'Featured',
    },
    {
        path: `/about`,
        component: About,
        breadcrumbName: 'About',
    },
    {
        path: null,
        component: Legacy,
        breadcrumbName: 'Art',
        routes: [
            {
                path: '/art/legacy',
                component: Legacy,
                breadcrumbName: 'Legacy',
                routes: [
                    {
                        path: '/art/legacy/:id',
                        component: LegacyDetails,
                        breadcrumbName: ''
                    }
                ]
            },
            {
                path: '/art/story',
                component: Legacy,
                breadcrumbName: 'Story'
            },
            {
                path: '/art/imagination',
                component: Legacy,
                breadcrumbName: 'Imagination'
            },
        ]
    },
    {
        path: `/login`,
        component: Login,
        breadcrumbName: 'Artist Login',
    },
    

    
    // {
    //     path: `/category`,
    //     component: null,
    //     breadcrumbName: 'Categories',
    //     routes: [
    //         {
    //             path: '/categories/legacy',
    //             component: Legacy,
    //             breadcrumbName: 'Legacy'
    //         },
    //         {
    //             path: '/categories/story',
    //             // component: Story,
    //             // breadcrumbName: 'Story'
    //         },
    //         {
    //             path: '/categories/imagination',
    //             // component: Imagination,
    //             // breadcrumbName: 'Imagination'
    //         },
    //     ]
    // }
];


export default routes;