import sample from "lodash/sample";

// Array of available nodes to connect to
// final-changes
export const nodes = [
  "https://holy-red-violet.quiknode.pro/ad96881c9fd1953aea62e8d78d4f16420a32d82e",
];

const getNodeUrl = () => {
  return sample(nodes);
};

export default getNodeUrl;
