import React, { useState, useEffect } from "react";
import "../../assets/style/marketplace-item.css";
import FormInput from "../../components/FormInput1";
import Modal from "../../components/Modal1";
import Web3 from "web3";
import {
  useMarketplaceWriteContract,
  useNftContract,
  useNftContractWrite,
} from "../../hooks/useContract";
import {
  BidByOrderid,
  SafePlaceBid,
  TokenUri,
} from "../../components/myContract";
import { useWeb3React } from "@web3-react/core";

import axios from "axios";
import { getMarketplaceAddress } from "../../utils/addressHelpers";
import LoadingSpinner from "../../components/loading-spinner";
import Tab from "../../components/tab";

const MarketPlaceItem = ({ match }) => {
  const { nftAddress, assetId } = match.params;
  const [nftmetaData, setNftmetaData] = useState([]);
  const [modal, setModal] = useState(false);
  const { account } = useWeb3React();
  const web3 = new Web3(window.ethereum);
  const mpContractw = useMarketplaceWriteContract();
  const marketPlaceAddress = getMarketplaceAddress();

  const nftContract = useNftContract();
  const nftContractw = useNftContractWrite(nftAddress);

  const [highestBidValue, setHighestBidValue] = useState("");
  const [tokenUri, setTokenUri] = useState(null);
  const [nftImageUrl, setNftImageUrl] = useState(null);
  const [disableBid, setDisableBid] = useState(false);
  const [nftExpiresDate, setNftExpiresDate] = useState(0);
  const [orderDetails, setOrderDetails] = useState(null);
  const [txLoading, setTxLoading] = useState();
  const [fetchedBids, setFetchedBids] = useState([]);

  const [_spBid, set_spBid] = useState({
    priceinEth: "",
    expireAt: "180",
  });

  const [_orderDetails, set_orderDetails] = useState({
    nftAddress: nftAddress,
    assetId: assetId,
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    set_spBid({ ..._spBid, [name]: value });
  };

  const getTimestamp = () => {
    // current timestamp
    const timestamp = Date.now();
    return parseInt(timestamp / 1000);
  };

  const getTimestampForDays = (days) => {
    // days to  timestamp conversion
    let currentTimestamp = getTimestamp();
    let daysInSeconds = days * 86400;
    return currentTimestamp + daysInSeconds;
  };

  let maxBidPrice;
  const getHighestBidPrice = async () => {
    await BidByOrderid(mpContractw, nftAddress, assetId)
      .then((bidDetails) => {
        // setHighestBidValue(bidDetails.price)
        const maxBidPriceInEth = web3.utils.fromWei(bidDetails.price, "ether");
        maxBidPrice = maxBidPriceInEth;
        setHighestBidValue(maxBidPriceInEth);
      })
      .catch((error) => {
        console.log("bidbyorderId error", error);
      });
  };

  const getPlacedBidHistory = () => {
    axios
      .get(
        `https://emillionsnft.com/api/historybid?nftAddress=${nftAddress}&assetId=${assetId}&sort=+`
      )
      .then((response) => {
        setFetchedBids(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPriceInEth = (price) => {
    return web3.utils.fromWei(price, "ether");
  };

  function trimAdd(add = "0x00", l = 5) {
    return (
      String(add).slice(0, 2) +
      String(add).slice(2, 2 + l) +
      "..." +
      String(add).slice(add.length - l, add.length)
    );
  }

  const handleBidSubmit = async (e) => {
    e.preventDefault();
    setModal(false);
    await getHighestBidPrice();
    // validation2: bid price should be higher than previous

    const { priceinEth, expireAt } = _spBid;
    let expireDate_timestamp = getTimestampForDays(expireAt);

    if (priceinEth > maxBidPrice) {
      await SafePlaceBid(
        mpContractw,
        nftAddress,
        assetId,
        priceinEth,
        expireDate_timestamp,
        account,
        setTxLoading
      );
      getPlacedBidHistory();
    } else {
      alert("handleBidSubmit fails");
    }
  };

  let nftOriginalPrice;
  if (orderDetails) {
    nftOriginalPrice = web3.utils.fromWei(orderDetails.price, "ether");
  }

  function getDateFromTimestamp(timestamp) {
    let date = new Date(parseInt(timestamp * 1000));
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }

  useEffect(() => {
    const getOrderDetails = async () => {
      const { nftAddress, assetId } = _orderDetails;
      const fetchOrderDetails = await mpContractw.methods
        .orderByAssetId(nftAddress, assetId)
        .call();
      setOrderDetails(fetchOrderDetails);

      if (fetchOrderDetails) {
        let timestamp = fetchOrderDetails.expiresAt;
        let nftexpiry = getDateFromTimestamp(timestamp);
        setNftExpiresDate(nftexpiry);

        let currentDateTimestamp = Date.now();
        if (currentDateTimestamp > parseInt(timestamp) * 1000) {
          setDisableBid(true);
        }
      }
    };

    const getTokenUri = async (e) => {
      await TokenUri(nftContractw, assetId)
        .then((res) => {
          setTokenUri(res);
        })
        .catch((error) => {
          console.log("tokenuri error", error);
        });
    };

    if (tokenUri) {
      const fetchTokenUri = async () => {
        axios
          .get(`https://ipfs.io/ipfs/${tokenUri.slice(7)}`)
          .then((response) => {
            setNftmetaData(response.data);
            setNftImageUrl(response.data.image);
          })
          .catch((error) => {
            console.log("nftMetaData fetch failed", error);
          });
      };
      fetchTokenUri();
    }
    getPlacedBidHistory();
    getTokenUri();
    getHighestBidPrice();
    getOrderDetails();
  }, [tokenUri, nftImageUrl, fetchedBids]);

  return (
    <div className="container">
      {modal && (
        <Modal handleClose={() => setModal(false)}>
          <form onSubmit={handleBidSubmit} className="collection_formModal">
            <FormInput
              name="priceinEth"
              value={_spBid.priceinEth}
              label="priceinEth (uint256)"
              handleChange={onInputChange}
            />

            <div className="date-picker">
              <label className="form-input-label">expiresAt</label>
              <select
                defaultValue={_spBid.expireAt}
                onChange={(e) =>
                  set_spBid({ ..._spBid, expireAt: e.target.value })
                }
              >
                <option value="1">1 day</option>
                <option value="3">3 day</option>
                <option value="7">1 week</option>
                <option value="90">3 month</option>
                <option value="180">6 month</option>
              </select>
            </div>

            <button type="submit" className="get-btn">
              Get
            </button>
          </form>
        </Modal>
      )}
      <div className="nft-item">
        <div className="nft-itemm-grid">
          <div className="nft-item-left">
            {nftImageUrl && (
              <img
                src={`https://ipfs.io/ipfs/${nftImageUrl.slice(7)}`}
                alt=""
              />
            )}
          </div>
          <div className="nft-item-right">
            <div className="nft-bid-section">
              <p className="price">
                {nftOriginalPrice ? nftOriginalPrice : 0} ETH
              </p>
              <p className="price">
                Minimum Bid: {highestBidValue ? highestBidValue : 0} ETH
              </p>
              <p className="price gray">
                OrderExpires: {orderDetails ? nftExpiresDate : "..."}{" "}
              </p>
              <div className="nft-buttons">
                <button
                  className={`btn ${disableBid && "gray-btn"}`}
                  onClick={() => setModal(!modal)}
                  disabled={disableBid}
                >
                  {txLoading ? "Bidding..." : "Place Bid"}
                </button>
              </div>
            </div>
            <div className="nft-bid-table">
              <Tab title="Offers" show={true}>
                {fetchedBids.length ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Price</th>
                        <th>Expiration</th>
                        <th>Bidder</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetchedBids
                        ?.slice(0)
                        .reverse()
                        .map((item, key) => (
                          <tr key={key}>
                            <td>{getPriceInEth(item.price)} ETH</td>
                            <td>{getDateFromTimestamp(item.expiresAt)}</td>
                            <td>{trimAdd(item.bidder)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <span className="nft-bid-status">No bid placed yet</span>
                )}
              </Tab>
            </div>
          </div>
        </div>
      </div>
      {txLoading && <LoadingSpinner />}
    </div>
  );
};

export default MarketPlaceItem;
