import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import '../../assets/style/collection-preview.css'
import { useNftContractWrite } from '../../hooks/useContract';
import CollectionItem from '../../components/wallet-collection';
import { getNftAddress } from '../../utils/addressHelpers';
import LoadingSpinner from '../../components/loading-spinner';

const WalletNfts = () => {
    const [assets, setAssets] = useState([])
    const { account } = useWeb3React();
    const nftAddress = getNftAddress()
    const nftContractw = useNftContractWrite(nftAddress)
    const [totalNfts, setTotalNfts] = useState('');




    const getAssetIds = async () => {
        let assetList = [];
        const nftCount = await nftContractw.methods.balanceOf(account).call()
        for (let i = 0; i < nftCount; i++) {
            const assetId = await nftContractw.methods.tokenOfOwnerByIndex(account, i).call();
            assetList.push(assetId)
        }
        setAssets(assetList)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (account) {
                await getAssetIds()
            }
        }
        fetchData()
    }, [account, totalNfts])

    return (
        <div className='container'>
            <div className="collection-wrapper">
                {assets.length ?
                    <div className="collection-preview">
                        {assets.map((tokenId) => (
                            <CollectionItem
                                key={tokenId}
                                tokenId={tokenId}
                                setTotalNfts={setTotalNfts}
                            />
                        ))}
                    </div>
                    : <LoadingSpinner />
                    // <div className="collection-preview-msg">
                    //     Your wallet is Empty <BiWalletAlt />
                    // </div>
                }

            </div>
        </div>
    )
}

export default WalletNfts;
