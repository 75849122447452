import axios from "axios";
import React, { useEffect, useState } from "react";
import "./styles.css";
import FormInput from "../../components/FormInput1";
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // Infura IPFS API

const CreateAsset = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [ipfshash, setipfshash] = useState("");
  const [buffer, setbuffer] = useState(null);
  // const [mintData, setmintData] = useState({
  //     name: "",
  //     description: "",
  //     ipfsLink: "",
  //     category: "",
  // })

  // TODO : create input field for each state
  const [mintData, setmintData] = useState({
    title: "",
    description: "",
    ipfsLink: "",
    category: "",
    collection: "",
    price: {
      amount: "",
      currency: "",
    },
    address: "",
  });

  const types = ["image/jpeg", "image/png", "application/pdf"];

  const captureFile = (e) => {
    let selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      setFile(selected);
      var reader = new window.FileReader(); //Convert to a buffer
      reader.readAsArrayBuffer(selected);
      reader.onloadend = () => {
        setbuffer(Buffer(reader.result)); //Data to send to IPFS
      };
      setError("");
    } else {
      setFile(null);
      setError("Please select an image file (jpg, png or pdf)");
    }
  };

  //Submitting file to IPFS
  const onSubmit = async (event) => {
    event.preventDefault();
    await ipfs.add(buffer, (error, result) => {
      const hash = result[0].hash; //Saving hash
      setmintData({
        ...mintData,
        ipfsLink: `https://ipfs.infura.io/ipfs/${hash}`,
      });
      setipfshash(hash);
      if (error) {
        console.error(error);
        return;
      }
    });
  };

  const postMintData = (event) => {
    axios
      // .post('https://emillionsnft.com/api/postMintData', mintData)
      .post("https://emillionsnft.com/api/postArtwork", mintData)
      .then((res) => {
        alert("Successfull created!!✅");
        setmintData({
          name: "",
          description: "",
          ipfsLink: "",
          category: "",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setmintData({ ...mintData, [name]: value });
  };

  useEffect(() => {
    if (ipfshash) {
      postMintData();
    }
  }, [ipfshash, file]);

  return (
    <div className="container">
      <div className="create-asset">
        <form onSubmit={onSubmit}>
          <div className="title">
            <h2>Create New Item</h2>
          </div>
          <div className="select-asset ">
            <input
              name="imageUpload"
              id="imageUpload"
              type="file"
              onChange={captureFile}
              required
            />
            <label htmlFor="imageUpload" className="select-asset-image">
              {file ? (
                ["image/jpeg", "image/png"].includes(file.type) ? (
                  <div
                    className="select-asset-image"
                    style={{
                      backgroundImage: `url(${URL.createObjectURL(file)})`,
                    }}
                  ></div>
                ) : (
                  <p>
                    <span className="file-name">
                      {file.name.substring(0, 15)}
                    </span>{" "}
                    ...
                  </p>
                )
              ) : (
                <i className="fas fa-image"></i>
              )}
            </label>
          </div>

          <div className="asset_info">
            <FormInput
              name="name"
              value={mintData.title}
              label="Name"
              handleChange={onInputChange}
            />
          </div>

          <div className="asset_info">
            <FormInput
              name="category"
              value={mintData.category}
              label="Category"
              handleChange={onInputChange}
            />
          </div>

          <div className="asset_info">
            <label className="form-input-label">Description</label>
            <textarea
              name="description"
              value={mintData.description}
              placeholder="https://opensea.io/asset/create"
              onChange={onInputChange}
              required
            ></textarea>
          </div>
          <div className="submit">
            <button type="submit">Create</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAsset;
