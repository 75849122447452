import React from "react";
import { Redirect, Route } from "react-router-dom";
const AdminProtected = ({ component: Cmp }) => {
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };;
  return (
    <Route
      render={() =>
        localStorage.getItem("token") &&
        parseJwt(localStorage.getItem("token")).role === "admin" ? (
          <Cmp />
        ) : (
          <Redirect to="login" />
        )
      }
    />
  );
};

export default AdminProtected;
