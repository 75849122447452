import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
// import { WalletLinkConnector } from "@web3-react/walletlink-connector";
// final-changes
var RPC_URLS = {
  1: "https://holy-red-violet.quiknode.pro/ad96881c9fd1953aea62e8d78d4f16420a32d82e",
  3: "https://ropsten.infura.io/v3/19de54b594234ffb978a4e81f18a9827",
  4: "https://eth-rinkeby.alchemyapi.io/v2/vOVnxLhoAFehzn-tZxbGZOo_GrT5ntGv",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545",
};

const POLLING_INTERVAL = 12000;

// export const NETWORK_CHAIN_ID = parseInt(
//   process.env.REACT_APP_MAINNET_CHAIN_ID ?? "97" // network change
// );

export const injected = new InjectedConnector({
  supportedChainIds: [1],
});

export const network = new NetworkConnector({
  urls: { 1: RPC_URLS[1] },
  defaultChainId: 1,
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  chainId: 1,
  bridge: "https://bridge.walletconnect.org/",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

// export const walletlink = new WalletLinkConnector({
//   url: "https://rinkeby.infura.io/v3/63273290f2b64f1d956e2a607d17b196",
//   appName: "web3-react example",
//   supportedChainIds: [4],
// });
