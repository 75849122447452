import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CustomButton from "../../components/CustomButton";
import FormInput from "../../components/FormInput";
import Pattern from "../images/membership-bg.png";

const Footer = () => {
  const loginData = useSelector((state) => state.data);
  const [login, setLogin] = useState();
  const location = useLocation();

  useEffect(() => {
    setLogin(loginData);
  }, [loginData]);

  return (
    <>
      {!login && location?.pathname !== "/sign-up" && (
        <div className="membership-wrapper">
          <div
            className="membership"
            style={{ backgroundImage: `url(${Pattern})` }}
          >
            <div className="title">
              Join an exclusive community of collectors.
            </div>
            <div className="sub-title">Buy your first NFT today.</div>
            <Link to="/sign-up">
              <CustomButton size="xlg">Sign up</CustomButton>
            </Link>
          </div>
        </div>
      )}
      <div className="footer-wrapper">
        {/* UPLOAD FILE TO IPFS */}
        {/* <div style={{ padding: "15% 0" }}>
          <input type="file" onChange={captureFile} />
        </div> */}
        {/* https://ipfs.infura.io/ipfs/ */}

        <div className="footer">
          <div className="footer-left">
            <div className="copy-rights">
              © {new Date().getFullYear()} Emillions NFT. All Rights Reserved.
            </div>
            <div className="social-icons"></div>
          </div>
          <div className="footer-form">
            <div className="title">Stay up-to-date on NFT News!</div>
            <div className="form-grid">
              <FormInput label="First Name" />
              <FormInput label="Last Name" />
              <FormInput label="Email Address" />
            </div>
            <CustomButton size="lg">Register</CustomButton>
          </div>
          <div className="footer-right">
            <div className="options">
              <Link className="option" to="/featured">
                Current Sales
              </Link>
              <Link className="option" to="/art/legacy">
                Categories
              </Link>
              <Link className="option" to="/artist">
                Artists
              </Link>
              <Link className="option" to="/about">
                Team
              </Link>
            </div>
            <div className="options">
              <a
                className="option"
                href="https://emillionsnft.mypinata.cloud/ipfs/QmZDPcRvSwsSfKEBMd4ooeRAAokyGeCW4y9LGveQ7Zh63E"
                download
              >
                Legal
              </a>
              <a
                className="option"
                href="https://emillionsnft.mypinata.cloud/ipfs/Qmd4C1rPMb5h5X9oeXERngdJgofXFF34jYyv3CPeGouckW"
              >
                Privacy Policy
              </a>
              <a
                className="option"
                href="https://emillionsnft.mypinata.cloud/ipfs/QmaFjzigCovSLAFTd8MAGixYFfbuqToSn9Rd1EjU6t1EeG"
              >
                FAQ
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
