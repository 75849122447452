import React, { useEffect, useState } from "react";
import Pattern from "../../assets/images/artist-hero.svg";
import CustomButton from "../../components/CustomButton";
import SkewBackground from "../../assets/images/skew-2.svg";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Featured = () => {
  const [activeArt, setactiveArt] = useState(1);
  const [featuredNfts, setFeaturedNfts] = useState([]);
  const history = useHistory();
  // const onMatchedRoutes = (matchedRoutes) => {
  //   return [
  //     {
  //       route: {
  //         path: `/`,
  //         breadcrumbName: "Home",
  //       },
  //     },
  //     ...matchedRoutes,
  //   ];
  // };

  const newlyUpdatedFeaturedNfts = async () => {
    try {
      const res = await axios.get(
        "https://emillionsnft.com/api/minted?sort=-updatedAt&featured=true"
      );
      setFeaturedNfts(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    newlyUpdatedFeaturedNfts();
  });

  const handleFeaturedRedirection = () => {
    if (activeArt === 1) {
      history.replace(
        `/art/${featuredNfts[0].category}/${featuredNfts[0].nftAddr}/${featuredNfts[0].nftId}`
      );
    } else {
      history.replace(
        `/art/${featuredNfts[1].category}/${featuredNfts[1].nftAddr}/${featuredNfts[1].nftId}`
      );
    }
  };

  let featuredNft1 =
    featuredNfts && featuredNfts[0] && featuredNfts[0].description;
  let featuredNft2 =
    featuredNfts && featuredNfts[1] && featuredNfts[1].description;

  let featuredNftImg1 =
    featuredNfts && featuredNfts[0] && featuredNfts[0].ipfsLink;
  let featuredNftImg2 =
    featuredNfts && featuredNfts[1] && featuredNfts[1].ipfsLink;

  let featuredNftProfileImg1 =
    featuredNfts &&
    featuredNfts[0] &&
    featuredNfts[0].artistDetails &&
    featuredNfts[0].artistDetails.profileImage;
  let featuredNftProfileImg2 =
    featuredNfts &&
    featuredNfts[1] &&
    featuredNfts[1].artistDetails &&
    featuredNfts[1].artistDetails.profileImage;

  let featuredNftUsername1 =
    featuredNfts &&
    featuredNfts[0] &&
    featuredNfts[0].artistDetails &&
    featuredNfts[0].artistDetails.username;
  let featuredNftUsername2 =
    featuredNfts &&
    featuredNfts[1] &&
    featuredNfts[1].artistDetails &&
    featuredNfts[1].artistDetails.username;

  let featuredNftTitle1 =
    featuredNfts && featuredNfts[0] && featuredNfts[0].title;
  let featuredNftTitle2 =
    featuredNfts && featuredNfts[1] && featuredNfts[1].title;

  return (
    <div className="featured">
      <div className="featured-image">
        <img src={Pattern} alt="" />
      </div>

      <div className="container">
        {/* <div className='featured-mapping'>
                    <Breadcrumb
                        locationPath={location.pathname}
                        onMatchedRoutes={onMatchedRoutes}
                    />
                </div> */}
      </div>
      <section>
        <div className="featured-hero">
          <div className="skew">
            <img src={SkewBackground} alt="" />
          </div>
          <div className="featured-hero-container">
            <div className="featured-hero-grid">
              <div className="left">
                <div className="category">
                  <div className="category-title">Featured</div>
                  <div className="category-buttons">
                    <button
                      className={`${activeArt === 1 ? "active" : ""}`}
                      onClick={() => setactiveArt(1)}
                    >
                      {" "}
                      <BsChevronLeft />{" "}
                    </button>
                    <button
                      className={`${activeArt === 2 ? "active" : ""}`}
                      onClick={() => setactiveArt(2)}
                    >
                      {" "}
                      <BsChevronRight />{" "}
                    </button>
                  </div>
                </div>
                <div className="title">
                  {activeArt === 2
                    ? `${featuredNftTitle2}`
                    : `${featuredNftTitle1}`}
                </div>
                <div className="owner">
                  <div className="avatar">
                    {activeArt === 1 ? (
                      <img
                        src={featuredNftProfileImg1}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        src={featuredNftProfileImg2}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    )}
                  </div>
                  <div className="username">
                    {activeArt === 1
                      ? featuredNftUsername1
                      : featuredNftUsername2}
                  </div>
                </div>
                <div className="info">
                  {`${activeArt === 1 ? featuredNft1 : featuredNft2}`}
                </div>
                <CustomButton size="lg" onClick={handleFeaturedRedirection}>
                  Details
                </CustomButton>
              </div>
              <div className="right">
                {activeArt === 1 ? (
                  featuredNfts &&
                  featuredNfts[0] &&
                  featuredNfts[0].fileType === "video" ? (
                    <video src={featuredNftImg1} autoPlay loop></video>
                  ) : (
                    <img src={featuredNftImg1} alt="" />
                  )
                ) : featuredNfts &&
                  featuredNfts[1] &&
                  featuredNfts[1].fileType === "video" ? (
                  <video src={featuredNftImg2} autoPlay loop></video>
                ) : (
                  <img src={featuredNftImg2} alt="" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Featured;
