import React, { useState } from 'react';
import '../assets/style/tab.css';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'

const Tab = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className="tab">
            <h1 onClick={() => setIsOpen(!isOpen)} >

                {props.title}
                {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </h1>
            {isOpen && <div className="tab-content" >{props.children}</div>}
        </div>
    )
}

export default Tab;
