import React from 'react';
import '../assets/style/modal.css'

const Modal = ({ handleClose, children, closeIcon, ...rest }) => {
    return (
        <div className="modal">
            <div className="backdrop" onClick={handleClose}></div>
            <div className="modal-wrapper" >
                <div className="modal-content">
                    {children}
                </div>
            </div>
            {closeIcon && <span onClick={handleClose} className="close-icon" >&#10006;</span>}
        </div>
    )
}

export default Modal
