import Web3 from "web3";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
toast.configure();

const checkWalletAddress = async (account) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  try {
    const res = await axios.get("https://emillionsnft.com/api/me", { headers });
    if (res.data.walletAddress === account) {
      return false;
    } else {
      toast.error("please use correct wallet address");
      toast.info(`use wallet address ${res.data.walletAddress}`);
      return true;
    }
  } catch (error) {
    toast.error("cannot check wallet address");
  }
};

export const InitializeNft = async (
  nftContract,
  nftId,
  nftInitPrice,
  artistAddress,
  account,
  setTxLoading
) => {
  const web3 = new Web3(window.ethereum);
  const weiValue = web3.utils.toWei(nftInitPrice.toString());
  await nftContract.methods
    .initializeNft(nftId, weiValue, artistAddress)
    .send({ from: account })
    .once("transactionHash", () => {
      setTxLoading(true);
    })
    .once("receipt", () => {
      setTxLoading(false);
      toast.success("Initialized succesfully");
    })
    .on("error", () => {
      setTxLoading(false);
      toast.error("Initialized failed");
    });
};

export const MintNft = async (nftContract, nftPrice, nftId, account) => {
  const checkResult = await checkWalletAddress(account);
  if (checkResult) {
    return;
  }
  const web3 = new Web3(window.ethereum);
  const weiValue = web3.utils.toWei(nftPrice.toString());
;
  await nftContract.methods
    .mintNFT(nftId)
    .send({ from: account, value: weiValue })
    .once("recepient", (recepient) => {
      toast.success("Mint Successfully!!");
    })
    .on("error", (e) => {
      toast.error("Mint Failed!!");
    });
};

// approveAll nft to marketplace
export const ApproveNft = async (
  nftContractw,
  approveTo,
  account,
  setTxLoading
) => {
  await nftContractw.methods
    .setApprovalForAll(approveTo, true)
    .send({ from: account })
    .once("transactionHash", () => {
      setTxLoading(true);
    })
    .once("receipt", () => {
      setTxLoading(false);
      toast.success("Approved Successfully!!");
    })
    .on("error", () => {
      setTxLoading(false);
      toast.error("Approval Failed!!");
    });
};

export const CreateOrder = async (
  mpContract,
  nftAddress,
  tokenId,
  nftPrice,
  account,
  setTxLoading
) => {
  const checkResult = await checkWalletAddress(account);
  if (checkResult) {
    return;
  }
  const web3 = new Web3(window.ethereum);
  const weiValue = web3.utils.toWei(nftPrice.toString());

  await mpContract.methods
    .createOrder(nftAddress, tokenId, weiValue)
    .send({ from: account })
    .once("transactionHash", () => {
      setTxLoading(true);
    })
    .once("receipt", (receipt) => {
      setTxLoading(false);
      toast.success("Order Successfully Created");
    })
    .on("error", () => {
      setTxLoading(false);
      toast.error("Createding Failed");
    });
};

export const PurchaseOrder = async (
  mpContract,
  nftPrice,
  nftAddress,
  tokenId,
  account,
  setTxLoading
) => {
  const checkResult = await checkWalletAddress(account);
  if (checkResult) {
    return;
  }
  const web3 = new Web3(window.ethereum);
  const weiValue = web3.utils.toWei(nftPrice.toString());

  await mpContract.methods
    .purchaseOrder(nftAddress, tokenId)
    .send({ from: account, value: weiValue })
    .once("transactionHash", () => {
      setTxLoading(true);
    })
    .once("receipt", (receipt) => {
      setTxLoading(false);
      toast.success("Purchase Successfully");
    })
    .on("error", () => {
      setTxLoading(false);
      toast.error("Purchase Failed");
    });
};

export const CancelOrder = async (
  mpContract,
  nftAddress,
  assetId,
  account,
  setTxLoading
) => {
  const checkResult = await checkWalletAddress(account);
  if (checkResult) {
    return;
  }
  await mpContract.methods
    .cancelOrder(nftAddress, assetId)
    .send({ from: account })
    .once("transactionHash", () => {
      setTxLoading(true);
    })
    .once("receipt", (receipt) => {
      setTxLoading(false);
      toast.success("Cancelled Successfully");
    })
    .on("error", () => {
      setTxLoading(false);
      toast.error("Cancel order Failed");
    });
};

export const UpdateOrder = async (
  mpContract,
  nftAddress,
  assetId,
  nftPrice,
  account,
  setTxLoading
) => {
  const checkResult = await checkWalletAddress(account);
  if (checkResult) {
    return;
  }
  const web3 = new Web3(window.ethereum);
  const weiValue = web3.utils.toWei(nftPrice.toString());

  await mpContract.methods
    .updateOrder(nftAddress, assetId, weiValue)
    .send({ from: account })
    .once("transactionHash", () => {
      setTxLoading(true);
    })
    .once("receipt", (receipt) => {
      setTxLoading(false);
      toast.success("Updated Successfully");
    })
    .on("error", () => {
      setTxLoading(false);
      toast.error("Update order Failed");
    });
};
