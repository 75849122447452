import { Fragment, useState, useEffect } from "react"
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector';
import { useInactiveListener } from '../hooks/onAccountChange'
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from '@web3-react/frame-connector'
import { injected, network, walletconnect } from "../connectors"


const ConnectorNames = {
  Injected: 'MetaMask',
  Network: 'Network',
  WalletConnect: 'WalletConnect'
};
Object.freeze(ConnectorNames)
const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.Network]: network,
  [ConnectorNames.WalletConnect]: walletconnect
}

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network."
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return 'Please authorize this website to access your Ethereum account.'
  } else {
    console.error(error)
    return 'An unknown error occurred. Check the console for more details.'
  }
}

const Card = (props) => {
  const context = useWeb3React()
  const { connector, account, activate, deactivate, active, error } = context

  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = props.tried

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)
  return (
    <Fragment>
      {!!error && <h4 style={{ position: 'fixed', top: '5%', width: '100%', left: '0', marginTop: '1rem', marginBottom: '0', padding: '20px', backgroundColor: '#f44336', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '30' }}>{getErrorMessage(error)}</h4>}
      <h2>{account === null
        ? 'Netwrok Connected'
        : account
          ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
          : 'No Wallet Attached!!'}
      </h2> <br />
      {Object.keys(connectorsByName).map((name) => {
        const currentConnector = connectorsByName[name]
        const activating = currentConnector === activatingConnector
        const connected = currentConnector === connector
        const disabled = !triedEager || !!activatingConnector || connected || !!error
        return (
          <div>
            <div>
              <button
                disabled={disabled}
                className="btn-modal"

                key={name}
                onClick={() => {
                  setActivatingConnector(currentConnector)
                  activate(connectorsByName[name])
                }}
              >
                {connected && (
                  <span role="img" aria-label="check">
                    ✅
                  </span>
                )}
                {name}
              </button>
            </div>
          </div>

        )
      })}

      <div>
        {(active || error) && (
          <button
            className="btn-modal"
            onClick={() => {
              deactivate()
            }}
            style={{ background: 'black', color: 'white' }}
          >
            Deactivate
          </button>
        )}
      </div>
    </Fragment>
  );
};
export default Card;