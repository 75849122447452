import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/style/nftCard.css";
import { withRouter } from "react-router-dom";
import { TokenUri } from "./myContract";
import { useNftContractWrite } from "../hooks/useContract";
import { useWeb3React } from "@web3-react/core";
import { getNftAddress } from "../utils/addressHelpers";

const NftCard = ({ nftItem, match, history }) => {
  const { assetId, nftAddress, expiresAt } = nftItem;
  const nftContractAddress = getNftAddress();
  const nftContractw = useNftContractWrite(nftContractAddress);
  const [tokenUri, setTokenUri] = useState(null);
  const [nftMetadata, setNftMetadata] = useState([]);
  const [nftImageUrl, setNftImageUrl] = useState(null);
  const [expired, setExpired] = useState(false);

  const handleClick = () => {
    if (match.path === "/collection") {
      history.push(`${match.path}/${nftAddress}/${assetId}`);
    }
  };

  useEffect(() => {
    let orderTimestamp = parseInt(expiresAt);
    let currentDateTimestamp = Date.now();

    if (currentDateTimestamp > orderTimestamp * 1000) {
      setExpired(true);
    }

    const getTokenUri = async (e) => {
      await TokenUri(nftContractw, assetId)
        .then((res) => {
          setTokenUri(res);
        })
        .catch((error) => {
          console.log("tokenuri error", error);
        });
    };

    if (tokenUri) {
      const fetchNftData = async () => {
        axios
          .get(`https://ipfs.io/ipfs/${tokenUri.slice(7)}`)
          .then((response) => {
            setNftMetadata(response.data);
            setNftImageUrl(response.data.image);
          });
      };
      fetchNftData();
    }

    getTokenUri();
  }, [tokenUri]);

  return (
    <>
      {tokenUri && !expired && (
        <div className="nft-collection-card" onClick={handleClick}>
          <div className="nft-card-img">
            {nftImageUrl && (
              <img
                src={`https://ipfs.io/ipfs/${nftImageUrl.slice(7)}`}
                alt=""
              />
            )}
          </div>
          <div className="nft-card-details">
            <p> AssetId: {assetId}</p>
            <p>{nftAddress}</p>
          </div>
        </div>
      )}
    </>
  );
};
export default withRouter(NftCard);
