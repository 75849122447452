import React from 'react';

const CustomButton = ({ children, size, ...otherProps }) => (
    <button
        className={`${size ? size: ""} custom-button`}
        style={{ backgroundColor: otherProps.background, color: otherProps.color }}
        {...otherProps}
    >
        {children}
    </button>
)

export default CustomButton;
