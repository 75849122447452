import React from 'react';
import { Link } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import routes from '../routes';

const Breadcrumb = ({ locationPath, onMatchedRoutes }) => {
    let matchedRoutes = matchRoutes(routes, locationPath);

    if (typeof onMatchedRoutes === 'function') {
        matchedRoutes = onMatchedRoutes(matchedRoutes);
    }

    return (
        <nav>
            <ul className="breadcrumb">
                {matchedRoutes.map((matchRoute, i) => {
                    const { path, breadcrumbName } = matchRoute.route;
                    const isActive = path === locationPath;

                    return isActive ? (
                        <li key={i} className="breadcrumb-item active">
                            {breadcrumbName}
                        </li>
                    ) : (
                        <li key={i} className="breadcrumb-item">
                            <Link to={path}>{breadcrumbName}</Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};
export default Breadcrumb;
