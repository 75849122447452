import React from 'react';
import '../assets/style/loading-spinner.css'

const LoadingSpinner = () => {
    return (
        <div className='spinner-overlay' >
            <div className="spinner-container"></div>
        </div>
    )
}

export default LoadingSpinner
