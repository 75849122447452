import React, { useState, useEffect } from "react";
import CustomButton from "../../components/CustomButton";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import Modal from "../../components/Modal";
import { ReactComponent as CloseIcon } from "../../assets/images/closeIcon.svg";
import Pattern from "../../assets/images/legacy-hero.svg";
import Gold from "../../assets/images/gold-single.svg";
import Breadcrumb from "../../components/Breadcrumb";
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../components/loading-spinner";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


toast.configure();

const BlogCard = ({ nftItem }) => {
  const userData = useSelector((state) => state.data);
  const {
    ipfsLink,
    nftId,
    nftAddr,
    nftOrderPrice,
    title,
    description,
    fileType,
  } = nftItem;

  console.log('HEY THERE ITS NFTITEM', nftItem)
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const [ethPrice, setEthprice] = useState();
  const [editDescModal, setEditDescModal] = useState(false);
  const [newDesc, setNewDesc] = useState(nftItem.description);
  const [loading, setLoading] = useState(false);
  const [txLoading, setTxLoading] = useState(true);
  // const getPriceInEth = (price) => {
  //   return web3.utils.fromWei(price, "ether");
  // };
  const handleDetails = () => {
    history.push(`${location.pathname}/${nftAddr}/${nftId}`);
  };

  const getOneEthPrice = () => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
      )
      .then((res) => {
        let oneEthPrice = res.data.ethereum.usd;
        setEthprice(oneEthPrice);
      })
      .catch(() => {
        toast.error("getting ETH error!!");
      });
  };

  useEffect(() => {
    getOneEthPrice();
  }, []);

  const handleImgVideoOnClick = (e) => {
    if (fileType === "image") {
      open();
    }
    if (fileType === "video") {
      e.target.play();
    }
  };

  const editDescModalHandler = () => {
    setEditDescModal(true);
  };

  const closeEditDescModalHandler = () => {
    setEditDescModal(false);
  };

  const onDescChange = (e) => {
    setNewDesc(e.target.value);
  };

  const editDescFormHandler = async (e) => {
    window.alert("desc updated");
    e.preventDefault();
    setLoading(true);
    await axios
      .patch(
        `https://emillionsnft.com/api/minted/${nftItem.nftId}`,
        { description: newDesc },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Successfully updated!");
      })
      .catch((err) => {
        toast.error(err.response.data.error || "Update Failed!!");
        console.log(err.message);
      });
    setLoading(false);
  };

  const handleMakeFeature = () => {
    setLoading(true);
    axios
      .patch(
        `https://emillionsnft.com/api/minted/${nftItem.nftId}`,
        { featured: true },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Successfully updated!");
      })
      .catch((err) => {
        toast.error(err.response.data.error || "Update Failed!!");
        console.log(err.message);
      });
    setLoading(false);
  };

  const handleUnFeature = () => {
    setLoading(true);
    axios
      .patch(
        `https://emillionsnft.com/api/minted/${nftItem.nftId}`,
        { featured: false },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Successfully updated!");
      })
      .catch((err) => {
        toast.error(err.response.data.error || "Update Failed!!");
        console.log(err.message);
      });
    setLoading(false);
  };

  useEffect(() => {
      const timer = setTimeout(() => {
        if(nftItem) {
          setTxLoading(false)
        }
      }, 300);
      return () => clearInterval(timer)
  }, [nftItem])

  return (
    <div className="card">
      {loading && <LoadingSpinner />}
      <div className="grid">
        <div className="image" onClick={handleImgVideoOnClick}>
          {txLoading ?
            <Skeleton width={375} height={270} /> :
            <>
              {fileType === "image" ? <img src={ipfsLink} alt="" /> : null}
              {fileType === "video" ? <video src={ipfsLink}></video> : null}
            </>
          }
          {/* {fileType === "video" ? <p style={{color: "red", fontWeight: "bold"}}>*Click to start animation</p> : null} */}
        </div>
        <div className="content">
          {txLoading ?
            <>
              <Skeleton width={380} height={35} style={{ marginBottom: '5px' }} />
              <Skeleton width={180} height={32} />
              <br />
              <Skeleton count={5} height={28} style={{ marginBottom: '5px' }} />
            </> :
            <>
              <div className="title">{title ? title : "..."}</div>
              <div className="sub-title">NFT ID : {nftId ? nftId : "..."}</div>
              <div className="body">
                <div className="text">
                  {description ? `${description.substring(0, 500)}...` : "..."}
                </div>
                <div className="action">
                  <CustomButton size="md" onClick={handleDetails}>
                    Details
                  </CustomButton>
                  <div className="price">
                    {nftOrderPrice && (
                      <span className="bold">
                        {parseFloat(nftOrderPrice.toFixed(3))} ETH
                      </span>
                    )}
                    <span className="light">
                      ${Number(ethPrice * nftOrderPrice).toFixed(2)}
                    </span>
                  </div>
                </div>
                {userData && userData.role === "admin" && (
                  <CustomButton size="md" onClick={editDescModalHandler}>
                    Edit
                  </CustomButton>
                )}
              </div>
            </>
          }

        </div>
      </div>

      {editDescModal && (
        <Modal style={{ width: "40%", padding: "1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "2rem",
            }}
          >
            <div style={{ padding: "2rem" }}>
              <div className="mod-closeIcon">
                <CloseIcon onClick={closeEditDescModalHandler} />
              </div>
              <form onSubmit={editDescFormHandler}>
                <textarea
                  name="editBio"
                  value={newDesc}
                  label="New Bio"
                  onChange={onDescChange}
                  rows="15"
                  cols="60"
                  style={{ padding: "0.7rem", fontSize: "1rem" }}
                ></textarea>
                <button
                  type="submit"
                  className="btn"
                  style={{
                    padding: "0.5rem 0.5rem",
                    margin: "0.7rem 0",
                    borderRadius: "0.5rem",
                    border: "none",
                    fontSize: "1rem",
                  }}
                >
                  Change Bio
                </button>
              </form>
              {!nftItem.featured && (
                <button
                  type="submit"
                  className="btn"
                  style={{
                    padding: "0.5rem 0.5rem",
                    margin: "0.7rem 0",
                    borderRadius: "0.5rem",
                    border: "none",
                    fontSize: "1rem",
                  }}
                  onClick={handleMakeFeature}
                >
                  Feature it
                </button>
              )}
              {nftItem.featured && (
                <button
                  type="submit"
                  className="btn"
                  style={{
                    padding: "0.5rem 0.5rem",
                    margin: "0.7rem 0",
                    borderRadius: "0.5rem",
                    border: "none",
                    fontSize: "1rem",
                  }}
                  onClick={handleUnFeature}
                >
                  Unfeature it
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}

      {isOpen && (
        <Modal style={{ width: "60%", padding: 0 }}>
          <div className="mod-image">
            {ipfsLink && <img src={`${ipfsLink}`} alt="" />}
          </div>
          <div className="mod-closeIcon">
            <CloseIcon onClick={close} />
          </div>
        </Modal>
      )}
    </div>
  );
};

const FilterTab = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="filter-tab">
      <div onClick={() => setIsOpen(!isOpen)} className="title">
        {props?.title}
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {isOpen && <div className="ft-body">{props.children}</div>}
    </div>
  );
};

const Legacy = ({ location }) => {
  const [path, setPath] = useState("");
  // const [visible, setVisible] = useState(6);
  const [nfts, setNfts] = useState([]);
  // const [featuredNfts, setFeaturedNfts] = useState([]);
  const [artists, setArtists] = useState([]);
  const [filterPrice, setFilterPrice] = useState({
    minimum: "",
    maximum: "",
  });

  const [mediaFilterStatus, setMediaFilterStatus] = useState({
    image: "",
    video: "",
  });
  const [filterArtist, setFilterArtist] = useState([]);
  const [ethCurrencyFilter, setEthCurrencyFilter] = useState(false);
  const [usdCurrencyFilter, setUsdCurrencyFilter] = useState(false);

  // const showMoreCards = () => {
  //   setVisible((prevValue) => prevValue + 6);
  // };

  const filterNft = async (currentPath) => {
    let imgFilter;
    let vidFilter;
    let lte;
    let gte;
    let artistFilter;
    const { image, video } = mediaFilterStatus;
    let { minimum, maximum } = filterPrice;
    if (usdCurrencyFilter) {
      const res = await axios.get(
        "https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH"
      );
      minimum = minimum * res.data.ETH;
      maximum = maximum * res.data.ETH;
    }
    if (mediaFilterStatus.image) {
      imgFilter = `&fileType=${image && "image"}`;
    }
    if (mediaFilterStatus.video) {
      vidFilter = `&fileType=${video && "video"}`;
    }
    if (filterPrice.minimum) {
      lte = `&nftOrderPrice[gte]=${minimum && minimum}`;
    }
    if (filterPrice.maximum) {
      gte = `&nftOrderPrice[lte]=${maximum && maximum}`;
    }
    if (filterArtist && filterArtist.length > 0) {
      artistFilter = filterArtist.map((element) => `&artistDetails=${element}`);
    }
    const filterUrl = `https://emillionsnft.com/api/minted?status[ne]=not&category=${currentPath}${imgFilter ? imgFilter : ""
      }${vidFilter ? vidFilter : ""}${minimum ? lte : ""}${maximum ? gte : ""}${artistFilter ? artistFilter : ""
      }`;

    axios
      .get(filterUrl.replaceAll(",", ""))
      .then((res) => {
        setNfts(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const artistFilterHandler = (e) => {
    const { checked, name } = e.target;
    let newFilterArtistArray = [...filterArtist];
    if (checked) {
      newFilterArtistArray.push(name);
    }
    if (!checked) {
      newFilterArtistArray = newFilterArtistArray.filter(
        (element) => element !== name
      );
    }
    setFilterArtist(newFilterArtistArray);
  };

  const filterImgHandler = (e) => {
    const { name, checked } = e.target;
    setMediaFilterStatus({ ...mediaFilterStatus, [name]: checked });
  };

  const fetchArtists = () => {
    axios
      .get("https://emillionsnft.com/api/artist")
      .then((res) => {
        const array = res.data.filter((element) => element.arts.length > 0);
        setArtists(array);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // const fetchFeaturedNfts = async () => {
  //   const res = await axios.get(
  //     "https://emillionsnft.com/api/minted?featured=true"
  //   );
  //   setFeaturedNfts(res.data);
  // };

  useEffect(() => {
    fetchArtists();
    // fetchFeaturedNfts();
  }, []);

  useEffect(() => {
    if (location) {
      const { pathname } = location;
      const currentPath = pathname.replace("/art/", "");
      setPath(currentPath);

      filterNft(currentPath);
      // else if (currentPath==="/story") fetchStoryNfts();
      // else if (currentPath==="/imagination") fetchImaginationNfts();
    }
  }, [location, mediaFilterStatus, filterArtist]);

  const handleCurrencyCheck = (e) => {
    if (e.target.name === "ETH") {
      if (e.target.checked) {
        setEthCurrencyFilter(true);
      } else {
        setEthCurrencyFilter(false);
      }
    } else {
      if (e.target.checked) {
        setUsdCurrencyFilter(true);
      } else {
        setUsdCurrencyFilter(false);
      }
    }
  };

  return (
    <div className="legacy">
      <div className="legacy-hero">
        <img src={Pattern} alt="" />
        <div className="art-routing">
          <div className="route">
            <Link to="/art/legacy">Legacy</Link>
          </div>
          <div className="route">
            <Link to="/art/story">Story</Link>
          </div>
          <div className="route">
            <Link to="/art/imagination">Imagination</Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="legacy-background">
          <img src={Gold} alt="" />
        </div>
        <div className="legacy-body">
          <div className="legacy-body-left">
            <div className="filter-section">
              <div className="main-title">Filter By</div>
              <div className="checkbox" onChange={filterImgHandler}>
                <div className="checkbox-container">
                  <input name="video" value="video" type="checkbox" id="cb1" />
                  <label htmlFor="cb1">Video</label>
                </div>

                <div className="checkbox-container">
                  <input name="image" value="image" type="checkbox" id="cb3" />
                  <label htmlFor="cb3">Image</label>
                </div>
              </div>
            </div>
            <div className="filter-section">
              <div className="sub-title">Artist</div>
              <FilterTab title="Select Artist">
                <div className="checkbox" onChange={artistFilterHandler}>
                  {artists?.map((artist, i) => (
                    <div className="checkbox-container" key={i}>
                      <input
                        type="checkbox"
                        id={artist?._id}
                        name={artist?._id}
                      />
                      <label htmlFor={artist?._id}>{artist.username}</label>
                    </div>
                  ))}
                </div>
              </FilterTab>
            </div>
            <div className="filter-section">
              <div className="sub-title">Currency</div>
              <FilterTab title="Select Currency">
                <div className="checkbox">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      name="USD"
                      id="cb7"
                      onClick={handleCurrencyCheck}
                      disabled={ethCurrencyFilter}
                    />
                    <label htmlFor="cb7">USD</label>
                  </div>
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      name="ETH"
                      id="cb8"
                      onClick={handleCurrencyCheck}
                      disabled={usdCurrencyFilter}
                    />
                    <label htmlFor="cb8">ETH</label>
                  </div>
                </div>
              </FilterTab>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                filterNft(location?.pathname.replace("/art/", ""));
              }}
            >
              <div className="filter-section">
                <div className="bid-title">Minimum</div>
                <div className="bid-input">
                  <input
                    type="number"
                    value={filterPrice.minimum}
                    onChange={(e) => {
                      setFilterPrice({
                        ...filterPrice,
                        minimum: e.target.value,
                      });
                    }}
                  />
                  <button>{usdCurrencyFilter ? "USD" : "ETH"}</button>
                </div>
              </div>
              <div className="filter-section">
                <div className="bid-title">Maximum</div>
                <div className="bid-input">
                  <input
                    type="number"
                    value={filterPrice.maximum}
                    onChange={(e) => {
                      setFilterPrice({
                        ...filterPrice,
                        maximum: e.target.value,
                      });
                    }}
                  />
                  <button>{usdCurrencyFilter ? "USD" : "ETH"}</button>
                </div>
              </div>
              <CustomButton type="submit">Apply</CustomButton>
            </form>
          </div>
          <div className="legacy-body-right">
            {path === "legacy" && (
              <Art
                pathname={location?.pathname}
                title="Legacy"
                path={path}
                nfts={nfts}
              // featuredNfts={featuredNfts.filter(
              //   (element) => element.category === "legacy"
              // )}
              />
            )}
            {path === "story" && (
              <Art
                pathname={location?.pathname}
                title="Story"
                path={path}
                nfts={nfts}
              // featuredNfts={featuredNfts.filter(
              //   (element) => element.category === "story"
              // )}
              />
            )}
            {path === "imagination" && (
              <Art
                pathname={location?.pathname}
                title="Imagination"
                path={path}
                nfts={nfts}
              // featuredNfts={featuredNfts.filter(
              //   (element) => element.category === "imagination"
              // )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legacy;

const Art = (props) => {
  const { pathname, title, nfts } = props;
  const onMatchedRoutes = (matchedRoutes) => {
    return [
      {
        route: {
          path: `/`,
          breadcrumbName: "Home",
        },
      },
      ...matchedRoutes,
    ];
  };

  return (
    <>
      <div className="mapping">
        <Breadcrumb locationPath={pathname} onMatchedRoutes={onMatchedRoutes} />
      </div>
      <div className="title">{title}</div>
      <div className="message">
        {props?.path === "legacy" &&
          "Creating and seeking Legacy. Here you will find a growing portfolio, bringing to life the historical legacy of iconic people, places and events; combined with legacies to be drawn across nature and the issues and movement of people."}
        {props?.path === "story" &&
          "Compelling Stories. A picture, a moment, a video clip can communicate a story like no other medium. Here, the art resonates with a story; whether it be from a moment in history, a life once lived, an iconic event, an experience shared or from a journey about to commence; a future foretold."}
        {props?.path === "imagination" &&
          "Flashes of inspiration. Placing in art that which has previously lived in the imagination. Whether from the world of movies and entertainment or from the mind and soul of the creator. The future imagined, the present reconstituted or the past recreated."}
      </div>
      <div className="collection">
        {nfts?.map((item) => (
          <>
            <BlogCard
              key={item.nftId}
              nftItem={item}
              // featuredNfts={featuredNfts}
              nfts={nfts}
            />
          </>
        ))}
      </div>
    </>
  );
};
