import React from 'react'
import '../assets/style/modal.css'



const Modal = ({ handleClose, children, closeIcon, ...rest }) => {
    return (

        <div className="nft-modal">
            <div className="mod-backdrop"></div>
            <div className="mod-wrapper" {...rest} >
                {children}
            </div>
        </div>
    )
}

export default Modal;
