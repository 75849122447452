import React, { useState } from 'react';
import Pattern from "../../assets/images/artist-hero.svg"
import Breadcrumb from '../../components/Breadcrumb';
import { BsChevronDoubleDown } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { BiLink } from 'react-icons/bi';
// import BlockTing from '../../assets/images/blockting.svg'
// import Planar from '../../assets/images/planar.png'
import HuschBlackWell from '../../assets/images/husch_blackwell.png'
import Impactility from '../../assets/images/impactility.png'

import MissionSkew from "../../assets/images/skew-2.svg"
import Neil from "../../assets/images/Neil-Headshot.png"
// import Rafael from "../../assets/images/Rafael-Headshot.png"
import Mickey from "../../assets/images/Mickey_Farley.jpg"
import RobinA from "../../assets/images/Robin_Austin.jpg"
import Marlissa from "../../assets/images/Marlissa_Gardner.png"
import Michael from "../../assets/images/Michael_Dana.jpg"
// import Harman from "../../assets/images/Harman_Puri.png"
// import Joe from "../../assets/images/Joe_Guagliardo.jpg"

const ProfileCard = (props) => {
    const [show, setShow] = useState(false);

    return (
        <div className="about_profileCard">
            <div className="about_cardImage" style={{ backgroundImage: `url(${props.img})` }}>
            </div>
            <div className="about_cardInfo">
                <div className="about_cardInfo-name">{props.name}</div>
                <div className="about_cardInfo-owner">{props.des}</div>
                <div className="about_cardInfo-social">
                    {/* <Link className='social-icon'> <BsLinkedin /> </Link> */}
                    <Link className='social-icon'> <BiLink /> </Link>
                </div>
                <div className="about_cardInfo-about">
                    <p className='para'>
                        {props.desc1} {show ? props.desc2 : '…'}
                    </p>
                    {!show &&
                        <p className='read-more' onClick={() => setShow(!show)} >
                            Read More <span><BsChevronDoubleDown /></span>
                        </p>
                    }

                </div>
            </div>
        </div>
    )
}
const About = ({ location }) => {

    const onMatchedRoutes = (matchedRoutes) => {
        return [
            {
                route: {
                    path: `/`,
                    breadcrumbName: 'Home',
                },
            },
            ...matchedRoutes,
        ];
    };
    return (
        <div className='about' >
            <div className="about-hero">
                <img src={Pattern} alt="" />
                <div className="title">About</div>
            </div>

            <div className='about-black'>
                <div className="mission_bg">
                    <div className="container breadcrumbs">
                        <div className='artist-mapping'>
                            <Breadcrumb
                                locationPath={location?.pathname}
                                onMatchedRoutes={onMatchedRoutes}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div className="about_section " >
                            <div className="about_section_title">Mission</div>
                            <div className="about_section_info">
                                Our Mission is to create a dynamic place where the traditional and the digital meet, bringing full value to owners of original art. Through the lenses of legacy, story and imagination, we curate the art and the artist and at the same time bring excitement, experience and insight, minting and marketing NFTs.  We uniquely combine the physical, digital and animated in one experience, working with all parties to create and execute a recipe for success.
                                <br />
                                <br />
                                We are built on the foundations of the trust and reputation of a global art consultancy that cares about its artists and clients and has a track record of innovation. We operate hand in glove with our sister company Emillions Art , a business that has a global reputation for sourcing and dealing fine art assets from old masters, modern masters, and contemporary as well as providing consultancy services in such assets and associated wealth management strategies.</div>
                        </div>
                    </div>
                    <img src={MissionSkew} alt="" />
                </div>
            </div>
            <div className="container">
                <div className="about_section " >
                    <div className="about_section_title">Vision</div>
                    <div className="about_section_info">Our Vision is that the opportunity to access the value offered by NFTs, is fully open to lovers, buyers, holders and creators of rare, high quality, fine art, not just crypto-natives and computer generated artists.</div>
                </div>
            </div>

            <div className="container">
                <div className="about_team">
                    <div className="profile_title">Our Team</div>
                    <div className="profile_collection">
                        <ProfileCard
                            name="Marlissa Gardner"
                            des="Co-founder and CEO"
                            desc1="Marlissa is a highly experienced and influential Art Entrepreneur. She founded Emillions Art in 2014, growing it globally with a reputation for trust, credibility and value from Old Masters to Digital and Contemporary art. Highly networked across the globe, Marlissa is a senior advisor to a blockchain-based "
                            desc2="luxury asset platform, Idoneus, and a frequent influential speaker on art, value and innovation for a range of high-value audiences including Bank of America, Dentons Law, wealth management and Family Offices."
                            img={Marlissa}
                        />
                        <ProfileCard
                            name="Dr. Neil Pennington"
                            des="(COO) Designate"
                            desc1="Neil is a value-driven entrepreneur, technologist and innovator, with over 25 years senior corporate leadership (PwC, IBM, RWE) and 6 years blockchain and decentralised technology start-up experience across fintech, clean energy, cross-border criminal matters, luxury asset trading and Art."
                            desc2="A key member of the team that launched EWChain (and EWT), he has consulted on blockchain and crypto policy for governments, been an advocate for ID2020 and statelessness, and is a frequent speaker and writer on innovation and blockchain."
                            img={Neil}
                        />
                        {/* <ProfileCard
                            name="Rafael Rios"
                            des="Head of Product"
                            desc1="Rafael is an experienced innovator and project manager with a focus on the development of technology platforms and blockchain research. His work includes structuring the development of incentivised blockchain information exchange,"
                            desc2="full content marketing strategies, and advising on the development of APIs and software tools."
                            img={Rafael}
                        />
                        <ProfileCard
                            name="Harman Puri"
                            des="Blockchain Lead"
                            desc1="Harman is a Blockchain thought leader having 4+ years of experience in the space. He has contributed to several DLT and NFT projects as a developer, project manager, architect, and consultant."
                            desc2="He is currently the director of BlockTing, a Blockchain technology, platform and marketing firm."
                            img={Harman}
                        /> */}
                        <ProfileCard
                            name="Mickey Farley"
                            des="Brand Advisor"
                            desc1="Mickey has been a thought leader in the business of Brand Marketing Strategy for nearly 4 decades. She has been in senior management and brand building strategy and innovation consulting (Kraft/General Foods, Mars Inc., Coca Cola, P&G, Ford, Daimler/Chrysler, Toyota, Pepperidge Farm, etc.)"
                            desc2="As an entrepreneur she co founded an Innovation Marketing Firm, Fusion 5 - sold to WPP in 2001, among others, and consults with start-ups and business rejuvenations. Mickey was an Associate Professor of Marketing at NYU Stern School of Business for nearly a decade teaching Brand Strategy and New Product Development."
                            img={Mickey}
                        />
                        <ProfileCard
                            name="Robin Austin"
                            des="Creative Advisor"
                            desc1="Robin has always been a big thinker! His brilliance is in taking insight-based strategy and turning it into creative activation plans that connect, engage and delight a brand’s target consumer. Robin is an innovator that truly understands it is a totally new marketing world "
                            desc2="and has embraced all the technology which is so vital to driving business growth today! Robin has led team efforts that include breakthrough work at Ford, Coca Cola, the re-launch of the Dodge Ram truck, the restaging of Miller Lite beer, “German Engineering” for VW, Virgin, Procter & Gamble, Toyota, and Nike to name just a few."
                            img={RobinA}
                        />
                        <ProfileCard
                            name="Michael Dana"
                            des="External Legal Counsel: Husch Blackwell"
                            desc1="Michael is a partner with Husch Blackwell and business lawyer. He focuses his practice on providing U.S. tax advice to businesses and their owners -in connection with all aspects of business formation, operation and disposition."
                            desc2="Michael’s diverse practice also includes advising clients on corporate, partnership, securities and other business law matters in the US and globally."
                            img={Michael}
                        />
                        {/* <ProfileCard
                            name="Joe Guagliardo"
                            des="External Legal Counsel: Dentons Law"
                            desc1="Joe is a partner in Dentons’ Venture Technology and Emerging Growth Companies practice, where he focuses on representing blockchain protocols, NFT platform providers, cryptocurrency investors, and large enterprises using blockchain technology."
                            desc2="As a technology industry veteran, Joe understands the business of technology and has a very practical appreciation for the business and cross-disciplinary legal matters facing emerging technology businesses in the global economy.  He is a sought-after speaker and author on emerging technology-related topics and technology and intellectual property commercialization and strategy. He has been recognized as a “Top Author” in the JD Supra Readers’ Choice Awards for his thought leadership on blockchain technology."
                            img={Joe}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="about_partners">
                <div className="container">
                    <div className="about_section_title">Our Partners</div>
                    <div className="partners">
                        <div className="partner">
                            <img src={HuschBlackWell} alt="" />
                        </div>
                        <div className="partner">
                            <img src={Impactility} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="about_section_title">Insights</div>
                <div className="about_insights">
                    <div className="about_insights-left">
                        <div className="about_section_info">Being, and remaining, at the Forefront of Art Value requires commitment. We bring our knowledge, insight, and network to bear, to keep you on point with where the market is going and what it means for you, as part of our family of Value-Focused Art lovers, buyers, holders and creators.</div>
                    </div>
                    <div className="about_insights-right">
                        <ul>
                            <li><a href="https://medium.com/@emillionsnft">Blog</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
