import Web3 from "web3";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
toast.configure();

export const MintNft = async (nftContractw, name, description, ipfsLink, category, beneficiery, account, setTxLoading) => {

    await nftContractw
        .methods
        .mintNFT(name, description, ipfsLink, category, "1", beneficiery)
        .send({ from: account })
        .once("transactionHash", () => {
            setTxLoading(true)
        })
        .once("receipt", (receipt) => {
            setTxLoading(false)
            toast.success("Mint Successfully")
        })
        .on("error", () => {
            setTxLoading(false)
            toast.error("Mint Failed")
        });


}

export const CreateOrder = async (mpContract, nftAddress, assetId, priceinEth, expireAt, account, setTxLoading) => {

    const web3 = new Web3(window.ethereum);
    const weiValue = web3.utils.toWei(priceinEth.toString())

    await mpContract
        .methods
        .createOrder(nftAddress, assetId, weiValue, expireAt)
        .send({ from: account })
        .once("transactionHash", () => {
            setTxLoading(true)
        })
        .once("receipt", (receipt) => {
            setTxLoading(false)
            toast.success("Order Successfully Created")
        })
        .on("error", () => {
            setTxLoading(false)
            toast.error("Createding Failed")
        });


}

export const CancelOrder = async (mpContractw, nftAddress, assetId, account, setTxLoading) => {
    await mpContractw
        .methods
        .cancelOrder(nftAddress, assetId)
        .send({ from: account })
        .once("transactionHash", () => {
            setTxLoading(true)
        })
        .once("receipt", () => {
            setTxLoading(false)
            toast.success("Order Cancelled")
        })
        .on("error", () => {
            setTxLoading(false)
            toast.error("Cancelorder Failed")
        });
}

export const SafePlaceBid = async (mpContractw, nftAddress, assetId, priceinEth, expireDate_timestamp, account, setTxLoading) => {
    const web3 = new Web3(window.ethereum);
    const weiValue = web3.utils.toWei(priceinEth.toString())

    await mpContractw
        .methods
        .safePlaceBid(nftAddress, assetId, expireDate_timestamp)
        .send({ from: account, value: weiValue })
        .once("transactionHash", () => {
            setTxLoading(true)
        })
        .once("receipt", (receipt) => {
            setTxLoading(false)
            toast.success("Bid Placed")
        })
        .on("error", () => {
            setTxLoading(false)
            toast.error("Bid Failed");
        });
}

export const AcceptBid = async (mpContractw, nftAddress, assetId, account, setTxLoading) => {
    await mpContractw
        .methods
        .acceptBid(nftAddress, assetId)
        .send({ from: account })
        .once("transactionHash", () => {
            setTxLoading(true)
        })
        .once("receipt", (receipt) => {
            setTxLoading(false)
            toast.success("Bid Successfully Accepted ")
        })
        .on("error", () => {
            setTxLoading(false)
            toast.error("Acceptance Failed")
        });
}

export const CancelBid = async (mpContractw, nftAddress, assetId, account, setTxLoading) => {
    await mpContractw
        .methods
        .cancelBid(nftAddress, assetId)
        .send({ from: account })
        .once("transactionHash", () => {
            setTxLoading(true)
        })
        .once("receipt", (receipt) => {
            setTxLoading(false)
            toast.success("Cancelled Successfully!!")
        })
        .on("error", () => {
            setTxLoading(false)
            toast.error("Cancelled Failed!!")
        });
}

export const ApproveNft = async (nftContractw, approveTo, tokenId, account, setTxLoading) => {

    await nftContractw
        .methods
        .setApprovalForAll(approveTo, tokenId)
        .send({ from: account })
        .once("transactionHash", () => {
            setTxLoading(true)
        })
        .once("receipt", () => {
            setTxLoading(false)
            toast.success("Approved Successfully!!")
        })
        .on("error", () => {
            setTxLoading(false)
            toast.error("Approval Failed!!")
        });

}

export const getNftApproveStatus = async (nftContractw, tokenId) => {
    const value = await nftContractw.methods.getApproved(tokenId).call();
    return value
}

export const ApproveInfinixToken = async (infinixContractw, spenderAddress, amount, account) => {
    await infinixContractw
        .methods
        .approve(spenderAddress, amount)
        .send({ from: account })
        .once("recepient", (recepient) => {
            window.alert("approved fund for trasaction");
        })
        .on("error", (e) => {
            window.alert("approve failed");
        });
}

export const InfinixAllowance = async (infinixContractw, ownerAddress, spenderAddress) => {
    const value = await infinixContractw.methods.allowance(ownerAddress, spenderAddress).call()
    return value
}

export const BidByOrderid = async (mpContractw, nftAddress, tokenId) => {
    const bidDetails = await mpContractw
        .methods
        .bidByOrderId(nftAddress, tokenId).call();
    return bidDetails;
}


export const OrderByAssetid = async (mpContractw, nftAddress, tokenId) => {
    const orderDetails = await mpContractw.methods.orderByAssetId(nftAddress, tokenId).call()
    return orderDetails;
}


export const BalanceOfUser = async (infinixContractw, account) => {
    const balance = await infinixContractw.methods.balanceOf(account).call()
    return balance
}

export const TokenUri = async (nftContractw, assetId) => {
    const tokenuri = await nftContractw.methods.tokenURI(assetId).call()
    return tokenuri
}

