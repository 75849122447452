import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Gold from "../../assets/images/gold-single.svg";
import Pattern from "../../assets/images/signup-hero-bg2.svg";
import CustomButton from "../../components/CustomButton";
import Modal from "../../components/Modal";
import { ReactComponent as CloseIcon } from "../../assets/images/closeIcon.svg";
import LoadingSpinner from "../../components/loading-spinner";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Captcha from "../../components/Captcha";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store";

toast.configure();

const Login = ({ location }) => {
  const history = useHistory();
  // const login = useSelector(state => state.data)
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [otp, setotp] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [captchaValue, setCaptchaValue] = useState();
  const recaptchaRef = React.useRef();
  const close = () => {
    setIsOpen(false);
    setIsWarning(false);
    setfpSuccess(false);
    setCredential({
      email: "",
      password: "",
    });
  };

  const open = () => {
    setIsOpen(true);
    setIsWarning(false);
    setCredential({
      email: "",
      password: "",
    });
  };

  const [isWarning, setIsWarning] = useState(false);
  const [fpSuccess, setfpSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [credential, setCredential] = useState({
    email: "",
    password: "",
  });
  const onMatchedRoutes = (matchedRoutes) => {
    return [
      {
        route: {
          path: `/`,
          breadcrumbName: "Home",
        },
      },
      ...matchedRoutes,
    ];
  };

  const redirectByRole = (userRole) => {
    if (userRole === "buyer") {
      history.push("/");
    } else if (userRole === "artist") {
      history.push("/artist");
    } else if (userRole === "both") {
      history.push("/");
      // user can route on artist as well
    } else if (userRole === "admin") {
      history.push("/admin");
    }
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const Login = () => {
    if (captchaValue) {
      setLoading(true);
      axios
        .post("https://emillionsnft.com/api/login", credential)
        .then((res) => {
          setLoading(false);
          setIsWarning(false);
          setStep(2);
          toast.success(res.data.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setIsWarning(true);
          toast.error(error.response.data.error || "Login Failed");
        });
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    Login();
  };

  const handleOTPverification = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .patch("https://emillionsnft.com/api/login", {
        ...credential,
        otp: otp,
      })
      .then((res) => {
        dispatch(loginAction.setData(res.data.user));

        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        setLoading(false);
        setIsWarning(false);
        toast.success("Successfully verified!!");
      })
      .catch((error) => {
        setLoading(false);
        setIsWarning(true);
        toast.error(error.response.data.error || "verification failed!!");
      });
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("https://emillionsnft.com/api/forgotpassword", {
        email: credential.email,
      })
      .then((res) => {
        setfpSuccess(true);
        setLoading(false);
        setIsWarning(false);
        setCredential({
          email: "",
          password: "",
        });
        toast.success("Email sent!!");
      })
      .catch((error) => {
        setLoading(false);
        setIsWarning(true);
        toast.error(error.response.data.error || "Email sent failed!!");
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredential({ ...credential, [name]: value });
  };

  useEffect(() => {
    let role = parseJwt(localStorage.getItem("token"))?.role;
    redirectByRole(role);
  }, [token]);

  return (
    <>
      <div className="login">
        <div className="login-hero-img">
          <img src={Pattern} alt="" />
        </div>
        <div className="login-container">
          <div className="login-background">
            <img src={Gold} alt="" />
          </div>
          <div className="login-header">
            <div className="mapping">
              <Breadcrumb
                locationPath={location?.pathname}
                onMatchedRoutes={onMatchedRoutes}
              />
            </div>
            <div className="title">Login</div>
          </div>
          {step === 1 && (
            <>
              <div className="login-body">
                <div className="form">
                  <form onSubmit={handleLogin}>
                    <div className="form-group">
                      <label className={`form-input-label`}>
                        Email Address
                      </label>
                      <input
                        name="email"
                        type="email"
                        value={credential.email}
                        className={`form-input ${isWarning ? "warning" : ""}`}
                        placeholder="janedoe@gmail.com"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className={`form-input-label`}>Password</label>
                      <input
                        name="password"
                        type="password"
                        value={credential.password}
                        className={`form-input ${isWarning ? "warning" : ""}`}
                        placeholder="Your password"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <Captcha
                        setCaptchaValue={setCaptchaValue}
                        recaptchaRef={recaptchaRef}
                      />
                    </div>
                    {isWarning && (
                      <div className="invalid-cred">Invalid credential</div>
                    )}
                    <div className="btn-group">
                      <CustomButton type="submit" size="md">
                        Submit
                      </CustomButton>
                      <span onClick={open}>I forgot my password</span>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            // OTP verification
            <>
              <div className="login-body">
                <div className="form">
                  <form onSubmit={handleOTPverification}>
                    <div className="vcode-on-email">
                      Code sent to {credential.email}
                    </div>
                    <div className="form-wrapper">
                      <div className="form-group">
                        <label className="form-input-label">
                          Verification Code
                        </label>
                        <input
                          name="otp"
                          value={otp}
                          onChange={(e) => setotp(e.target.value)}
                          className={`form-input ${isWarning ? "warning" : ""}`}
                        />
                      </div>
                      {isWarning && (
                        <div className="warning">Incorrect code entered</div>
                      )}
                    </div>
                    <CustomButton type="submit" size="lg">
                      Verify
                    </CustomButton>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
        {isOpen && (
          <Modal style={{ width: "63%", padding: 0, backgroundColor: "#fff" }}>
            <div className="mod-content">
              <div className="fp-title">Forgot Password</div>
              {fpSuccess ? (
                <div className="fp-success">
                  <div className="success-message">
                    A password recovery email has been sent to the provided
                    address.
                  </div>
                  <CustomButton size="lg" onClick={close}>
                    Close
                  </CustomButton>
                </div>
              ) : (
                <div className="fp-form">
                  <form onSubmit={handleForgotPassword}>
                    <div className="form-group">
                      <label className={`form-input-label`}>
                        Email Address
                      </label>
                      <input
                        name="email"
                        type="email"
                        value={credential.email}
                        className={`form-input ${isWarning ? "warning" : ""}`}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    {isWarning && (
                      <div className="invalid-email">Email doesn't exist</div>
                    )}
                    <CustomButton size="lg" type="submit">
                      Submit
                    </CustomButton>
                  </form>
                </div>
              )}
            </div>
            <div className="mod-closeIcon">
              <CloseIcon onClick={close} />
            </div>
          </Modal>
        )}
      </div>
      {loading && <LoadingSpinner />}
    </>
  );
};

export default Login;
