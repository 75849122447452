import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/js/swiper.esm";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.min.css";
import { ReactComponent as FileUpload } from "../../assets/images/uploadFile.svg";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner";
import { useWeb3React } from "@web3-react/core";
import ArtworkCard from "./artworkCard";
toast.configure();
require('dotenv').config()

const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // Infura IPFS API

let params = {
  modules: [Pagination, Navigation],
  slidesPerView: 1,
  spaceBetween: 30,
  loop: false,
  pagination: { clickable: true },
  breakpoints: {
    1470: { slidesPerView: 3 },
    760: { slidesPerView: 2 },
    460: { slidesPerView: 1 },
  },
};

// const FilterTab = (props) => {
//   const [isOpen, setIsOpen] = useState(false);
//   return (
//     <div className="filter-tab">
//       <div onClick={() => setIsOpen(!isOpen)} className="filter-title">
//         Select Artist
//         {isOpen ? <FaChevronUp /> : <FaChevronDown />}
//       </div>
//       {isOpen && <div className="ft-body">{props.children}</div>}
//     </div>
//   );
// };

const Artwork = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [ipfshash, setipfshash] = useState("");
  const [buffer, setbuffer] = useState(null);
  const swiperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  let [ethPrice, setEthprice] = useState();
  const [isInvalidAddress, setIsInvalidAddress] = useState(false);
  const [artworks, setArtworks] = useState();
  const [priceError, setPriceError] = useState();
  const { account } = useWeb3React();

  const [artworkDetails, setArtworkDetails] = useState({
    title: "",
    description: "",
    ipfsLink: "",
    category: "legacy",
    address: `${account ? account : ""}`,
    collections: "",
    fileType: "image",
  });

  const [artworkPriceInfo, setArtworkPriceInfo] = useState({
    amount: "",
    currency: "ETH",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArtworkDetails({ ...artworkDetails, [name]: value });
  };

  const walletAddress = JSON.parse(localStorage.getItem("user")).walletAddress;

  let types = ["image/jpeg", "image/png", "image/jpg", "video/mp4"];

  const captureFile = async (e) => {
    let selected = e.target.files[0];
    if(artworkDetails && artworkDetails.fileType === "video"){
      types = ["video/mp4"];
    }
    if(artworkDetails && artworkDetails.fileType === "image"){
      types = ["image/jpeg", "image/png", "image/jpg"];
    }
    if (selected && types.includes(selected.type)) {
      setLoading(true)
      setFile(selected);
      const formData = new FormData();
      formData.append("file", selected);
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      try {
        const response = await axios.post(url, formData, {
          maxContentLength: "Infinity",
          headers: {
            "Content-Type": `multipart/form-data;boundary=${formData._boundary}`,
            pinata_api_key: '729a2bfac173c1f89a2f',
            pinata_secret_api_key: 'b921af42a341602efa779953e8f1c0009480b1c85be4bd58fa9bf50fa208c4e0',
          },
        });
        const ipfsId = response.data.IpfsHash
        setArtworkDetails({
          ...artworkDetails,
          ipfsLink: `https://emillionsnft.mypinata.cloud/ipfs/${ipfsId}`,
        });
        setipfshash(ipfsId)
      } catch (err) {
        setLoading(false);
        toast.error("File Uploading failed");
        console.error(err);
        return;
      }
      setLoading(false);
      setError("");
    } else {
      setFile(null);
      toast.error("Please select a proper file upload type");
      setError("Please select a proper file upload type");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let priceInWei;
    let { amount, currency } = artworkPriceInfo;
    const convertCurrency = async () => {
      if (currency === "USD") {
        priceInWei = amount / ethPrice;
      } else {
        priceInWei = amount;
      }
      // priceInWei = web3.utils.toWei(priceInWei.toString())
    };
    await convertCurrency();

    let body = {
      ...artworkDetails,
      price: priceInWei,
    };

    setLoading(true);
    if (amount > 0) {
      if (ipfshash) {
        await axios
          .post("https://emillionsnft.com/api/art", body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast.success("Successfull created!!");
            setLoading(false);
            setArtworkDetails({
              title: "",
              description: "",
              ipfsLink: "",
              category: "legacy",
              address: `${account ? account : ""}`,
              fileType: "image",
              collections: "",
            });
            setArtworkPriceInfo({
              amount: "",
              currency: "",
            });
            setFile(null);
          })
          .catch((err) => {
            toast.error(err.response.data.error || "Submission Failed!!");
            console.log(err.message);
            setLoading(false);
          });
      }
    } else {
      setPriceError("Minimum price should be greater than 0");
      setLoading(false);
    }
  };

  const getOneEthPrice = () => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
      )
      .then((res) => {
        let oneEthPrice = res.data.ethereum.usd;
        setEthprice(oneEthPrice);
      })
      .catch(() => {
        toast.error("getting ETH error!!");
      });
  };

  const [totalNfts, setTotalNfts] = useState("");
  const [txLoading, setTxLoading] = useState(false);

  const fetchListedArtworks = async () => {
    // /art?address=walletAddress&initialized=true
    await axios
      .get(
        `https://emillionsnft.com/api/art?artistAddress=${account}&initialized=true`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        setArtworks(res.data?.reverse());
      })
      .catch((err) => {
        toast.error(err.response.data.error || "ArtworkFetch Failed!!");
        console.log(err.message);
      });
  };

  // useEffect(async () => {
  //   if (account) {
  //     fetchListedArtworks();
  //     setArtworkDetails({ ...artworkDetails, address: account });
  //   }
  // }, [account, totalNfts]);

  useEffect(() => {
    const fetchData = async () => {
      if (account) {
        fetchListedArtworks();
        setArtworkDetails({ ...artworkDetails, address: account });
      }
    };
    fetchData();
  }, [account, totalNfts]);

  useEffect(() => {
    getOneEthPrice();
  }, []);

  return (
    <div className="dashboard_artwork-tab">
      <section>
        {artworks && artworks.length ? (
          <>
            <div className="title">Listed Artwork</div>
            <div className="cards">
              <Swiper ref={swiperRef} {...params}>
                {artworks.map((data, index) => (
                  <div key={index}>
                    <ArtworkCard data={data} />
                  </div>
                ))}
              </Swiper>
            </div>
          </>
        ) : null}
      </section>
      <section>
        <div className="title">Upload Artwork</div>
        <form onSubmit={handleFormSubmit}>
          <div className="section_container">
            <div className="artwork_left">
              <div className="artwork_form-group">
                <label>Artwork Title</label>
                <input
                  name="title"
                  value={artworkDetails.title}
                  onChange={handleInputChange}
                  type="text"
                  required
                />
              </div>
              <div className="artwork_form-group">
                <label>Artwork Description</label>
                <textarea
                  name="description"
                  value={artworkDetails.description}
                  onChange={handleInputChange}
                  type="text"
                  required
                ></textarea>
              </div>
              <div className="artwork_check">
                <div className="artwork_form-group">
                  <label>Artwork Price</label>
                  <input
                    name="amount"
                    value={artworkPriceInfo.amount}
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setArtworkPriceInfo({
                          ...artworkPriceInfo,
                          amount: e.target.value,
                        });
                        setPriceError("");
                      } else {
                        setArtworkPriceInfo({
                          ...artworkPriceInfo,
                          amount: "",
                        });
                        setPriceError("Minimum price should be greater than 0");
                      }
                    }}
                    type="number"
                    required
                  />
                  {priceError ? (
                    <p className="price_error">{priceError}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="price-checkbox">
                  <div
                    className="options"
                    onChange={(e) =>
                      setArtworkPriceInfo({
                        ...artworkPriceInfo,
                        currency: e.target.value,
                      })
                    }
                  >
                    <label htmlFor="r1" className="radio">
                      ETH
                      <input
                        type="radio"
                        name="currency"
                        value="ETH"
                        id="r1"
                        className="radio--input"
                        defaultChecked
                      />
                      <div className="radio--radio"></div>
                    </label>
                    <label htmlFor="r2" className="radio">
                      DOLLARS
                      <input
                        type="radio"
                        name="currency"
                        value="USD"
                        id="r2"
                        className="radio--input"
                      />
                      <div className="radio--radio"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="artwork_form-group">
                <label>Collection Name</label>
                <input
                  name="collections"
                  value={artworkDetails.collections}
                  onChange={handleInputChange}
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="artwork_right">
              <div className="artwork_form-group">
                <label>Upload Artwork Files</label>
                {/* CAPTURE FILE */}
                <div className="form-input-asset">
                  <input
                    name="imageUpload"
                    id="imageUpload"
                    type="file"
                    onChange={captureFile}
                  />
                  <label htmlFor="imageUpload" className="select-asset-image">
                    {file ? (
                      ["image/jpeg", "image/png", "image/jpg"].includes(file.type) ? (
                        <div
                          className="select-asset-image"
                          style={{
                            backgroundImage: `url(${artworkDetails?.ipfsLink})`,
                          }}
                        ></div>
                      ) : (
                        <p>
                          <span className="file-name">
                            {file.name.substring(0, 15)}
                          </span>{" "}
                          ...
                        </p>
                      )
                    ) : (
                      <div className="content">
                        <FileUpload />
                        <p>
                          Drag and drop or <span>browse</span> your files
                        </p>
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="column-2">
                <div className="artwork_form-group">
                  <label>Artwork Category</label>
                  <select
                    name="category"
                    value={artworkDetails.category}
                    onChange={(e) =>
                      setArtworkDetails({
                        ...artworkDetails,
                        category: e.target.value,
                      })
                    }
                  >
                    <option value="legacy">Legacy</option>
                    <option value="story">Story</option>
                    <option value="imagination">Imagination</option>
                  </select>
                </div>
                <div className="artwork_form-group">
                  <label>File Type</label>
                  <select
                    name="fileType"
                    defaultValue={artworkDetails.fileType}
                    onChange={handleInputChange}
                  >
                    <option value="image" name="image">Image</option>
                    <option value="video" name="video">Video</option>
                  </select>
                </div>
              </div>
              <div className="artwork_form-group">
                {/* <label>Artwork Address</label>

                <input
                  name="address"
                  value={
                    artworkDetails.address ? artworkDetails.address : "..."
                  }
                  type="text"
                  required
                  disabled={true}
                />
                {isInvalidAddress ? <p>Invalid address</p> : ""} */}
                <h3>Artwork Address</h3>
                <p style={{ fontSize: "1.2rem", marginTop: "0.5rem" }}>
                  {walletAddress}
                </p>
              </div>
            </div>
          </div>
          <div className="artwork_submit">
            <button type="submit">SUBMIT</button>
          </div>
        </form>
      </section>

      {loading ? <LoadingSpinner /> : null}
      {txLoading ? <LoadingSpinner /> : null}
    </div>
  );
};

export default Artwork;
