import React, { useState, useEffect, useRef } from "react";
import Gold from "../../assets/images/gold-single.svg";
import Pattern from "../../assets/images/artist-hero.svg";
import CustomButton from "../../components/CustomButton";
import Breadcrumb from "../../components/Breadcrumb";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../../components/loading-spinner";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { ReactComponent as CloseIcon } from "../../assets/images/closeIcon.svg";
import { toast } from "react-toastify";
import ScrollToTop from '../../components/scrollToTop';

toast.configure();

const ArtistCard = ({ artist }) => {
  const userData = useSelector((state) => state.data);
  const { id, email, biography, profileImage, username } = artist;
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [editBioModal, setEditBioModal] = useState(false);
  const [newBio, setNewBio] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    history.push(`${location.pathname}/${id}`);
  };
  const mainRef= useRef(null);

  const handleEditBio = () => {
    setEditBioModal(true);
  };

  const handleCloseEditBio = () => {
    setEditBioModal(false);
  };

  const onBioChange = (e) => {
    setNewBio(e.target.value);
  };

  const editBioFormHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .patch(
        `https://emillionsnft.com/api/users/${email}`,
        { biography: newBio },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Successfully updated!");
      })
      .catch((err) => {
        toast.error(err.response.data.error || "Update Failed!!");
        console.log(err.message);
      });
    setLoading(false);
  };

  const dummyImg =
    "https://cdn.pixabay.com/photo/2021/03/14/11/14/fish-6093991_960_720.jpg";

  useEffect(() => {
    setNewBio(biography);
  }, []);

  return (
    <ScrollToTop>
        <div className="artist-card">
          {loading && <LoadingSpinner />}
          {editBioModal && (
            <Modal style={{ width: "40%", padding: "1rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "2rem",
                }}
              >
                <div style={{ padding: "2rem" }}>
                  <div className="mod-closeIcon">
                    <CloseIcon onClick={handleCloseEditBio} />
                  </div>
                  <form onSubmit={editBioFormHandler}>
                    <textarea
                      name="editBio"
                      value={newBio}
                      label="New Bio"
                      onChange={onBioChange}
                      rows="15"
                      cols="60"
                      style={{ padding: "0.7rem", fontSize: "1rem" }}
                    ></textarea>
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        padding: "0.5rem 0.5rem",
                        margin: "0.7rem 0",
                        borderRadius: "0.5rem",
                        border: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Change Bio
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          )}
          <div className="artist-card-image">
            <img
              src={profileImage ? profileImage : dummyImg}
              alt="profileImage"
            />
          </div>
          <div className="artist-card-name">{username ? username : "NoName"}</div>
          {/* <div className="artist-card-category">{email}</div> */}
          <div className="artist-card-social">
            {/* <Link className='social-icon'> <BsLinkedin /> </Link> */}
            {/* <Link className='social-icon'> <BiLink /> </Link> */}
          </div>
          <div className="artist-card-info">
            <p className="para">
              {!show && biography.slice(0, 120)}
              {show && biography?.length > 120
                ? biography
                : biography?.length > 120
                ? "..."
                : ""}
            </p>
            {!show && biography?.length > 120 && (
              <p className="read-more" onClick={() => setShow(!show)}>
                Read More{" "}
                <span>
                  <BsChevronDoubleDown />
                </span>
              </p>
            )}
          </div>
          <div className="view-nfts">
            <CustomButton size="md" onClick={handleClick}>
              {" "}
              View NFTs
            </CustomButton>
            {userData && userData.role === "admin" && (
              <CustomButton
                size="md"
                onClick={handleEditBio}
                style={{ margin: "0 0.5rem" }}
              >
                {" "}
                Edit Bio
              </CustomButton>
            )}
          </div>
        </div>
      </ScrollToTop>
  );
};
const ArtistMain = () => {
  const location = useLocation();
  const mainRef= useRef(null);
  const [visible, setVisible] = useState(4);
  const [vArtist, setvArtist] = useState(null);
  const [loading, setLoading] = useState(false);

  const onMatchedRoutes = (matchedRoutes) => {
    return [
      {
        route: {
          path: `/`,
          breadcrumbName: "Home",
        },
      },
      ...matchedRoutes,
    ];
  };
  // const showMoreCards = () => {
  //     setVisible(prevValue => prevValue + 6)
  // }

  const fetchVartist = () => {
    setLoading(true);
    axios
      .get("https://emillionsnft.com/api/artist")
      .then((res) => {
        setvArtist(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVartist();
  }, []);

  // useEffect(() => {
  //   mainRef.current.focus();
  // }, [mainRef]);

  return (
    <ScrollToTop>
      <div className="artist-main">
        <div className="artist-hero">
          <img src={Pattern} alt="" />
          <div className="title">Artists</div>
        </div>

        <div className="container">
          <div className="artist-mapping">
            <Breadcrumb
              locationPath={location.pathname}
              onMatchedRoutes={onMatchedRoutes}
            />
          </div>
          <div className="artist-collections">
            <div className="background">
              <img src={Gold} alt="" />
            </div>
            <div className="collection">
              {vArtist &&
                vArtist.map((item, index) => (
                  <ArtistCard key={index} artist={item} />
                ))}
            </div>
          </div>
        </div>
        {loading && <LoadingSpinner />}
      </div>
    </ScrollToTop>
  );
};

export default ArtistMain;
