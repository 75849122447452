import React from "react";
import Robin from "../../assets/images/Robin_Austin.jpg";
import CustomButton from "../../components/CustomButton";
import SkewBackground from "../../assets/images/skew.svg";
// import Pattern from '../../assets/images/signup-hero.png'
import Pattern from "../../assets/images/gold-bg.png";
import Gold from "../../assets/images/gold-single.svg";
import Legacy from "../../assets/images/Legacy.jpg";
import Story from "../../assets/images/Story.jpg";
import Imagination from "../../assets/images/Imagination.jpg";
import UpcomingEvents from "../../assets/images/UpcomingEvents.png";
import FeaturedHome from "../../assets/images/FeaturedHome.jpg";
import FeaturedHomeLogo from "../../assets/images/FeaturedHomeLogo.png";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Fade } from 'react-awesome-reveal'

// const BlogCard = ({ order }) => (
//     <div className="blog-card" style={{ order: order }} >
//         <div className="title">Imagination</div>
//         <div className="sub-title">Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus.</div>
//         <div className="body">
//             <div className="image">
//                 <img src={Robin} alt="" />
//             </div>
//             <div className="text">
//                 <h1 className='name' >Dali Blue</h1>
//                 <h1 className='profession' >Robin Austin, Classics Collection</h1>
//                 <p className='info' >Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.</p>
//             </div>

//             <CustomButton size="lg" >Details</CustomButton>
//         </div>

//     </div>
// )

const Home = () => {
  const history = useHistory();
  return (
    <div className="home">
      <div
        className="hero-image"
        style={{ backgroundImage: `url(${Pattern})` }}
      >
        <div className="hero-image-content">
          <Fade cascade triggerOnce delay={300} damping={0.5} duration={1200} >
            <div className="title">
              Where the physical and the digital meet at the forefront of art
              value.
            </div>
            <div className="subtitle">
              Not just any NFT. Rare, exciting, <br /> exceptional and
              experiential value.
            </div>
          </Fade>

          <Fade delay={1800} triggerOnce >
            <Link to="/featured">
              <CustomButton size="lg">
                Explore <AiOutlineRight />{" "}
              </CustomButton>
            </Link>
          </Fade>
        </div>
      </div>
      <div className="hero">
        <div className="skew">
          <img src={SkewBackground} alt="" />
        </div>
        <div className="hero-container">
          <div className="hero-grid">
            <div className="left">
              <div className="category">Featured</div>
              <div className="title" style={{fontSize:"20px"}}>FRANK SINATRA “The Price Of Seduction”</div>
              <div className="owner">
                <div className="avatar">
                  <img src={Robin} alt="" />
                </div>
                <div className="username">Robin Austin</div>
              </div>
              <div className="info" style={{fontSize:"14px"}}>
								Archival Fine Art Print Mounted on Acrylic. Size 60” x 40” Created 2021 
								Most people don’t know that Frank Sinatra has an arrest record and a mugshot to match. The official charge? Seduction (an offence targeted at men enticing single women into pre- marital relations). In 1938, a 23-year-old Sinatra was officially arrested and booked. The charge was eventually dropped when it was discovered that the woman was married. This mosaic is a celebration of Sinatra’s life with this iconic mug shot.
              </div>
              <CustomButton
                size="lg"
                onClick={() => {
                  history.push("/art/legacy");
                }}
              >Details</CustomButton>
            </div>
            <div className="right">
              <div className="right-wrapper">
                <div className="img-wrapper right-img-wrapper" style={{ height: '600px', margin: '0 auto 30px' }} >
                  <img src={Legacy} alt="" />
                </div>
                <div className="img-info">
                  Includes one original canvas and one Fine Art NFT, which
                  includes original digital file and animation
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-us">
        <div className="about-container">
          <div className="title">At the Forefront of Art Value</div>
          <div className="info">
            Curation. Rarity. Education. Insight. Thoughtfulness. Passion. This
            is how we stay at the Forefront of Art Value. We offer a curated
            selection of fine artworks from premier digital artists, a premium
            full service custom NFT minting process which offers protection
            through proof of ownership and Provenance for our owners, as well as
            facilitated access for purchase, ownership, and transfer of these
            assets, both physical and digital.
          </div>
          {/* <div className="read-more"> <span>Read More <AiOutlineRight /></span> </div> */}
        </div>
      </div>
      <div className="section">
        <div className="background-image">
          <img src={Gold} alt="" />
        </div>
        <div className="container">
          <div className="collection-grid">
            <div className="blog-card item">
              <div className="title">Legacy</div>
              <div className="sub-title">
                Creating and seeking Legacy. Here you will find a growing
                portfolio, bringing to life the historical legacy of iconic
                people, places and events; combined with legacies to be drawn
                across nature and the issues and movement of people.
              </div>
              <div className="read-more">
                <Link to="/art/legacy">
                  <span>
                    Explore Legacy Artwork <AiOutlineRight />
                  </span>
                </Link>
              </div>
              <div className="body">
                <div className="image" style={{ height: '620px', margin: '0 auto 30px' }}>
                  <img src={Legacy} alt="" />
                </div>
                <div className="text">
                  <h1 className="name">
										FRANK SINATRA “The Price Of Seduction”
                  </h1>
                  <h1 className="profession">Robin Austin</h1>
                  <p className="info">
									Archival Fine Art Print Mounted on Acrylic. Size 60” x 40” Created 2021 
									Most people don’t know that Frank Sinatra has an arrest record and a mugshot to match. 
									The official charge? Seduction (an offence targeted at men enticing single women into pre- marital relations). 
									In 1938, a 23-year-old Sinatra was officially arrested and booked. The charge was eventually dropped when it was discovered that the woman was married. This mosaic is a celebration of Sinatra’s life with this iconic mug shot.
                  </p>
                </div>
                <CustomButton
                  size="lg"
                  onClick={() => {
                    history.push("/art/legacy");
                  }}
                >
                  Details
                </CustomButton>
              </div>
            </div>
            <div className="blog-card item">
              <div className="title">Imagination</div>
              <div className="sub-title">
                Flashes of inspiration. Placing in art that which has previously
                lived in the imagination. Whether from the world of movies and
                entertainment or from the mind and soul of the creator. The
                future imagined, the present reconstituted or the past
                recreated.
              </div>
              <div className="read-more">
                <Link to="/art/imagination">
                  <span>
                    Explore Imagination Artwork <AiOutlineRight />
                  </span>
                </Link>
              </div>

              <div className="body">
                <div className="image">
                  <img src={Story} alt="" />
                </div>
                <div className="text">
                  <h1 className="name">Eyes Wide Shut</h1>
                  <h1 className="profession">Layla Love</h1>
                  <p className="info">
                    This image was composed at the center of the earth, a mile
                    deep just outside Bogota, Columbia in a salt mine cathedral.
                    The concept shared in this work is that we ourselves are
                    golden. I am inviting you to be an alchemist -- transforming
                    everything you touch into gold. Relating the passage of time
                    to the sand in an hourglass. ... This NFT is a reminder to
                    see every moment as a golden opportunity to make your dreams
                    come true-- hence the woman's closed eyes-- envisioning the
                    life you desire is key to becoming.
                  </p>
                </div>
                <CustomButton
                  size="lg"
                  onClick={() => {
                    history.push("/art/imagination");
                  }}
                >
                  Details
                </CustomButton>
              </div>
            </div>
            <div className="blog-card item">
              <div className="title">Story</div>
              <div className="sub-title">
                Compelling Stories. A picture, a moment, a video clip can
                communicate a story like no other medium. Here, the art
                resonates with a story; whether it be from a moment in history,
                a life once lived, an iconic event, an experience shared or from
                a journey about to commence; a future foretold.
              </div>
              <div className="read-more">
                <Link to="/art/story">
                  <span>
                    Explore Story Artwork <AiOutlineRight />
                  </span>
                </Link>
              </div>

              <div className="body">
                <div className="image">
                  <img src={Imagination} alt="" />
                </div>
                <div className="text">
                  <h1 className="name">Ferrari Blue</h1>
                  <h1 className="profession">Jim Salvati</h1>
                  <p className="info"></p>
                </div>
                <CustomButton
                  size="lg"
                  onClick={() => {
                    history.push("/art/story");
                  }}
                >
                  Details
                </CustomButton>
              </div>
            </div>
            <div className="blog-card events item ">
              <div className="title">Upcoming Events</div>
              <div className="sub-title">“Ravielli Rediscovered” Exhibition – The Event that Revolutionises Art History</div>
              <div className="date">
                March 25 to June 19, 2022 . Augusta Museum of History, Georgia, USA
              </div>
              <div className="more-on-events">
                <div className="img-wrap">
                  <img src={UpcomingEvents} alt="" />
                </div>
                <div>
                  <div className="rm-title">
                    <p>
                      The exhibition features Anthony Ravielli’s illustrations of golf greats, including winners of the Titleholders golf tournament and Masters© - Bobby Jones, Ben Hogan, and Patty Berg to name a few. 
                    </p>
                    <br></br>
                    <p>
                      This is one of the world’s first exhibitions where physical artworks will be showcased alongside its digital NFTs, in a leading museum.
                    </p>
                    <br></br> 
                  </div>
                  {/* <div className='rm-subtitle' ><span>Read More <AiOutlineRight /></span></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
