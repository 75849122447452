import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/style/collection-item.css';
import Modal from './Modal1'
import FormInput from './FormInput1';
import { ApproveNft, CreateOrder, TokenUri } from './myContract';
import { useMarketplaceContract, useMarketplaceWriteContract, useNftContract, useNftContractWrite } from '../hooks/useContract';
import { useWeb3React } from '@web3-react/core';

const CollectionItem = ({ item }) => {
    const { token_id } = item;
    const { account } = useWeb3React();
    const [nftMetadata, setNftMetadata] = useState([]);
    const mpContract = useMarketplaceContract();
    const mpContractw = useMarketplaceWriteContract();
    const nftContract = useNftContract();
    const nftAddress = item.asset_contract.address;
    const nftContractw = useNftContractWrite(nftAddress);
    const [nftStatus, setnftStatus] = useState(0)
    const [orderOfAssetIdValue, setOrderOfAssetIdValue] = useState(null)
    const [tokenUri, setTokenUri] = useState(null)
    const [nftImageUrl, setNftImageUrl] = useState(null)

    const getStatus = async () => {
        await nftContractw.methods.getApproved(token_id).call()
            .then(response => {
                setnftStatus(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getStatus();
    }, [nftContractw, nftStatus])

    const [modal, setModal] = useState(false);

    const [_createOrder, set_createOrder] = useState({
        priceinEth: "",
        expireAt: ""
    })

    const onInputChange = (e) => {
        const { name, value } = e.target;
        set_createOrder({ ..._createOrder, [name]: value })
    }

    function trimAdd(add = "0x00", l = 5) {
        return (
            String(add).slice(0, 2) +
            String(add).slice(2, 2 + l) +
            "..." +
            String(add).slice(add.length - l, add.length)
        );
    }


    const getTimestamp = () => {
        const timestamp = Date.now()
        return parseInt(timestamp / 1000);
    }

    const getqualifyTimestamp = (value) => {
        value = parseInt(value);
        const currentTimestamp = getTimestamp();
        if (value > currentTimestamp + 86400) {
            return true
        }
    }

    let isOrderbyAssetIdExist;
    if (orderOfAssetIdValue) {
        isOrderbyAssetIdExist = parseInt(orderOfAssetIdValue.price)
    }


    const CreateOrderSubmit = (e) => {
        e.preventDefault();
        const { priceinEth, expireAt } = _createOrder;
        const assetId = token_id;
        const isValidTimestamp = getqualifyTimestamp(expireAt);
        if (isValidTimestamp) {
            if (nftStatus && !isOrderbyAssetIdExist) {
                CreateOrder(mpContractw, nftAddress, assetId, priceinEth, expireAt, account)
                setModal(false)
            }
        } else {
            alert("Minimum Expiry's 1 Day")
            set_createOrder({ expireAt: "" })
        }
    }

    const ApproveMyNft = async (e) => {
        e.preventDefault();
        await ApproveNft(nftContractw, "0xCf52dd2218220dd58B06eF52cF9Ea8DBf773DD05", token_id, account)
        await getStatus();
    }

    useEffect(() => {
        const getTokenUri = async (e) => {
            await TokenUri(nftContractw, token_id)
                .then(res => {
                    setTokenUri(res)
                })
                .catch(error => {
                    console.log("tokenuri error", error)
                })
        }

        if (tokenUri) {
            const fetchNftData = async () => {
                axios
                    .get(`https://ipfs.io/ipfs/${tokenUri.slice(7)}`)
                    .then((response) => {
                        setNftMetadata(response.data);
                        setNftImageUrl(response.data.image);
                    });
            };
            fetchNftData();
        }

        const getOrderDetailsOfAssetId = async () => {
            const fetchOrderDetails = await mpContractw.methods.orderByAssetId(nftAddress, token_id).call()
            setOrderOfAssetIdValue(fetchOrderDetails)
        }

        getOrderDetailsOfAssetId();
        getTokenUri();
    }, [tokenUri, nftImageUrl])


    return (
        <div className="collection-item">
            {modal &&
                <Modal handleClose={() => setModal(false)}>
                    <form onSubmit={CreateOrderSubmit} className='collection_formModal' >

                        <h2>({trimAdd(nftAddress)})</h2>
                        <FormInput
                            name="priceinEth"
                            value={_createOrder.priceinEth}
                            label="priceinEth (uint256)"
                            handleChange={onInputChange} />
                        <FormInput
                            name="expireAt"
                            value={_createOrder.expireAt}
                            label="expireAt (uint256)"
                            handleChange={onInputChange} />

                        <button type="submit" className="get-btn">Create Order</button>
                    </form>
                </Modal>
            }

            <div className="collection-img" >
                <p>AssetId: {token_id}</p>
                {nftImageUrl && <img src={`https://ipfs.io/ipfs/${(nftImageUrl).slice(7)}`} alt="" />}
            </div>
            <div className="collection-footer">
                {parseInt(nftStatus) ?
                    <span className="collection-btn cf-order" onClick={() => setModal(!modal)} >Create Order</span>
                    : <span className="collection-btn cf-approve" onClick={ApproveMyNft} >Approve Nft</span>
                }
            </div>

        </div>
    )
}

export default CollectionItem;