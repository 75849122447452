import React, { useState } from 'react';
import '../assets/style/mintnft-card.css'
import { useWeb3React } from '@web3-react/core';
import { useNftContractWrite } from '../hooks/useContract';
import { getNftAddress } from '../utils/addressHelpers';
import FormInput from './FormInput1';
import { MintNft } from './myContract';
import LoadingSpinner from './loading-spinner';

const MintNftCard = ({ item }) => {
    const { name, category, ipfsLink, description } = item;
    const { account } = useWeb3React();
    const nftAddress = getNftAddress()
    const nftContractw = useNftContractWrite(nftAddress);
    const [txLoading, setTxLoading] = useState();
    const [mintData, setmintData] = useState({
        beneficiery: ""
    })

    const MintMyNft = async (event) => {
        event.preventDefault()
        const { beneficiery } = mintData;
        await MintNft(nftContractw, name, description, ipfsLink, category, beneficiery, account, setTxLoading)
        setmintData({
            beneficiery: ""
        })
    }

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setmintData({ ...mintData, [name]: value })
    }
 
    return (
        <div className="nft-item-grid">
            <div className="nft-item-left">
                {ipfsLink && <img src={ipfsLink} alt="" />}
            </div>
            <div className="nft-item-right">
                <div className="nft-mintinfo">
                    {/* <p><span>Name: </span> {name}</p> */}
                </div>

                <div className="nft-buttons-action" >
                    <form onSubmit={MintMyNft}>
                        <FormInput
                            name="beneficiery"
                            value={mintData.beneficiery}
                            label="Beneficieary address"
                            handleChange={onInputChange}
                        />
                        <button type='submit' className="btn">
                            {txLoading ? "Minting..." : "Mint"}
                        </button>
                    </form>
                </div>
            </div>
            {txLoading ? <LoadingSpinner /> : null}
        </div>
    )
}

export default MintNftCard;
