export default {
  nft: {
    1: "0x336159bc237945cD598744c5eB39C135F4700462",
    4: "0x24C331CbC7517D93f0E8Cd03da9f3D36bF3DD564",
  },
  marketplace: {
    1: "0x115AC133B7267Ea05D146CaA64D4140425A43DC8",
    4: "0xFFcebdFf06449119Ebb55F912518113435CBE012",
  }
};
