// import Web3 from 'web3'
import web3NoAccount from './web3'

// Addresses
import {
  getNftAddress,
  getMarketplaceAddress,
} from './addressHelpers'
import Web3 from "web3"
// ABI
import marketplaceABI from '../config/abi/marketplace.json'
import nftAbi from '../config/abi/nft.json'

export const getContract = (abi, address, Web3) => {
  const web3 = Web3 ? Web3 : web3NoAccount
  return new web3.eth.Contract(abi, address)
}

export const getMarketplaceContract = (web3) => {
  return getContract(marketplaceABI, getMarketplaceAddress(), web3)
}

export const getMarketplaceWrite = () => {
  const web3 = new Web3(window.ethereum)
  return getContract(marketplaceABI, getMarketplaceAddress(), web3)
}

export const getNftContract = (web3) => {
  return getContract(nftAbi, getNftAddress(), web3)
}

export const getNftContractWrite = (address) => {
  const web3 = new Web3(window.ethereum)
  return getContract(nftAbi, address, web3)
}