import { configureStore, createSlice } from "@reduxjs/toolkit";


const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    data: user ? user : null,
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
            localStorage.setItem('user', JSON.stringify(state.data))
        },
        removeData: (state, action) => {
            state.data = null
            localStorage.clear()
        },
        updateData: (state, action) => {
            state.data = action.payload
            localStorage.removeItem('user')
            localStorage.setItem('user', JSON.stringify(state.data))
        }
    }
})


export const loginAction = loginSlice.actions

export default configureStore({
    reducer: loginSlice.reducer
})