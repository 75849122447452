import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Pattern from "../../assets/images/legacy-hero.svg";
import Gold from "../../assets/images/gold-single.svg";
import CustomButton from "../../components/CustomButton";
import { BsChevronDoubleDown } from "react-icons/bs";
import { MdOutlineZoomOutMap } from "react-icons/md";
import Modal from "../../components/Modal";
import { ReactComponent as CloseIcon } from "../../assets/images/closeIcon.svg";
import { useEffect } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import LoadingSpinner from "../../components/loading-spinner";
// import { ReactComponent as GoldEmillion } from "../images/artist/gold-new_E5.svg";
import { ReactComponent as GoldEmillion } from "../../assets/images/artist/gold-new_E5.svg";
import {
  MintNft,
  PurchaseOrder,
  CancelOrder,
  UpdateOrder,
} from "../../utils/contractFunctions";
import Web3 from "web3";
import mpAbi from "../../config/abi/marketplace.json";
import nftAbi from "../../config/abi/nft.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import {
  getMarketplaceAddress,
  getNftAddress,
} from "../../utils/addressHelpers";
toast.configure();

const CollectionCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const history = useHistory();

  return (
    <>
      <div className="nft-card">
        <div className="image" onClick={open}>
          {props?.item?.fileType === "image" ? (
            <img src={props?.item?.ipfsLink} alt="" />
          ) : null}
          {props?.item?.fileType === "video" ? (
            <video src={props?.item?.ipfsLink} autoPlay muted loop></video>
          ) : null}
        </div>
        <div className="info">
          <div className="title">{props && props.item && props.item.title}</div>
          <div className="sub-title">
            {props &&
              props.item &&
              props.item.description.slice(0, 60) + " ..."}
          </div>
          <a
            href={`/art/${props && props.item && props.item.category}/${
              props && props.item && props.item.nftAddr
            }/${props && props.item && props.item.nftId}`}
            target={"_blank"}
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <CustomButton size="md">Details</CustomButton>
          </a>
        </div>
        {isOpen && (
          <Modal>
            <div className="mod-grid">
              <div className="image">
                {props?.item?.fileType === "image" ? (
                  <img src={props?.item?.ipfsLink} alt="" />
                ) : null}
                {props?.item?.fileType === "video" ? (
                  <video src={props?.item?.ipfsLink} autoPlay muted loop></video>
                ) : null}
              </div>
              <div className="content">
                <div className="name">
                  {props && props.item && props.item.title}
                </div>
                <div className="body">
                  {props && props.item && props.item.description}
                </div>
                <a
                  href={`/art/${props && props.item && props.item.category}/${
                    props && props.item && props.item.nftAddr
                  }/${props && props.item && props.item.nftId}`}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <CustomButton
                    size="md"
                    // onClick={() => {
                    //   history.push(
                    //     `/art/${props && props.item && props.item.category}/${
                    //       props && props.item && props.item.nftAddr
                    //     }/${props && props.item && props.item.nftId}`
                    //   );
                    // }}
                  >
                    Details
                  </CustomButton>
                </a>
              </div>
            </div>
            <div className="mod-closeIcon">
              <CloseIcon onClick={close} />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

const LegacyDetails = ({ match }) => {
  const { nftAddress, assetId } = match.params;
  const history = useHistory();

  const { account } = useWeb3React();
  const [tab, setTab] = useState(1);
  const [isZoom, setIsZoom] = useState(false);
  const [txLoading, setTxLoading] = useState();
  const [ethPrice, setEthPrice] = useState("");
  const [nftDetails, setNftDetails] = useState(null);
  const [categoryNftDetails, setCategoryNftDetails] = useState(null);
  const [updatePrice, setUpdatePrice] = useState("");
  const [reinitializedPrice, setReinitializedPrice] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const [showReadMore, toggleReadMore] = useState(false);
  // const [purchaseTimeStart, setPurchaseTimeStart]

  const [orderDetails, setOrderDetails] = useState(null);

  const loginData = useSelector((state) => state.data);

  const marketPlaceAddress = getMarketplaceAddress();
  const nftContractAddress = getNftAddress();

  const web3 = new Web3(window.ethereum);
  const nftContract = new web3.eth.Contract(nftAbi, nftContractAddress);
  const mpContract = new web3.eth.Contract(mpAbi, marketPlaceAddress);
  const [ownerAddr, setOwnerAddr] = useState("")

  const fetchNftDetails = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    axios
      .get(`https://emillionsnft.com/api/minted/${assetId}`, {
        headers: headers,
      })
      .then((res) => {
        setNftDetails(res.data);
        console.log(res.data)
        setOwnerAddr(res.data?.ownerAddr)
        setOrderDetails(res.data.orders.reverse());
        if (res.data?.orders?.length === 0) {
          setTab(2)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchCategoryNftDetails = () => {
    setTxLoading(true);
    axios
      .get(
        `https://emillionsnft.com/api/minted?status[ne]=not&category=${
          nftDetails && nftDetails.category
        }`
      )
      .then((res) => {
        setTxLoading(false);
				console.log("assetId: ", assetId);
				console.log("ownerAddr: ", ownerAddr);
        let result = res.data;
				console.log("result: ", result);
        const filteredArray = result.filter((item) => (item.nftId !== assetId) && (item.ownerAddr === ownerAddr) );
				console.log("filteredArray: ", filteredArray);
        setCategoryNftDetails(filteredArray);
      })
      .catch((err) => {
        console.log(err.message);
        setTxLoading(false);
      });
  };

  const handleMintNft = async () => {
    const { nftOrderPrice, nftId } = nftDetails;
    if (loginData?._id) {
      await MintNft(nftContract, nftOrderPrice, nftId, account);
    } else {
      history.push("/login");
    }
  };

  const handlePurchaseNft = async () => {
    const { nftOrderPrice, nftId } = nftDetails;
    if (loginData?._id) {
      await fetchNftDetails();
      let status = nftDetails.status;
      if (status != "not") {
        await PurchaseOrder(
          mpContract,
          nftOrderPrice,
          nftAddress,
          nftId,
          account,
          setTxLoading
        );
        setTimeout(() => {
          fetchNftDetails();
        }, 5000);
        history.go(0);
      } else {
        toast.warning("NFT already purchased & is not for sale");
      }

      history.push("/art/legacy");
    } else {
      history.push("/login");
    }
  };

  const handleReinitializeNft = async () => {
    if (reinitializedPrice === "") {
      toast.warning("Please enter a price");
    } else {
      try {
        axios.post(
          `https://emillionsnft.com/api/minted/${assetId}`,
          { price: reinitializedPrice },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } catch (error) {
        toast.error("Error while reinitializing NFT");
      }
    }
  };

  const handleUpdateNft = async () => {
    if (updatePrice === "") {
      toast.error("Please enter a price.");
    } else {
      try {
        const res = await UpdateOrder(
          mpContract,
          nftAddress,
          assetId,
          updatePrice,
          account,
          setTxLoading
        );
        history.go(0);
      } catch (error) {
        toast.error("Error while updating price");
      }
    }
  };

  const handleCancelNft = async () => {
    try {
      const res = await CancelOrder(
        mpContract,
        nftAddress,
        assetId,
        account,
        setTxLoading
      );
      history.replace("/");
    } catch (error) {
      toast.error("Error while cancelling NFT");
    }
  };

  const getOneEthPrice = () => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
      )
      .then((res) => {
        let oneEthPrice = res.data.ethereum.usd;
        setEthPrice(oneEthPrice);
      })
      .catch(() => {
        toast.error("getting ETH error!!");
      });
  };

  function trimAdd(add = "0x00", l = 4) {
    return (
      String(add).slice(0, 2) +
      String(add).slice(2, 2 + l) +
      "..." +
      String(add).slice(add.length - l, add.length)
    );
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    fetchNftDetails();
    getOneEthPrice();
    
  }, []);

  return (
    <>
      <div className="legacy-details">
        <div className="legacy-details-hero">
          <img src={Pattern} alt="" />
          <div className="art-routing">
            <div className="route">
              <Link to="/art/legacy">Legacy</Link>
            </div>
            <div className="route">
              <Link to="/art/story">Story</Link>
            </div>
            <div className="route">
              <Link to="/art/imagination">Imagination</Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="legacy-details-background">
            <img src={Gold} alt="" />
          </div>
          <div className="legacy-details-body">
            <div className="legacy-details-body-left">
              <div className="mapping"></div>
              <div className="title">
                {nftDetails ? nftDetails.title : "..."}
              </div>
              <div className="subtitle">
                {nftDetails ? `Collection: ${nftDetails.collections}` : ""}
              </div>
              <div className="legacy-details-grid">
                <div className="ldg_image">
                  <div className="background-image">
                    {nftDetails?.fileType === "image" ? (
                      <img src={nftDetails?.ipfsLink} alt="" />
                    ) : null}
                    {nftDetails?.fileType === "video" ? (
                      <video src={nftDetails?.ipfsLink} autoPlay loop></video>
                    ) : null}
                    {nftDetails?.fileType ? (
                      <div className="zoom-out" onClick={() => setIsZoom(true)}>
                        <MdOutlineZoomOutMap />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="ldg_text">
                  <div className="text_1">
                    {nftDetails ? nftDetails.description : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="legacy-details-body-right">
              <div className="user">
                <div
                  className="user-avatar"
                  style={{
                    backgroundImage: `url(${
                      nftDetails &&
                      nftDetails.artistDetails &&
                      nftDetails.artistDetails.profileImage
                    })`,
                  }}
                ></div>
                <div className="user-name">
                  <p
                    className="my-0"
                    onClick={() => {
                      history.push(
                        `/artist/${
                          nftDetails &&
                          nftDetails.artistDetails &&
                          nftDetails.artistDetails._id
                        }`
                      );
                    }}
                  >
                    {" "}
                    {nftDetails &&
                      nftDetails.artistDetails &&
                      nftDetails.artistDetails.username}
                  </p>
                </div>
              </div>
              <div className="price">
                {nftDetails ? (
                  <div className="price_inEth">
                    {parseFloat(nftDetails.nftOrderPrice.toFixed(3))} ETH
                  </div>
                ) : (
                  ""
                )}
                {nftDetails ? (
                  <div className="price_inDollar">
                    ${(nftDetails.nftOrderPrice * ethPrice).toFixed(2)}
                  </div>
                ) : (
                  ""
                )}

                <CustomButton
                  size="lg"
                  onClick={
                    nftDetails?.status === "mint"
                      ? handleMintNft
                      : handlePurchaseNft
                  }
                  disabled={
                    nftDetails &&
                    nftDetails.status &&
                    nftDetails.status === "not" &&
                    true
                  }
                >
                  {nftDetails &&
                  nftDetails.status &&
                  nftDetails.status === "not"
                    ? "Sold Out"
                    : "Purchase"}
                </CustomButton>

                {loginData && loginData.role === "admin" && (
                  <>
                    <input
                      type="text"
                      onChange={(e) => {
                        setReinitializedPrice(e.target.value);
                      }}
                      placeholder="price in eth"
                      value={reinitializedPrice}
                      style={{ marginBottom: "0.5rem" }}
                    />
                    <CustomButton size="lg" onClick={handleReinitializeNft}>
                      Reinitialize
                    </CustomButton>
                  </>
                )}

                {nftDetails &&
                  nftDetails.status === "buy" &&
                  account === (nftDetails && nftDetails.ownerAddr) && (
                    <>
                      <input
                        type="text"
                        onChange={(e) => {
                          setUpdatePrice(e.target.value);
                        }}
                        placeholder="price in eth"
                        value={updatePrice}
                        style={{ marginBottom: "0.5rem" }}
                      />
                      <CustomButton size="lg" onClick={handleUpdateNft}>
                        Update
                      </CustomButton>
                    </>
                  )}

                {nftDetails &&
                  nftDetails.status === "buy" &&
                  account === (nftDetails && nftDetails.ownerAddr) && (
                    <CustomButton size="lg" onClick={handleCancelNft}>
                      Cancel
                    </CustomButton>
                  )}
              </div>
              <div className="infolinks">
                <div>
                  <div className="background-image">
                    <GoldEmillion style={{ width: "50px", height: "50px" }} />
                  </div>
                  <Link to="#" onClick={open}>
                    Proof of Authenticity
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="legacy-details-tabs">
            <div className="legacy-tabs">
              {orderDetails && orderDetails.length ? (
                <div
                  className={`legacy-tab ${tab === 1 ? "active" : ""}`}
                  onClick={() => setTab(1)}
                >
                  Transaction History
                </div>
              ) : null}
              <div
                className={`legacy-tab ${tab === 2 ? "active" : ""}`}
                onClick={() => setTab(2)}
              >
                About {nftDetails && nftDetails.artistDetails?.username}
              </div>
              <div
                className={`legacy-tab ${tab === 3 ? "active" : ""}`}
                onClick={() => {
                  setTab(3);
                  fetchCategoryNftDetails();
                }}
              >
                Collection {/* {nftDetails ? nftDetails.collections : ""} */}
              </div>
            </div>
            <div className="legacy-tab-content">
              {tab === 1 && (
                <div className="legacy-table">
                  <table>
                    <thead>
                      <tr>
                        {orderDetails && orderDetails.length ? (
                          <Fragment>
                            <th>Seller</th>
                            <th>Edition #</th>
                            <th>Transaction Date</th>
                            <th>Transaction Type</th>
                            <th>Transaction Amount</th>
                          </Fragment>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails && orderDetails.length
                        ? orderDetails.map((item, index) => {
                            if (!item.active) {
                              return (
                                <tr key={index}>
                                  <td>{trimAdd(item?.seller)}</td>
                                  <td>...</td>
                                  <td>{formatDate(item.updatedAt)}</td>
                                  <td>...</td>
                                  <td>{item.price} ETH</td>
                                </tr>
                              );
                            }
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              )}
              {tab === 2 && (
                <div className="legacy-about">
                  <div
                    className="userImage"
                    // style={{height: 'unset'}}
                  >
                    {/* {nftDetails?.fileType === "image" ? (
                      <img src={nftDetails?.ipfsLink} alt="" />
                    ) : null}
                    {nftDetails?.fileType === "video" ? (
                      <video src={nftDetails?.ipfsLink} autoPlay loop></video>
                    ) : null} */}
                    <img src={nftDetails?.artistDetails?.profileImage} alt="" />
                  </div>
                  <div className="summary">
                    <div className="summary-text">
                      {/* {nftDetails && nftDetails.description} */}

                      <p className="para">
                        {!showReadMore && nftDetails?.artistDetails?.biography?.slice(0, 300)}
                        {showReadMore && nftDetails?.artistDetails?.biography?.length > 300
                          ? nftDetails?.artistDetails?.biography
                          : nftDetails?.artistDetails?.biography?.length > 300
                          ? "..."
                          : ""}
                      </p>
                    </div>
                    {!showReadMore && nftDetails?.artistDetails?.biography?.length > 300 && (
                      <div
                        className="read-more"
                        onClick={() => toggleReadMore(!showReadMore)}
                      >
                        {" "}
                        <span>
                          Read More <BsChevronDoubleDown />
                        </span>{" "}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {tab === 3 && (
                <div className="legacy-nfts">
                  <div className="row-title">
										{console.log("categoryNFTDetails: ", categoryNftDetails)}
                    {categoryNftDetails && categoryNftDetails.length > 0
                      ? "Available NFT"
                      : "No NFT for sale in this collection"}
                  </div>
                  <div className="collection">
                    {categoryNftDetails &&
                      categoryNftDetails
                        .map((item, index) => (
                          <CollectionCard key={index} item={item} />
                        ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isZoom && (
          <Modal style={{ width: "60%", padding: 0 }}>
            <div className="mod-image">
              {nftDetails?.fileType === "image" ? (
                <img src={nftDetails?.ipfsLink} alt="" />
              ) : null}
              {nftDetails?.fileType === "video" ? (
                <video src={nftDetails?.ipfsLink} autoPlay loop></video>
              ) : null}
            </div>
            <div className="mod-closeIcon">
              <CloseIcon onClick={() => setIsZoom(false)} />
            </div>
          </Modal>
        )}
      </div>
      {txLoading && <LoadingSpinner />}
      {isOpen && (
        <Modal style={{ width: "37%", padding: "1rem", background: "#073763" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="proofOfAuthenticitydiv"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={Gold} alt="Emillions Logo" />
            </div>
            <div
              className="proofTextContainer"
              style={{
                textAlign: "center",
                color: "white",
                padding: "0 0.5rem",
              }}
            >
              <h1 style={{ margin: "1rem 0" }}>Proof of Authenticity</h1>
              <h2 style={{ margin: "1rem 0" }}>Unique Origin Addresses</h2>
              <p style={{ margin: "1rem 0" }}>
                Digital proof of origin requires: <br /> The unique wallet
                address of the place this NFT was minted <br /> The unique
                wallet address of the artist who created the works and received
                payment <br /> The date on which the NFT was minted and sold for
                the very first time.
              </p>
              <p style={{ margin: "1rem 0" }}>
                This is what makes your NFT Authentic.
              </p>
              <p style={{ margin: "1rem 0" }}>
                The presence of the following addresses is your proof of
                authenticity.
              </p>
              <p style={{ margin: "2rem 0" }}>
                Address of EmillionsNFT:
                0x336159bc237945cD598744c5eB39C135F4700462 <br />
                Address of{" "}
                {nftDetails &&
                  nftDetails.artistDetails &&
                  nftDetails.artistDetails.username}
                : {nftDetails && nftDetails.ownerAddr}
              </p>
            </div>
          </div>
          <div className="mod-closeIcon">
            <CloseIcon onClick={close} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default LegacyDetails;