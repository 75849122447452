import React, { useRef, useState, useEffect } from "react";
import { Navigation, Pagination } from "swiper/js/swiper.esm";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.min.css";
import { useWeb3React } from "@web3-react/core";
import { getNftAddress } from "../../utils/addressHelpers";
import { useNftContractWrite } from "../../hooks/useContract";
import WalletCard from "./walletCard";
import { BiWalletAlt } from "react-icons/bi";
import LoadingSpinner from "../../components/loading-spinner";

let params = {
  modules: [Pagination, Navigation],
  slidesPerView: 1,
  spaceBetween: 30,
  loop: false,
  pagination: { el: ".swiper-pagination", clickable: true },
  breakpoints: {
    1470: { slidesPerView: 3 },
    760: { slidesPerView: 2 },
    460: { slidesPerView: 1 },
  },
};

const MyWallet = ({ match }) => {
  const swiperRef = useRef(null);
  const { account } = useWeb3React();
  const nftAddress = getNftAddress();
  const nftContractw = useNftContractWrite(nftAddress);
  const [assets, setAssets] = useState();
  const [totalNfts, setTotalNfts] = useState("");
  const [txLoading, setTxLoading] = useState(false);
  const [isWalletEmpty, setIsWalletEmpty] = useState();

  const getAssetIds = async () => {
    let assetList = [];
    const nftCount = await nftContractw.methods.balanceOf(account).call();

    if (nftCount === 0) {
      setIsWalletEmpty(true);
    } else {
      setIsWalletEmpty(false);
    }

    for (let i = 0; i < nftCount; i++) {
      const assetId = await nftContractw.methods
        .tokenOfOwnerByIndex(account, i)
        .call();
      assetList.push(assetId);
    }

    setAssets(assetList);
  };

  useEffect(() => {
    const fetchData = async () => {
      setTxLoading(true);
      if (account) {
        await getAssetIds();
      }
      setTxLoading(false);
    }
    fetchData()
  }, [account, totalNfts])

  return (
    <div className="dashboard_mywallet-tab">
      {isWalletEmpty ? (
        <div className="collection-preview-msg">
          Your wallet is Empty <BiWalletAlt />
        </div>
      ) : null}
      <section>
        {assets && assets.length ? (
          <>
            <div className="cards">
              <Swiper ref={swiperRef} {...params}>
                {assets.map((tokenId, index) => (
                  <div key={index}>
                    <WalletCard
                      tokenId={tokenId}
                      setTotalNfts={setTotalNfts}
                      setTxLoading={setTxLoading}
                      txLoading={txLoading}
                    />
                  </div>
                ))}
              </Swiper>
            </div>
          </>
        ) : null}
      </section>
      {txLoading && <LoadingSpinner />}
    </div>
  );
};

export default MyWallet;
