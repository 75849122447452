import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css";
import MintNftCard from "../../components/minnft-card";

const MintNft = () => {
  const [nftData, setnftData] = useState(null);

  useEffect(() => {
    const fetchedMintData = () => {
      axios
        // .get('https://emillionsnft.com/api/mintdata')
        .get("https://emillionsnft.com/api/art")
        .then((res) => {
          setnftData(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchedMintData();
  }, []);

  return (
    <div className="container">
      <div className="nft-item">
        {nftData
          ?.slice(0)
          .reverse()
          .map((item, key) => (
            <MintNftCard item={item} key={key} />
          ))}
      </div>
    </div>
  );
};

export default MintNft;
