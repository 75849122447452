import React, { useState } from "react";
import "../style/header.css";
import { useWeb3React } from "@web3-react/core";
import { Link, useHistory } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { AiOutlineLock } from "react-icons/ai";
import { ReactComponent as GoldEmillion } from "../images/artist/gold-new_E5.svg";
import logoBG from "../images/logo-bg.svg";
import { HiOutlineLogout } from "react-icons/hi";
import WalletPopup from "../../walletComponents/Popup/Popup";
import WalletDetailsPopup from "../../walletComponents/WalletDetailsPopup/WalletDetailsPopup";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../store";
import { FaChevronDown } from "react-icons/fa";
import MenuIcon from "../images/burgr_menu.png";
import CloseIcon from "../images/close.png";

const Header = () => {

  const loginData = useSelector(state => state.data)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const history = useHistory();
  const { account } = useWeb3React();

  const toggleDropdownHandler = (e) => {
    e.stopPropagation();
    setToggleDropdown(!toggleDropdown);
  };

  const [walletOpen, setWalletOpen] = useState(false);
  const closeModal = () => setWalletOpen(false);
  const [changePopupOpen, setChangePopupOpen] = useState(false);
  const closeModalChangePopup = () => setChangePopupOpen(false);

  const handleLogout = () => {
    history.push("/login");
    dispatch(loginAction.removeData())
  };

  function trimAdd(add = "0x00", l = 5) {
    return (
      String(add).slice(0, 2) +
      String(add).slice(2, 2 + l) +
      "..." +
      String(add).slice(add.length - l, add.length)
    );
  }

  return (
    <div className="header">
      {walletOpen && (
        <WalletPopup
          walletOpen={walletOpen}
          setWalletOpen={setWalletOpen}
          closeModal={closeModal}
        />
      )}
      {changePopupOpen && (
        <WalletDetailsPopup
          setWalletOpen={setWalletOpen}
          changePopupOpen={changePopupOpen}
          setChangePopupOpen={setChangePopupOpen}
          closeModal={closeModalChangePopup}
        />
      )}
      <div className="header-brand">
        <Link to="/">
          <GoldEmillion className="logo" />
        </Link>
        <div
          className="shape"
          style={{ backgroundImage: `url(${logoBG})` }}
        ></div>
      </div>
      <div className="header-navigation">
        <div className="header-login">
          {account ?
            <button
              className="btn-header"
              onClick={() => setWalletOpen(true)}
            >
              {trimAdd(account)}
            </button> :
            <button className="btn-header" onClick={() => setWalletOpen(true)}>
              Connect
            </button>
          }
          {!(loginData?._id) &&
            <CustomButton size="md">
              <Link to="/sign-up">Sign Up</Link>
            </CustomButton>
          }
          {(loginData?._id) ? (
            <span>
              <HiOutlineLogout
                style={{ cursor: "pointer" }}
                onClick={handleLogout}
              />
            </span>
          ) : (
            <span>
              <AiOutlineLock />
              <Link to="/login">Login</Link>
            </span>
          )}
        </div>
        <div className={`header-options ${loginData?._id ? "expand" : ""}`} >
          {loginData && loginData.role === "admin" && <NavLink to="/admin">Admin</NavLink>}
          <NavLink to="/featured">Featured</NavLink>
          <li className="option" >
            Art
            <ul className="dropdown">
              <Link to="/art/legacy">Legacy</Link>
              <Link to="/art/story">Story</Link>
              <Link to="/art/imagination">Imagination</Link>
            </ul>
          </li>
          <NavLink to="/artist">Our Artists</NavLink>
          <NavLink to="/about">About</NavLink>
          {loginData?._id && <NavLink to="/dashboard">Dashboard</NavLink>}
        </div >
      </div >
      <div className="mobile-navigation" onClick={() => setIsOpen(!isOpen)}>
        <div className="mobile-icons">
          <img
            src={MenuIcon}
            alt="menu-image"
            className={isOpen ? "hide-icon" : "mobile-menu-icon"}
          />
        </div>
        {isOpen && (
          <>
            <div
              className="mobile-options"
            // style={{ animationName: menuToggle }}
            // style={{
            //   transform: translateMenu,
            //   transition: "transform 2s ease-in-out 2s",
            // }}
            >
              <div className="close-icon-wrapper">
                <img
                  src={CloseIcon}
                  alt="menu-image"
                  className={!isOpen ? "hide-icon" : "mobile-close-icon"}
                />
              </div>
              <div className="mobile-login">
                {loginData?._id &&
                  (account ? (
                    <button
                      className="btn-header"
                      onClick={() => setWalletOpen(true)}
                    >
                      {trimAdd(account)}
                    </button>
                  ) : (
                    <button
                      className="btn-header"
                      onClick={() => setWalletOpen(true)}
                    >
                      Connect
                    </button>
                  ))}
                {!loginData?._id && (
                  <CustomButton size="md">
                    <Link to="/sign-up">Sign Up</Link>
                  </CustomButton>
                )}
                {loginData?._id ? (
                  <span>
                    <HiOutlineLogout
                      style={{ cursor: "pointer" }}
                      onClick={handleLogout}
                    />
                  </span>
                ) : (
                  <span>
                    <AiOutlineLock />
                    <Link to="/login">Login</Link>
                  </span>
                )}
              </div>
              <div className="mobile-links">
                {loginData && loginData.role === "admin" && (
                  <NavLink to="/admin">Admin</NavLink>
                )}
                <NavLink to="/featured">Featured</NavLink>
                <li className="mobile-option">
                  <span className={toggleDropdown ? "text-icon" : ""}>
                    <p>Art</p>{" "}
                    <FaChevronDown
                      onClick={toggleDropdownHandler}
                      className={
                        toggleDropdown ? "dropdown-icon" : "rotate-icon"
                      }
                    />
                  </span>
                  {toggleDropdown && (
                    <ul className="mobile-dropdown">
                      <Link to="/art/legacy">Legacy</Link>
                      <Link to="/art/story">Story</Link>
                      <Link to="/art/imagination">Imagination</Link>
                    </ul>
                  )}
                </li>
                <NavLink to="/artist">Our Artists</NavLink>
                <NavLink to="/about">About</NavLink>
                {loginData?._id && <NavLink to="/dashboard">Dashboard</NavLink>}
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  );
};

const NavLink = ({ children, ...rest }) => (
  <Link className="option" {...rest}>
    {children}
  </Link>
);

export default Header;
