import React from 'react';
import Tab from '../../components/tab';

import {
    CreateOrder,
    CancelOrder,
    SafePlaceBid,
    AcceptBid,
    ApproveNft,
} from '../../components/myContract';

import {
    useMarketplaceWriteContract,
    useNftContractWrite,
} from '../../hooks/useContract'
import FormInput from '../../components/FormInput1';
import { useWeb3React } from '@web3-react/core';
import { getNftAddress } from '../../utils/addressHelpers';

const MarketPlace = () => {

    const { account } = useWeb3React()


    const mpContractw = useMarketplaceWriteContract();
    const nftAddress = getNftAddress()
    const nftContractw = useNftContractWrite(nftAddress);

    const [_createOrder, set_createOrder] = React.useState({
        nftAddress: "0x93dece825c9C42A47F6bE18CAB5EBaE695e25113",
        assetId: "",
        priceinEth: "",
        expireAt: ""
    })

    const [_cancelOrder, set_cancelOrder] = React.useState({
        nftAddress: "0x93dece825c9C42A47F6bE18CAB5EBaE695e25113",
        assetId: ""
    })

    const [_acceptBid, set_acceptBid] = React.useState({
        nftAddress: "0x93dece825c9C42A47F6bE18CAB5EBaE695e25113",
        assetId: "",
        priceinEth: "",
    })

    const [_spBid, set_spBid] = React.useState({
        nftAddress: "0x93dece825c9C42A47F6bE18CAB5EBaE695e25113",
        assetId: "",
        priceinEth: "",
        expireAt: ""
    })

    const [_bidDetails, set_bidDetails] = React.useState({
        nftAddress: "0x93dece825c9C42A47F6bE18CAB5EBaE695e25113",
        assetId: "",
        details: null
    })

    const [_orderDetails, set_orderDetails] = React.useState({
        nftAddress: "0x93dece825c9C42A47F6bE18CAB5EBaE695e25113",
        assetId: "",
        details: null
    })

    const [_nftApproval, set_nftApproval] = React.useState({
        approveTo: "",
        tokenId: ""
    })

    const [_infinixApproval, set_infinixApproval] = React.useState({
        spenderAddress: "",
        amount: ""
    })

    const [_cancelBid, set_cancelBid] = React.useState({
        nftAddress: "",
        assetId: ""
    })

    const getTimestamp = () => {
        const timestamp = Date.now()
        return parseInt(timestamp / 1000);
    }

    const getqualifyTimestamp = (value) => {
        value = parseInt(value);
        const currentTimestamp = getTimestamp();
        if (value > currentTimestamp + 86400) {
            return true
        } else {
            alert('Minimum expire date is 1 day')
        }
    }

    const onInputChange = (e) => {
        const { name, value } = e.target;
        set_createOrder({ ..._createOrder, [name]: value })
    }


    const CreateOrderSubmit = (e) => {
        e.preventDefault();
        const { nftAddress, assetId, priceinEth, expireAt } = _createOrder
        CreateOrder(mpContractw, nftAddress, assetId, priceinEth, expireAt, account)
    }

    const CancelOrderSubmit = (e) => {
        e.preventDefault();
        const { nftAddress, assetId } = _cancelOrder;
        CancelOrder(mpContractw, nftAddress, assetId, account)
    }

    const SafePlaceBidSubmit = (e) => {
        e.preventDefault();
        const { nftAddress, assetId, priceinEth, expireAt } = _spBid
        SafePlaceBid(mpContractw, nftAddress, assetId, priceinEth, expireAt, account)
    }

    const AcceptBidSubmit = (e) => {
        e.preventDefault();
        const { nftAddress, assetId, priceinEth } = _acceptBid;
        AcceptBid(mpContractw, nftAddress, assetId, priceinEth, account)
    }

    const CancelBidSubmit = (e) => {
        e.preventDefault();
        const { nftAddress, assetId } = _cancelBid;
        ApproveNft(mpContractw, nftAddress, assetId, account)
    }

    const ApproveFromNFT = (e) => {
        e.preventDefault();
        const { approveTo, tokenId } = _nftApproval;
        ApproveNft(nftContractw, approveTo, tokenId, account)

    }
    const BidByOrderidSumbit = async (e) => {
        e.preventDefault();
        const { nftAddress, assetId } = _bidDetails;
        const fetchBidDetails = await mpContractw.methods.bidByOrderId(nftAddress, assetId).call();
        set_bidDetails({ details: fetchBidDetails })
    }

    const OrderByAssetIdSumbit = async (e) => {
        e.preventDefault();
        const { nftAddress, assetId } = _orderDetails;
        const fetchOrderDetails = await mpContractw.methods.orderByAssetId(nftAddress, assetId).call()
        set_orderDetails({ ..._orderDetails, details: fetchOrderDetails })
    }

    return (
        <div className="container" >
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}} >
                <a href="/collection">Collection</a>
                <a href="/myorders">MyOrders</a>
                <a href="/wallet">WalletNfts</a>
                <a href="/admin">Admin</a>
                <a href="/login">Login</a>
                <a href="/register">Register</a>
            </div>
            <Tab title="createOrder" >
                <form onSubmit={CreateOrderSubmit} >
                    <FormInput
                        name="nftAddress"
                        value={_createOrder.nftAddress}
                        label="nftAddress (address)"
                        handleChange={onInputChange} />

                    <FormInput
                        name="assetId"
                        value={_createOrder.assetId}
                        label="assetId (uint256)"
                        handleChange={onInputChange} />

                    <FormInput
                        name="priceinEth"
                        value={_createOrder.priceinEth}
                        label="priceinEth (uint256)"
                        handleChange={onInputChange} />
                    {/* TODO : dynamic date */}
                    <FormInput
                        name="expireAt"
                        value={_createOrder.expireAt}
                        label="expireAt (uint256)"
                        handleChange={onInputChange} />

                    <button type="submit" className="get-btn">Get</button>
                    {/* <div className="output" >
                    </div> */}
                </form>
            </Tab>
            <Tab title="cancelOrder" >
                <form onSubmit={CancelOrderSubmit} >
                    <FormInput
                        name="nftAddress"
                        value={_cancelOrder.nftAddress}
                        label="nftAddress (address)"

                        handleChange={(e) =>
                            set_cancelOrder({ ..._cancelOrder, [e.target.name]: e.target.value })}
                    />

                    <FormInput
                        name="assetId"
                        value={_cancelOrder.assetId}
                        label="assetId (uint256)"

                        handleChange={(e) =>
                            set_cancelOrder({ ..._cancelOrder, [e.target.name]: e.target.value })}
                    />
                    <button type="submit" className="get-btn">Get</button>
                </form>
            </Tab>
            <Tab title="approveFromNFT" >
                <form onSubmit={ApproveFromNFT} >
                    <FormInput
                        name="approveTo"
                        value={_nftApproval.approveTo}
                        label="to (address)"
                        handleChange={(e) =>
                            set_nftApproval({ ..._nftApproval, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="tokenId"
                        value={_nftApproval.tokenId}
                        label="tokenId (uint256)"
                        handleChange={(e) =>
                            set_nftApproval({ ..._nftApproval, [e.target.name]: e.target.value })} />
                    <button type="submit" className="get-btn">Get</button>
                </form>
            </Tab>
            <Tab title="approveFromInfinix" >
                {/* <form onSubmit={ApproveFromInfinix} >
                    <FormInput
                        name="spenderAddress"
                        value={_infinixApproval.spenderAddress}
                        label="spender (address)"
                        handleChange={(e) =>
                            set_infinixApproval({ ..._infinixApproval, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="amount"
                        value={_infinixApproval.amount}
                        label="amount (uint256)"
                        handleChange={(e) =>
                            set_infinixApproval({ ..._infinixApproval, [e.target.name]: e.target.value })} />
                    <button type="submit" className="get-btn">Get</button>
                </form> */}
            </Tab>
            <Tab title="safePlaceBid" >
                <form onSubmit={SafePlaceBidSubmit} >
                    <FormInput
                        name="nftAddress"
                        value={_spBid.nftAddress}
                        label="nftAddress (address)"
                        handleChange={(e) =>
                            set_spBid({ ..._spBid, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="assetId"
                        value={_spBid.assetId}
                        label="assetId (uint256)"
                        handleChange={(e) =>
                            set_spBid({ ..._spBid, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="priceinEth"
                        value={_spBid.priceinEth}
                        label="priceinEth (uint256)"
                        handleChange={(e) =>
                            set_spBid({ ..._spBid, [e.target.name]: e.target.value })} />
                    <FormInput
                        name="expireAt"
                        value={_spBid.expireAt}
                        label="expireAt (uint256)"
                        handleChange={(e) => {
                            const { name, value } = e.target;
                            set_spBid({ ..._spBid, [name]: value })
                            getqualifyTimestamp(name, value)
                        }} />
                    <button type="submit" className="get-btn">Get</button>
                </form>
            </Tab>
            <Tab title="acceptBid">
                <form onSubmit={AcceptBidSubmit} >
                    <FormInput
                        name="nftAddress"
                        value={_acceptBid.nftAddress}
                        label="nftAddress (address)"
                        handleChange={(e) =>
                            set_acceptBid({ ..._acceptBid, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="assetId"
                        value={_acceptBid.assetId}
                        label="assetId (uint256)"
                        handleChange={(e) =>
                            set_acceptBid({ ..._acceptBid, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="priceinEth"
                        value={_acceptBid.priceinEth}
                        label="priceinEth (uint256)"
                        handleChange={(e) =>
                            set_acceptBid({ ..._acceptBid, [e.target.name]: e.target.value })} />

                    <button type="submit" className="get-btn">Get</button>
                </form>
            </Tab>
            <Tab title="cancelBid">
                <form onSubmit={CancelBidSubmit} >
                    <FormInput
                        name="nftAddress"
                        value={_cancelBid.nftAddress}
                        label="nftAddress (address)"
                        handleChange={(e) =>
                            set_cancelBid({ ..._cancelBid, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="assetId"
                        value={_cancelBid.assetId}
                        label="assetId (uint256)"
                        handleChange={(e) =>
                            set_cancelBid({ ..._cancelBid, [e.target.name]: e.target.value })} />

                    <button type="submit" className="get-btn">Get</button>
                </form>
            </Tab>
            <Tab title="bidByOrderId">
                <form onSubmit={BidByOrderidSumbit} >
                    <FormInput
                        name="nftAddress"
                        value={_bidDetails.nftAddress}
                        label="nftAddress (address)"
                        handleChange={(e) =>
                            set_bidDetails({ ..._bidDetails, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="assetId"
                        value={_bidDetails.assetId}
                        label="assetId (uint256)"
                        handleChange={(e) =>
                            set_bidDetails({ ..._bidDetails, [e.target.name]: e.target.value })} />

                    <button type="submit" className="get-btn">Get</button>
                </form>
            </Tab>
            <Tab title="orderByAssetId">
                <form onSubmit={OrderByAssetIdSumbit} >
                    <FormInput
                        name="nftAddress"
                        value={_orderDetails.nftAddress}
                        label="nftAddress (address)"
                        handleChange={(e) =>
                            set_orderDetails({ ..._orderDetails, [e.target.name]: e.target.value })} />

                    <FormInput
                        name="assetId"
                        value={_orderDetails.assetId}
                        label="assetId (uint256)"
                        handleChange={(e) =>
                            set_orderDetails({ ..._orderDetails, [e.target.name]: e.target.value })} />

                    <button type="submit" className="get-btn">Get</button>
                </form>
            </Tab>
        </div>
    )
}

export default MarketPlace;
