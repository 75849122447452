import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/style/nftCard.css";
import { BidByOrderid, CancelOrder, TokenUri } from "./myContract";
import {
  useMarketplaceWriteContract,
  useNftContractWrite,
} from "../hooks/useContract";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import Tab from "./tab";
import LoadingSpinner from "./loading-spinner";

const OrderedNftCard = ({ nftItem }) => {
  const { assetId, nftAddress, expiresAt } = nftItem;
  const web3 = new Web3(window.ethereum);
  const { account } = useWeb3React();
  const nftContractw = useNftContractWrite(nftAddress);
  const [tokenUri, setTokenUri] = useState(null);
  const [nftMetadata, setNftMetadata] = useState([]);
  const [nftImageUrl, setNftImageUrl] = useState(null);
  const [expired, setExpired] = useState(false);
  const mpContractw = useMarketplaceWriteContract();
  const [highestBidValue, setHighestBidValue] = useState("");
  const [fetchedBids, setFetchBids] = useState([]);
  const [txLoading, setTxLoading] = useState();

  const handle_CancelOrder = async () => {
    await CancelOrder(mpContractw, nftAddress, assetId, account, setTxLoading);
  };

  const getPriceInEth = (price) => {
    return web3.utils.fromWei(price, "ether");
  };

  const getPlacedBids = () => {
    axios
      .get(
        `https://emillionsnft.com/api/activeBid?nftAddress=${nftAddress}&assetId=${assetId}&sort=+`
      )
      .then((response) => {
        setFetchBids(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function trimAdd(add = "0x00", l = 5) {
    return (
      String(add).slice(0, 2) +
      String(add).slice(2, 2 + l) +
      "..." +
      String(add).slice(add.length - l, add.length)
    );
  }

  function getDateFromTimestamp(timestamp) {
    let date = new Date(parseInt(timestamp * 1000));
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  useEffect(() => {
    let orderTimestamp = parseInt(expiresAt);
    let currentDateTimestamp = Date.now();
    if (currentDateTimestamp > orderTimestamp * 1000) {
      setExpired(true);
    }

    const getHighestBidPrice = async () => {
      await BidByOrderid(mpContractw, nftAddress, assetId)
        .then((bidDetails) => {
          const maxBidPriceInEth = web3.utils.fromWei(
            bidDetails.price,
            "ether"
          );
          setHighestBidValue(maxBidPriceInEth);
        })
        .catch((error) => {
          console.log("bidbyorderId error", error);
        });
    };

    const getTokenUri = async (e) => {
      await TokenUri(nftContractw, assetId)
        .then((res) => {
          setTokenUri(res);
        })
        .catch((error) => {
          console.log("tokenuri error", error);
        });
    };

    if (tokenUri) {
      const fetchNftData = async () => {
        axios
          .get(`https://ipfs.io/ipfs/${tokenUri.slice(7)}`)
          .then((response) => {
            setNftMetadata(response.data);
            setNftImageUrl(response.data.image);
          });
      };
      fetchNftData();
    }

    getPlacedBids();
    getHighestBidPrice();
    getTokenUri();
  }, [tokenUri]);

  return (
    <>
      {tokenUri && !expired && (
        <>
          <div className="nft-card">
            <div className="nft-card-img">
              {nftImageUrl && (
                <img
                  src={`https://ipfs.io/ipfs/${nftImageUrl.slice(7)}`}
                  alt=""
                />
              )}
            </div>
            <div className="nft-card-details">
              <p> AssetId: {assetId}</p>
              <p> Highest Bid: {highestBidValue} ETH</p>
            </div>
            <div className="nft-buttons">
              <button className={`btn`} onClick={handle_CancelOrder}>
                Cancel Order
              </button>
            </div>
          </div>
          <div className="nft-card-info">
            <div className="nft-bid-table">
              <Tab title="Offers" show={true}>
                {fetchedBids.length ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Price</th>
                        <th>Expiration</th>
                        <th>Bidder</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetchedBids
                        .slice(0)
                        .reverse()
                        .map((item, key) => (
                          <tr key={key}>
                            <td>{getPriceInEth(item.price)} ETH</td>
                            <td>{getDateFromTimestamp(item.expiresAt)}</td>
                            <td>{trimAdd(item.bidder)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <span className="nft-bid-status">No bid placed yet</span>
                )}
              </Tab>
            </div>
          </div>
        </>
      )}
      {txLoading ? <LoadingSpinner /> : null}
    </>
  );
};
export default OrderedNftCard;
