import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import Card from '../../components/cards';
import '../../assets/style/collection-preview.css'
import CollectionItem from '../../components/collection-item';

const Upload = () => {
    const [nfts, setNfts] = useState([])
   
    useEffect(() => {
        const fetchUserNfts = async () => {
            if (window.ethereum) {
                await window.ethereum.enable();
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const address = await signer.getAddress();

                axios
                    .get(
                        `https://rinkeby-api.opensea.io/api/v1/assets?owner=${address}&order_direction=asc&offset=0&limit=20`
                    )
                    .then((response) => {
                        setNfts(response.data.assets);
                    });
            }
        };
        fetchUserNfts();
    }, []);

    return (
        <div className='container' >
            <div className="collection-preview">
                {nfts.map((item) => (
                    <CollectionItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    )
}

export default Upload;
