import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner";
toast.configure();

const VerifyUser = ({ match }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const AuthenticateUser = () => {
      setLoading(true);
      axios
        .get(`https://emillionsnft.com/api/email-auth/${id}`)
        .then((res) => {
          setLoading(false);
          toast.success("succesfully verified!");
          history.push("/login");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.error || "verification failed!");
        });
    };
    AuthenticateUser();
  }, []);

  return (
    <>
      <div className="">
        {loading && "Verifying..."}
        {loading && <LoadingSpinner />}
      </div>
    </>
  );
};

export default VerifyUser;
