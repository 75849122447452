/* eslint-disable default-case */
import { useMemo } from 'react'
import useWeb3 from './useWeb3'
import {
  getMarketplaceContract,
  getNftContract,
  getNftContractWrite,
  getMarketplaceWrite,
} from '../utils/contractHelpers'
/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useNftContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getNftContract(address), [address, web3])
}
export const useNftContractWrite = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getNftContractWrite(address), [address, web3])
}
export const useMarketplaceContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getMarketplaceContract(address), [address, web3])
}
export const useMarketplaceWriteContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getMarketplaceWrite(address), [address, web3])
}
