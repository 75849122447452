import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { BrowserRouter as Router } from "react-router-dom";
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from "react-redux"
import './index.scss'
import store from './store';
import ScrollToTop from './components/scrollToTop';

export const getLibrary = (provider) => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

ReactDOM.render(
  <Provider store={store} >
    <Web3ReactProvider getLibrary={getLibrary} >
      <Router>
        {/* <ScrollToTop> */}
          <App />
        {/* </ScrollToTop> */}
      </Router>
    </Web3ReactProvider>
  </Provider>,
  document.getElementById('root')
);
