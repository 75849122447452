import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import Modal from "../../components/Modal";
import Pattern from "../../assets/images/artist-hero.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/closeIcon.svg";
import Gold from "../../assets/images/gold-single.svg";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { BsChevronDoubleDown } from "react-icons/bs";
import ScrollToTop from "../../components/scrollToTop";
import { wrap } from "module";

const imageUrl = "https://source.unsplash.com/1600x900/?random";

const Card = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const history = useHistory();
  const availableNftHandler = () => {
    history.push(`/artist/${props.artistId}`);
    history.push(`/art/${props?.category}/${props?.address || "0x336159bc237945cD598744c5eB39C135F4700462"}/${props?.id}`);
  };
  // variable to check if the device is a mobile of max width 500px
  const [isMobile, setIsMobile] = useState(false);

  
  const getDimensions = () => {
    const vid = document.getElementById("nftContent");
    let vidHeight = props.fileType === "video" ? vid.videoHeight : vid.clientHeight;
		let vidWidth = props.fileType === "video" ? vid.videoWidth : vid.clientWidth;
    
		if(vidWidth/vidHeight === 1)
		{
			if(vidHeight > 650 || vidWidth > 650)
			{
				vidWidth = 650;
				vidHeight = 650;
			}
		}
		else if(vidWidth/vidHeight < 1) {
			if(vidHeight > 650)
			{
				vidWidth = (650 * vidWidth)/ vidHeight;
				vidHeight = 650;
			}
		}
		else if(vidWidth/vidHeight > 1) {
			if(vidWidth > 650)
			{
				vidHeight = (650 * vidHeight)/ vidWidth;
				vidWidth = 650;
			}
		}

		console.log(vidHeight);
		console.log(vidWidth);
		setHeight(vidHeight);
    setWidth(vidWidth);
  }

  const handleImgVideoOnClick = (e) => {
    if (props.fileType === "image") {
      open();
    }
    if (props.fileType === "video") {
      e.target.play();
    }
  };
  useEffect(() => {
    if (window.matchMedia('(max-device-width: 500px)').matches) {
      setIsMobile(true);
      console.log("yes its a mobile");
    }
    
  }, []);
  // useEffect(() => {
  //   if (height > 0) {
  //     console.log("state height:", height);
  //   }
  // }, [height]);

  // useEffect(() => {
  //   if (width > 0) {
  //     console.log("state width:", width);
  //   }
  // }, [width]);

  return (
      <div className="nft-card" style={{ position: 'relative', marginBottom: 60}}>
        <div className="image" onClick={open}>
          {props.fileType === "image" && (<img src={props && props.imgOrVideo} alt="nftImage" />)}
          {props.fileType === "video" && (<video src={props && props.imgOrVideo} alt="nftVideo" />)}
          {/* {props.fileType === "video" ? <p style={{color: "red", fontWeight: "bold"}}>*Click to start animation</p> : null} */}
        </div>
        <div className="info">
          <div className="title" style={{ marginTop: "2rem" }}>{props && props.title}</div>
          <div className="sub-title">
            <p className="para">
              {!show && props.desc.slice(0, 70)}
              {show && props.desc?.length > 70
                ? props.desc
                : props.desc?.length > 70
                  ? " ..."
                  : ""}
            </p>
            {/* {!show && props.desc?.length > 70 && (
              <p className="read-more" onClick={() => setShow(!show)}>
                Read More{" "}
                <span>
                  <BsChevronDoubleDown />
                </span>
              </p>
            )} */}
          </div>
        </div>
        {isOpen && !isMobile &&(
          <Modal>
            <div className="mod-grid">
              <div className="image" onClick={handleImgVideoOnClick} style={{
                height: height >0 ? height : 780, width: width>0 ? width : 780,
                objectFit: 'fill'
              }}>
                {props.fileType === "image" && (<img src={props && props.imgOrVideo} onLoad={getDimensions} id="nftContent" alt="nftImage"
                style={{
                  height: height>0 ? height : 480, width: width>0 ? width : 480
                }}/>)}
                {props.fileType === "video" && (<video src={props && props.imgOrVideo} onPlay={getDimensions} id="nftContent"
                  style={{
                    height: height>0 ? height : 480, width: width>0 ? width : 480
                  }} alt="nftVideo" autoPlay muted loop />)}
                {/* {props.fileType === "video" ? <p style={{color: "red", fontWeight: "bold"}}>*Click to start animation</p> : null} */}
              </div>
              <div className="content">
                <div className="name">{props && props.title}</div>
                <div className="body" style={{
                  maxHeight: height > 0 ? height * 3 / 4 : 350,
                  maxWidth: width > 0 ? width * 1.5 / 3 : 350
                }}>{props && props.desc}</div>
                <CustomButton size="md" onClick={availableNftHandler}>
                  Details
                </CustomButton>
              </div>
            </div>
            <div className="mod-closeIcon">
              <CloseIcon onClick={close} />
            </div>
          </Modal>
        )}
        {/* This code handles the mobile view with max-width: 500px*/}
        {isOpen && isMobile && (
          <Modal>
            <div className="mod-closeIcon">
              <CloseIcon onClick={close} />
            </div>
            <div className="mod-grid">
              <div className="image" onClick={handleImgVideoOnClick}>
                {props.fileType === "image" && (<img src={props && props.imgOrVideo} alt="nftImage"/>)}
                {props.fileType === "video" && (<video src={props && props.imgOrVideo} alt="nftVideo" autoPlay loop />)}
                {/* {props.fileType === "video" ? <p style={{color: "red", fontWeight: "bold"}}>*Click to start animation</p> : null} */}
              </div>
              <br />
              <div className="content">
                <div className="name">{props && props.title}</div>
                <div className="body">{props && props.desc}</div>
                <CustomButton size="md" onClick={availableNftHandler}>
                  Details
                </CustomButton>
              </div>
              
            </div>

          </Modal>
        )}
        <CustomButton size="md" onClick={availableNftHandler}
            style={{ position: 'absolute', left: 0, bottom: -30,}}
          >
            Details
          </CustomButton>
      </div>
  );
};

const Artist = ({ location }) => {
  const arr = [1, 2, 3, 4, 5, 6];
  const [tab, setTab] = useState(1);
  const [artistData, setArtistData] = useState();
  const params = useParams();
  const { id } = params;

  // history.push(`${location.pathname}`);

  // useEffect(() => {
  //   mainRef.current.focus();
  // }, [mainRef]);

  const onMatchedRoutes = (matchedRoutes) => {
    const _matchedRoutes = matchedRoutes.map((matchedRoute) => {
      if (matchedRoute.match && matchedRoute.match.path === "/artist/:id") {
        const artistId =
          matchedRoute.match.params && matchedRoute.match.params.id;
        return {
          match: matchedRoute.match,
          route: {
            ...matchedRoute.route,
            path: matchedRoute.match.url,
            breadcrumbName: artistData && artistData.username,
          },
        };
      } else {
        return matchedRoute;
      }
    });

    return [
      {
        route: {
          path: `/`,
          breadcrumbName: "Home",
        },
      },
      ..._matchedRoutes,
    ];
  };

  const fetchArtistData = async () => {
    const response = await axios.get(`https://emillionsnft.com/api/users/${id}`);
    const data = response.data;
    console.log('artist data', data)
    setArtistData(data);
  };

  useEffect(() => {
    fetchArtistData();
  }, []);

  return (
    <ScrollToTop>
    <div className="artist">
      <div className="artist-hero">
        <img src={Pattern} alt="" />
        <div className="title">Artists</div>
      </div>
      <div className="container">
        <div className="artist-background">
          <img src={Gold} alt="" />
        </div>
        <div className="artist-content">
          <div className="bio">
            <div
              className="img-wrapper"
              style={{
                backgroundImage: `url(${artistData && artistData.profileImage
                  })`,
              }}
            ></div>
            <div className="info">
              <div className="mapping">
                <Breadcrumb
                  locationPath={location?.pathname}
                  onMatchedRoutes={onMatchedRoutes}
                />
              </div>
              <div className="name">
                {artistData && artistData.username}
              </div>
              <div className="tab">
                <div className="tab-header">
                  <button
                    onClick={() => setTab(1)}
                    className={`${tab === 1 && "active"}`}
                  >
                    Description
                  </button>
                </div>
                <div className="tab-content">
                  {tab === 1 && artistData && artistData.biography}
                </div>
              </div>
            </div>
          </div>
        </div>
        {artistData && artistData.arts && artistData.arts.length > 0 && (
          <div className="artist-nfts">
            <div className="row-title">Available NFTs</div>
            <div className="collection">
              {artistData &&
                artistData.arts &&
                artistData.arts.map((item, key) => {
                  if (item.initialized) {
                    return (
                      <Card
                        key={key}
                        title={item.title}
                        desc={item.description}
                        imgOrVideo={item.ipfsLink}
                        category={item.category}
                        address={item.address}
                        id={item.autoId}
                        fileType={item.fileType}
                        artistId={id}
                      />
                    );
                  }
                })}
            </div>
          </div>
        )}
        {/* <div className="artist-nfts">
          <div className="row-title">Available NFT Collections</div>
          <div className="collection">
            {arr.map((item, key) => (
              <Card key={key} />
            ))}
          </div>
        </div> */}
      </div>
    </div>
  </ScrollToTop>
  );
};

export default Artist;
