import React, { useEffect } from "react";
import "../assets/style/App.css";
import Layout from "../assets/layout";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected } from "../connectors";
import { setupNetwork } from "../utils/wallet";
import { Switch, Route } from 'react-router-dom';


import MarketPlace from "../pages/marketplace";
import MarketPlaceItem from "../pages/assets";
import NftMarketPlace from "../pages/collection";
import CreateAsset from "../pages/create-asset";
import WalletNfts from "../pages/walletNfts";
import Upload from "../pages/upload";
import MyOrders from "../pages/myorders";
import MintNft from "../pages/mintnft";
import Login from "../pages/login";
import Register from "../pages/auth/register";
import Home from "../pages/home";
import Learn from "../pages/learn"

import Artist from "../pages/artists/artist-component.js";
import VerifyUser from "../pages/auth/verify-user";
import SignUp from "../pages/sign-up";
import ArtistMain from "../pages/artists";
import AdminProtected from "../routes/protected-routes/admin";
import Admin from "../pages/admin";
import ResetPassword from "../pages/reset-password";
import LegacyDetails from "../pages/legacy/legacyDetails";
import Legacy from "../pages/legacy";
import ArtistBio from "../pages/artist-bio";
import Featured from "../pages/featured";
import DashBoard from "../pages/dashboard";
import About from "../pages/about";
import ArtistApprove from "../pages/admin/approveArtist";

const App = () => {
  const { activate } = useWeb3React();

  const { ethereum } = window;

  useEffect(() => {
    defaultConnect();
  }, []);

  if (ethereum) {
    ethereum.on("accountsChanged", function (accounts) {
      window.location.reload();
      if (ethereum.removeListener) {
        ethereum.removeListener("chainChanged");
        ethereum.removeListener("accountsChanged");
      }
    });
  }

  useEffect(() => {
    console.warn = () => null;
    if (window && ethereum) {
      ethereum.on("chainChanged", async () => {
        try {
          await activate(injected, async (error) => {
            if (error instanceof UnsupportedChainIdError) {
              const hasSetup = await setupNetwork();
              if (hasSetup) {
                activate(injected);
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      });
    }
  }, []);

  const defaultConnect = async () => {
    try {
      await activate(injected, async (error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          if (hasSetup) {
            activate(injected);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout  >
      <Switch>

        <Route exact path="/learn" component={Learn} />

        <Route exact path="/wallet" component={WalletNfts} />
        <Route exact path="/mintadmin" component={MintNft} />
        <Route exact path="/collection" component={NftMarketPlace} />
        <Route exact path="/collection/:nftAddress/:assetId" component={MarketPlaceItem} />
        <Route exact path="/myorders" component={MyOrders} />

        <Route exact path="/" component={Home} />
        <Route exact path="/marketPlace" component={MarketPlace} />
        <Route exact path="/assets/create" component={CreateAsset} />
        <Route exact path="/upload" component={Upload} />
        {/* <Protected exact path="/dashboard" component={DashBoard} /> */}
        <Route exact path="/dashboard" component={DashBoard} />

        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/register" component={Register} />
        <Route exact path="/sign-up" component={SignUp} />

        <AdminProtected exact path="/admin" component={Admin} />
        <AdminProtected exact path="/admin/artist-approve" component={ArtistApprove} />

        <Route exact path="/artist" component={ArtistMain} />
        <Route exact path="/artist/:id" component={Artist} />
        <Route exact path="/artist-bio" component={ArtistBio} />

        <Route exact path="/featured" component={Featured} />

        <Route exact path="/art/legacy" component={Legacy} />
        <Route exact path="/art/story" component={Legacy} />
        <Route exact path="/art/imagination" component={Legacy} />


        <Route exact path="/art/legacy/:nftAddress/:assetId" component={LegacyDetails} />
        <Route exact path="/art/story/:nftAddress/:assetId" component={LegacyDetails} />
        <Route exact path="/art/imagination/:nftAddress/:assetId" component={LegacyDetails} />


        <Route exact path="/about" component={About} />

        <Route exact path="/email-auth/:id" component={VerifyUser} />
        <Route exact path="/passwordreset/:id" component={ResetPassword} />
      </Switch>
    </Layout>
  );
};

export default App;




