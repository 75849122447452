import React from 'react';
import '../assets/style/formInput.css';


const FormInput = ({ handleChange, label, ...otherProps }) => (
    <div className="group">
        {label ?
            (<label className="form-input-label" >
                {label}
            </label>) : null
        }
        <input className="form-input" onChange={handleChange}  {...otherProps} required />
    </div>

)


export default FormInput;