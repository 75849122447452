import addresses from "../config/contracts";
// final-changes
export const getAddress = (address) => {
  return address["1"];
};

export const getNftAddress = () => {
  return getAddress(addresses.nft);
};

export const getMarketplaceAddress = () => {
  return getAddress(addresses.marketplace);
};
