import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.css";
import FormInput from "../../components/FormInput1";
import useAuth from "../../hooks/useAuth";
import LoadingSpinner from "../../components/loading-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const Register = () => {
  const history = useHistory();
  const { login } = useAuth();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [credential, setCredential] = useState({
    username: "",
    email: "",
    password: "",
    role: "artist",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredential({ ...credential, [name]: value });
  };

  const redirectByRole = (userRole) => {
    if (userRole === "buyer") {
      login().then(() => {
        history.push(state?.path || "/collection");
      });
    } else if (userRole === "artist") {
      login().then(() => {
        history.push(state?.path || "/assets/create");
      });
    } else if (userRole === "admin") {
      login().then(() => {
        history.push(state?.path || "/admin");
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(!loading);
    axios
      .post("https://emillionsnft.com/api/register", credential)
      .then((res) => {
        localStorage.setItem("role", credential.role);
        setLoading(false);
        toast.success("Registerd Successfully!");
        // redirectByRole(credential.role)
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.error || "Signup Failed!");
      });
  };

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    // if (userRole) {
    // redirectByRole(userRole)
    // }
  }, []);

  return (
    <div className="container">
      <div className="register">
        <h1>Register</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <FormInput
              type="text"
              name="username"
              label="Username"
              value={credential.username}
              handleChange={handleChange}
            />
          </div>
          <div>
            <FormInput
              type="email"
              name="email"
              label="Email"
              value={credential.email}
              handleChange={handleChange}
            />
          </div>
          <div>
            <FormInput
              type="password"
              name="password"
              label="Password"
              value={credential.password}
              handleChange={handleChange}
            />
          </div>
          <div className="date-picker">
            <label className="form-input-label">I'm here as</label>
            <select
              defaultValue="artist"
              onChange={(e) =>
                setCredential({ ...credential, role: e.target.value })
              }
            >
              <option value="artist">Artist</option>
              <option value="buyer">Buyer</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button type="submit">Register</button>
        </form>
        <a href="/login">Login?</a>
      </div>
      {loading && <LoadingSpinner />}
    </div>
  );
};

export default Register;
