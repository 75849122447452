import React, { useState } from "react";
import Pattern from "../../assets/images/signup-hero-bg2.svg";
import Gold from "../../assets/images/gold-single.svg";
import LoadingSpinner from "../../components/loading-spinner";
import CustomButton from "../../components/CustomButton";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/index";
toast.configure();

const ResetPassword = ({ match }) => {
  const dispatch = useDispatch();
  const { id } = match.params;
  // const history = useHistory();
  const [isWarning, setIsWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState("");
  const [credential, setCredential] = useState({
    password: "",
    confirmPassword: "",
  });

  // const parseJwt = (token) => {
  //   try {
  //     return JSON.parse(atob(token.split(".")[1]));
  //   } catch (e) {
  //     return null;
  //   }
  // };

  // const redirectByRole = (userRole) => {
  //   if (userRole === "buyer") {
  //     history.push("/");
  //   } else if (userRole === "artist") {
  //     history.push("/artist");
  //   } else if (userRole === "admin") {
  //     history.push("/admin");
  //   }
  // };

  const handleResetPassword = (event) => {
    event.preventDefault();
    const { password, confirmPassword } = credential;
    setLoading(true);

    if (validate()) {
      axios
        .put(`https://emillionsnft.com/api/passwordreset/${id}`, {
          password: password,
          cpassword: confirmPassword,
        })
        .then((res) => {
          setLoading(false);
          localStorage.removeItem("token");
          dispatch(loginAction.removeData());
          // setToken(res.data.token);
          setCredential({
            password: "",
            confirmPassword: "",
          });
          toast.success(res.data.data);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.error || "Reset password failed!");
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredential({ ...credential, [name]: value });
  };

  const validate = () => {
    let isValid = true;
    const { password, confirmPassword } = credential;

    if (password != confirmPassword) {
      isValid = false;
      setIsWarning(true);
    }
    return isValid;
  };

  // useEffect(() => {
  //     let role = parseJwt(localStorage.getItem('token'))?.role;
  //     redirectByRole(role)
  // }, [token])

  return (
    <>
      <div className="reset-password">
        <div className="rp-hero-img">
          <img src={Pattern} alt="" />
        </div>
        <div className="rp-container">
          <div className="rp-background">
            <img src={Gold} alt="" />
          </div>
          <div className="rp-header">
            <div className="title">Reset Password</div>
          </div>

          <div className="rp-body">
            <div className="form">
              <form onSubmit={handleResetPassword}>
                <div className="form-group">
                  <label className={`form-input-label`}>Password</label>
                  <input
                    name="password"
                    type="password"
                    value={credential.password}
                    className={`form-input ${isWarning ? "warning" : ""}`}
                    placeholder="New password"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className={`form-input-label`}>Confirm Password</label>
                  <input
                    name="confirmPassword"
                    type="password"
                    value={credential.confirmPassword}
                    className={`form-input ${isWarning ? "warning" : ""}`}
                    placeholder="Confirm Password"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {isWarning && (
                  <div className="invalid-cred">Password do not match</div>
                )}
                <CustomButton type="submit" size="md">
                  Submit
                </CustomButton>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingSpinner />}
    </>
  );
};

export default ResetPassword;
