import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as Metamask } from "../../assets/images/metamask.svg";
import Pattern from "../../assets/images/signup-hero-bg2.svg";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/loading-spinner";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected } from "../../connectors";
import Gold from "../../assets/images/gold-single.svg";
import { setupNetwork } from "../../utils/wallet";
import Breadcrumb from "../../components/Breadcrumb";
import { toast } from "react-toastify";
import { BsQuestionCircleFill } from "react-icons/bs";
import { ReactComponent as FileUpload } from "../../assets/images/uploadFile.svg";
import "react-toastify/dist/ReactToastify.css";
import FormData from "form-data";
require('dotenv').config();
toast.configure();

const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // Infura IPFS API

const SignUp = ({ location }) => {
  const history = useHistory();
  const { activate, account } = useWeb3React();
  const [step, setStep] = useState(1);
  const [isWarning, setIsWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setotp] = useState();
  const [portfolioDocs, setPortfolioDocs] = useState(null);
  const [profileImage, setProfileImage] = useState();
  const [profileUploadStaus, setProfileUploadStatus] = useState();

  const [credential, setCredential] = useState({
    username: "",
    email: "",
    password: "",
    role: "buyer",
    biography: "",
    portfolio: "",
    portfolioLink: "",
    message: "",
    profileImage: "",
    walletAddress: account ? account : "",
  });

  const [bioUploadStatus, setBioUploadStatus] = useState();
  const [pfUploadStaus, setPfUploadStatus] = useState();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredential({ ...credential, [name]: value });
  };

  const getOTPCode = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("https://emillionsnft.com/api/register", {
        email: credential.email,
        walletAddress: account,
      })
      .then((res) => {
        setLoading(false);
        setStep(2);
        toast.success("OTP sent!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.error || "Something Went Wrong");
      });
  };

  const handleOTPverification = (event) => {
    event.preventDefault();
    setLoading(true);

    const { username, email, password, role } = credential;

    if (role === "artist" || role === "both") {
      if (!profileImage) return null;
      axios
        .patch("https://emillionsnft.com/api/register", {
          ...credential,
          otp: otp,
          walletAddress: account,
        })
        .then((res) => {
          setLoading(false);
          // setStep(3)
          // if (account) setStep(4);
          toast.success("Successfully verified!!");
          history.push("/login");
        })
        .catch((error) => {
          setLoading(false);
          setIsWarning(true);
          toast.error(error.response.data.error || "Something Went Wrong");
        });
    } else {
      axios
        .patch("https://emillionsnft.com/api/register", {
          username,
          email,
          password,
          role,
          otp,
          walletAddress: account,
        })
        .then((res) => {
          setLoading(false);
          // setStep(3)
          // if (account) setStep(4);
          toast.success("Successfully verified!!");
          history.push("/login");
        })
        .catch((error) => {
          setLoading(false);
          setIsWarning(true);
          console.log(error.response);
          toast.error(error.response.data.error || "Something Went Wrong");
        });
    }
  };

  const captureBioDocs = (e) => {
    setBioUploadStatus("");
    setCredential({ ...credential, biography: e.target.value });
  };
  
  const capturePortflioDocs = async(e) => {
    setPfUploadStatus("");
    let selected = e.target.files[0];
    const types = [
      "application/pdf",
      "application/msword",
      "application/vmd.ms-excel",
    ];
    if (!selected) return null;

    if (selected && types.includes(selected.type)) {
      setLoading(true);
      setPortfolioDocs(selected);
      setPfUploadStatus(() => (
        <span style={{ color: "#d79605" }}>File uploading...</span>
      ));
      const formData = new FormData();
      formData.append("file", selected);
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      try {
        const response = await axios.post(url, formData, {
          maxContentLength: "Infinity",
          headers: {
            "Content-Type": `multipart/form-data;boundary=${formData._boundary}`,
            pinata_api_key: '729a2bfac173c1f89a2f',
            pinata_secret_api_key: 'b921af42a341602efa779953e8f1c0009480b1c85be4bd58fa9bf50fa208c4e0',
          },
        });
        const ipfsId = response.data.IpfsHash
        setPfUploadStatus(`${selected.name} uploaded successfully`);
        setCredential({
          ...credential,
          portfolio: `https://emillionsnft.mypinata.cloud/ipfs/${ipfsId}`,
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
        return;
      }
      setLoading(false);
      // setError('');
    } else {
      setPfUploadStatus(() => (
        <span style={{ color: "#e20100" }}>Invalid file type</span>
      ));
      // setError('Please select an image file (jpg, png or pdf)')
    }
  };

  const captureProfileImage = async (e) => {
    setProfileImage("");
    setProfileUploadStatus("");
    let selected = e.target.files[0];
    const types = ["image/jpeg", "image/png"];

    if (!selected) return null;

    if (selected && types.includes(selected.type)) {
      setLoading(true);
      setProfileImage(selected);
      setProfileImage();
      setProfileUploadStatus(() => (
        <span style={{ color: "#d79605" }}>File uploading...</span>
      ));
      const formData = new FormData();
      formData.append("file", selected);
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      try {
        const response = await axios.post(url, formData, {
          maxContentLength: "Infinity",
          headers: {
            "Content-Type": `multipart/form-data;boundary=${formData._boundary}`,
            pinata_api_key: '729a2bfac173c1f89a2f',
            pinata_secret_api_key: 'b921af42a341602efa779953e8f1c0009480b1c85be4bd58fa9bf50fa208c4e0',
          },
        });
        const ipfsId = response.data.IpfsHash
        setCredential({
          ...credential,
          profileImage: `https://emillionsnft.mypinata.cloud/ipfs/${ipfsId}`,
        });
        setProfileImage(`https://emillionsnft.mypinata.cloud/ipfs/${ipfsId}`);
        setProfileUploadStatus(`${selected.name} uploaded successfully`);
      } catch (err) {
        console.error(err);
        setLoading(false);
        return;
      }
      setLoading(false);
      // setError('');
    } else {
      setProfileUploadStatus(() => (
        <span style={{ color: "#fd0202" }}>
          Please select an image file (jpg, png or pdf
        </span>
      ));
    }
  };

  const onMatchedRoutes = (matchedRoutes) => {
    return [
      {
        route: {
          path: `/`,
          breadcrumbName: "Home",
        },
      },
      ...matchedRoutes,
    ];
  };

  const connectWithMetamask = async (event) => {
    event.preventDefault();
    try {
      await activate(injected, async (error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          if (hasSetup) {
            activate(injected);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
  }, [credential?.role]);

  return (
    <div className="sign-up">
      <div className="sign-up-hero-img">
        <img src={Pattern} alt="" />
      </div>
      <div className="sign-up-container">
        <div className="sign-up-background">
          <img src={Gold} alt="" />
        </div>
        <div className="sign-up-header">
          <div className="mapping">
            <Breadcrumb
              locationPath={location.pathname}
              onMatchedRoutes={onMatchedRoutes}
            />
          </div>
          <div className="title">Sign Up</div>
          <div className="message">
            In signing up with Emillions NFT, you are joining a family and
            high-value network of Value-Focused Art lovers, buyers, holders and
            creators. Signing up brings access to exclusive drops, high impact
            pop-ups and events, insight from us, and the ability to network
            within our family as it grows across the world.
          </div>
        </div>

        {step === 1 && (
          <>
            <div className="sign-up-body">
              <div className="title">
                Would you like to sign up to be a buyer or seller?
              </div>
              <div
                className="options"
                onChange={(e) =>
                  setCredential({ ...credential, role: e.target.value })
                }
              >
                <label htmlFor="r1" className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="buyer"
                    id="r1"
                    className="radio--input"
                    checked={credential.role === "buyer"}
                  />
                  <div className="radio--radio"></div>
                  Buyer
                </label>
                <label htmlFor="r2" className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="artist"
                    id="r2"
                    className="radio--input"
                    checked={credential.role === "artist"}
                  />
                  <div className="radio--radio"></div>
                  Seller
                </label>
                <label htmlFor="r3" className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="both"
                    id="r3"
                    className="radio--input"
                    checked={credential.role === "both"}
                  />
                  <div className="radio--radio"></div>
                  Both Buyer & Seller
                </label>
              </div>
              <div className="form">
                <form onSubmit={getOTPCode}>
                  <div className="form-group">
                    <label className="form-input-label">
                      {credential.role === "artist" ||
                      credential.role === "both"
                        ? "Full Name"
                        : "Username"}
                    </label>
                    <input
                      name="username"
                      type="text"
                      value={credential.username}
                      className="form-input"
                      placeholder={
                        credential.role === "artist" ||
                        credential.role === "both"
                          ? "Your Name"
                          : "Your username"
                      }
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className={`form-input-label`}>Email Address</label>
                    <input
                      name="email"
                      type="email"
                      value={credential.email}
                      className="form-input"
                      placeholder="janedoe@gmail.com"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className={`form-input-label`}>Password</label>
                    <input
                      name="password"
                      type="password"
                      value={credential.password}
                      className="form-input"
                      placeholder="Your password"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className={`form-input-label`}>Wallet Address</label>
                    <input
                      name="walletAddress"
                      type="text"
                      value={account ? account : "Connect your wallet"}
                      disabled={!!account}
                      className="form-input"
                      placeholder="Your wallet Address"
                      required
                    />
                  </div>

                  {(credential.role === "artist" ||
                    credential.role === "both") && (
                    <div className="sign-up-artistform">
                      <div className="form-group">
                        <label className="form-input-label" htmlFor="biography">
                          <ToolTip data="Please upload a document that speaks to your experience, style, and aspirations as an artist. Accepted file formats are shown below." />
                          Biography
                        </label>
                        <textarea
                          name="biography"
                          id="biography"
                          cols="30"
                          rows="10"
                          onChange={captureBioDocs}
                          minLength="100"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="form-input-label">
                          <ToolTip data="Upload five files that you deem your to be the most representative examples of your work. Accepted file formats are shown below." />
                          Show and Describe what You've Done
                        </label>
                        <div className="form-input-type">
                          <div className="form-input-asset">
                            <input
                              name="portUpload"
                              id="portUpload"
                              type="file"
                              onChange={capturePortflioDocs}
                              required
                            />
                            <label
                              htmlFor="portUpload"
                              className="select-asset-image"
                            >
                              <div className="content">
                                <FileUpload />
                                <p>
                                  Drag and drop or <span>browse</span> your
                                  files
                                </p>
                              </div>
                            </label>
                          </div>
                          {pfUploadStaus
                            ? pfUploadStaus
                            : "Accepted filetypes: PDF, docs, and rtf"}
                        </div>
                      </div>
                      <div className="form-group bold">
                        <label className="form-input-label">
                          <ToolTip data="Provide a link to your current portfolio or personal website." />
                          Portfolio Link
                        </label>
                        <input
                          className="form-input"
                          name="portfolioLink"
                          value={credential.portfolioLink}
                          onChange={handleInputChange}
                          type="url"
                          pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                          required
                        />
                      </div>
                      <div className="form-group bold">
                        <label className="form-input-label">
                          <ToolTip data="Add a personal message to the EmillionsNFT team to discuss your goals or intentions in working with us." />
                          Message to the EmillionsNFT Team
                        </label>
                        <textarea
                          name="message"
                          value={credential.message}
                          onChange={handleInputChange}
                          required
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="form-input-label">
                          <ToolTip data="Provide a image of yours" />
                          Profile Image
                        </label>
                        <div className="select-asset ">
                          <input
                            name="imageUpload"
                            id="imageUpload"
                            type="file"
                            onChange={captureProfileImage}
                            required
                          />
                          <label
                            htmlFor="imageUpload"
                            className="select-asset-image"
                          >
                            {profileImage ? (
                              <div
                                className="select-asset-image"
                                style={{
                                  backgroundImage: `url(${profileImage})`,
                                }}
                              ></div>
                            ) : (
                              <i className="fas fa-image"></i>
                            )}
                          </label>
                        </div>
                        <div className="select-asset-error">
                          {profileUploadStaus}
                        </div>
                      </div>
                    </div>
                  )}
                  <CustomButton type="submit" size="lg">
                    Send Code
                  </CustomButton>
                </form>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="sign-up-body">
              <div className="title">
                Would you like to sign up to be a buyer or seller?
              </div>
              <div
                className="options"
                defaultValue={credential.role}
                onChange={(e) =>
                  setCredential({ ...credential, role: e.target.value })
                }
              >
                <label htmlFor="r1" className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="buyer"
                    id="r1"
                    className="radio--input"
                    checked={credential.role === "buyer"}
                  />
                  <div className="radio--radio"></div>
                  Buyer
                </label>
                <label htmlFor="r2" className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="artist"
                    id="r2"
                    className="radio--input"
                    checked={credential.role === "artist"}
                  />
                  <div className="radio--radio"></div>
                  Seller
                </label>
                <label htmlFor="r3" className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="both"
                    id="r3"
                    className="radio--input"
                    checked={credential.role === "both"}
                  />
                  <div className="radio--radio"></div>
                  Both Buyer & Seller
                </label>
              </div>
              <div className="form">
                <form onSubmit={handleOTPverification}>
                  <div className="vcode-on-email">
                    Code sent to {credential.email}
                  </div>
                  <div className="form-wrapper">
                    <div className="form-group">
                      <label className="form-input-label">
                        Verification Code
                      </label>
                      <input
                        name="otp"
                        value={otp}
                        onChange={(e) => setotp(e.target.value)}
                        className={`form-input ${isWarning ? "warning" : ""}`}
                      />
                    </div>
                    {isWarning && (
                      <div className="warning">Incorrect code entered</div>
                    )}
                  </div>
                  <CustomButton type="submit" size="lg">
                    Verify
                  </CustomButton>
                </form>
              </div>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <div className="sign-up-body">
              <div className="on-success">
                <div className="success-message">Success.</div>
                <div className="connect-metamask">
                  <button onClick={connectWithMetamask} disabled={account}>
                    <Metamask className="metamask-svg" />
                    Connect to MetaMask
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 4 && (
          <>
            <div className="sign-up-body">
              <div className="on-success">
                <div className="success-message">
                  MetaMask connected successfully.
                </div>
              </div>
              <div className="form">
                <form>
                  <div className="form-group">
                    <label className="form-input-label">Assign an Alias</label>
                    <input className="form-input" />
                  </div>
                  <div className="btn-group">
                    <CustomButton type="submit" size="md">
                      Assign
                    </CustomButton>
                    <Link to="">Skip</Link>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
      {loading && <LoadingSpinner />}
    </div>
  );
};

export default SignUp;

const ToolTip = ({ data }) => {
  return (
    <div className="signup__tooltip">
      <BsQuestionCircleFill />
      <div className="tooltip__text">{data ? data : "No tool tip"}</div>
    </div>
  );
};
