const ArtworkCard = ({ data }) => {

    return (
        <>
            {data ?
                <div className="artwork-card">
                    <div className="background-image" style={{ backgroundImage: `url(${data.ipfsLink})` }} ></div>
                    <div className="card-info">
                        <div className="card-title">{data.title}</div>
                        <div className="card-sub-title">{data.collections}</div>
                        <div className="card-para"> {data.description.length > 80 ? `${data.description.slice(0, 80)}...` : data.description}  </div>
                        <div className="card-price">
                            <button className="btn" >Listed</button>
                            <p>{parseFloat(Number(data.price).toFixed(5))} ETH</p>
                        </div>
                        <div className="txn-details">
                            <button className="btn" >
                                Txn Details
                            </button>
                        </div>
                    </div>
                </div>
                : null}
        </>

    )
}

export default ArtworkCard;