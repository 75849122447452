import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../../components/loading-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../../components/CustomButton";
import { InitializeNft } from "../../utils/contractFunctions";
import Web3 from "web3";
import { useNftContractWrite } from "../../hooks/useContract";
import { useWeb3React } from "@web3-react/core";
import { getNftAddress } from "../../utils/addressHelpers";
import { useHistory } from "react-router-dom";
toast.configure();

const Admin = () => {
  const history = useHistory();
  const [uartist, setUartist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [txLoading, setTxLoading] = useState(false);
  const [error, setError] = useState("");
  const [approve, setApprove] = useState(false);
  const [artworks, setArtworks] = useState(null);

  const nftAddress = getNftAddress();
  const nftContractw = useNftContractWrite(nftAddress);

  const { account } = useWeb3React();
  const web3 = new Web3(window.ethereum);

  const fetchUartist = () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: "https://emillionsnft.com/api/artist/unverified",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .request(options)
      .then((res) => {
        setUartist(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // const ApproveUserById = (userid) => {
  //   setLoading(true);
  //   const user = uartist.find(({ _id }) => _id === userid);
  //   const headers = {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //   };
  //   axios
  //     .patch(
  //       "https://emillionsnft.com/api/artist/unverified",
  //       { email: user.email },
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       setApprove(true);
  //       setLoading(false);
  //       toast.success(`${user?.username} Approved`);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //       setLoading(false);
  //     });
  // };

  const fetchArtworks = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    axios
      .get("https://emillionsnft.com/api/art", { headers: headers })
      .then((res) => {
        setArtworks(res.data);
      })
      .catch((err) => {
        setError("Error fetching artwork :(");
      });
  };

  const startInitialization = async (id) => {
    const artwork = artworks.find(({ _id }) => _id === id);
    const { autoId, artistAddress, price } = artwork;
    await InitializeNft(
      nftContractw,
      autoId,
      price,
      artistAddress,
      account,
      setTxLoading
    );
    history.go(0);
  };

  // const getPriceInEth = (weiPrice) => {
  //   const priceInEth = Number(
  //     parseFloat(web3.utils.fromWei(weiPrice, "ether")).toFixed(8)
  //   );
  //   return priceInEth;
  // };

  function trimAdd(add = "0x00", l = 5) {
    return (
      String(add).slice(0, 2) +
      String(add).slice(2, 2 + l) +
      "..." +
      String(add).slice(add.length - l, add.length)
    );
  }

  useEffect(() => {
    fetchUartist();
  }, [approve]);

  useEffect(() => {
    fetchArtworks();
  }, []);

  return (
    <div className="admin">
      <div className="admin_btn">
        <a href="/admin/artist-approve">
          <CustomButton size="md">Approve artist</CustomButton>
        </a>
      </div>
      <div className="container">
        {artworks && artworks.length ? (
          <section>
            {artworks
              .filter(({ initialized }) => initialized === false)
              .map(
                (
                  {
                    ipfsLink,
                    fileType,
                    autoId,
                    address,
                    initialized,
                    price,
                    _id,
                  },
                  index
                ) => (
                  <div className="admin_arwork-collection" key={index}>
                    <div className="admin_artwok-left">
                      {fileType === "image" ? (
                        <img src={ipfsLink} alt="" />
                      ) : null}
                      {fileType === "video" ? (
                        <video src={ipfsLink} autoPlay loop></video>
                      ) : null}
                    </div>
                    <div className="admin_artwok-right">
                      <div>
                        <span>Address : </span> {trimAdd(address)}
                      </div>
                      <div>
                        <span>NFT ID : </span> {autoId}
                      </div>
                      <div>
                        <span>Price:</span> {price}
                      </div>
                      <div>
                        <span>Initialize Status : </span>{" "}
                        {initialized.toString()}
                      </div>
                      <div>
                        <CustomButton
                          size="lg"
                          onClick={() => startInitialization(_id)}
                        >
                          {txLoading ? "Initializing..." : "Initialize"}
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                )
              )}
          </section>
        ) : null}
      </div>
      {txLoading || loading ? <LoadingSpinner /> : ""}
    </div>
  );
};

export default Admin;
