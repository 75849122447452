import React, { useEffect, useState } from "react";
import "swiper/css/swiper.min.css";
import CustomButton from "../../components/CustomButton";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { useHistory } from "react-router-dom";

const UniqueCollectionCard = ({
  name,
  setCollectionName,
  setCollectionDetails,
  collectionDetails,
}) => {
  const { account } = useWeb3React();
  const [previewImage, setPreviewImage] = useState();
  const fetchCollectionByName = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    if (account) {
      axios
        .get(
          `https://emillionsnft.com/api/minted?collections=${name}&ownerAddr=${account}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          setCollectionDetails(res.data?.reverse());
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchPreviewImage = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    if (account) {
      axios
        .get(
          `https://emillionsnft.com/api/minted?collections=${name}&ownerAddr=${account}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          setPreviewImage(res.data?.splice(-1)[0]?.ipfsLink);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchPreviewImage();
  }, []);

  return (
    <>
      <div className="collection-card">
        <div
          className="image"
          style={{
            backgroundImage: `url(${previewImage ? previewImage : null})`,
            backgroundColor: "#efefef",
          }}
        ></div>
        <div className="info">
          <div className="title">{name ? name : "Frank Sinatra"}</div>
          <CustomButton
            size="md"
            onClick={() => {
              setCollectionName(name);
              fetchCollectionByName();
            }}
          >
            Details
          </CustomButton>
        </div>
      </div>
    </>
  );
};

const Collections = ({ collections }) => {
  const [collectionName, setCollectionName] = useState("");
  const [collectionDetails, setCollectionDetails] = useState();
  const { account } = useWeb3React();
  const history = useHistory();

  // const [isOpen, setIsOpen] = useState(false);
  // const close = () => setIsOpen(false);
  // const open = () => setIsOpen(true);

  const CollectionCard = ({ data, name }) => {
    return (
      <>
        <div className="collection-card">
          <div
            className="image"
            style={{ backgroundImage: `url(${data?.ipfsLink})` }}
          ></div>
          <div
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            {name}
          </div>
          <CustomButton
            size="md"
            onClick={() => {
              history.push(`art/legacy/${data.nftAddr}/${data.nftId}`);
            }}
          >
            View NFT
          </CustomButton>
        </div>
      </>
    );
  };

  return (
    <div className="dashboard_collection-tab">
      {collections && collections.length ? (
        <div className="collection-nfts">
          <div className="title">Your Collections</div>
          <div className="collection">
            {collections.map((name, index) => (
              <UniqueCollectionCard
                key={index}
                name={name}
                setCollectionName={setCollectionName}
                setCollectionDetails={setCollectionDetails}
                collectionDetails={collectionDetails}
              />
            ))}
          </div>
        </div>
      ) : null}
      {collectionDetails && collectionDetails.length ? (
        <div className="collections-collection">
          <div className="title">
            NFTs from {collectionName ? collectionName : "Frank Sinatra"}{" "}
            Collection
          </div>
          <div className="collection_section">
            {collectionDetails.map((data, index) => (
              <CollectionCard data={data} key={index} name={data.title} />
            ))}
          </div>
        </div>
      ) : null}
      <div className="collection-form">
        <div className="title">Add a new collection</div>
        <div className="collection_form-group">
          <label>Collection Name</label>
          <input type="text" />
        </div>
        <div className="collection_form-group">
          <label>Collection Description</label>
          <textarea name="description"></textarea>
        </div>

        <CustomButton size="lg">Add Collection</CustomButton>
      </div>
    </div>
  );
};

export default Collections;
