import React, { useState, useEffect } from "react";
import "../../assets/style/nft-marketplace.css";
import axios from "axios";
import { ethers } from "ethers";
import NftCard from "../../components/nft-collection-card";
import { TokenUri } from "../../components/myContract";
import { useNftContractWrite } from "../../hooks/useContract";
import LoadingSpinner from "../../components/loading-spinner";

const NftMarketPlace = () => {
  const [nfts, setNfts] = useState([]);
  const [tokenUri, setTokenUri] = useState(null);

  useEffect(() => {
    const fetchOrderedNfts = async () => {
      axios.get(`https://emillionsnft.com/api/order`).then((response) => {
        setNfts(response.data);
      });
    };

    fetchOrderedNfts();
  }, []);

  return (
    <div className="container">
      <div className="nft-marketplace">
        {nfts.length ? (
          nfts.map((nft) => <NftCard key={nft.tokenId} nftItem={nft} />)
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default NftMarketPlace;
