import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LoadingSpinner from "../../components/loading-spinner";
toast.configure();

const ArtistApprove = () => {
  const [uartist, setUartist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);

  const fetchUartist = () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: "https://emillionsnft.com/api/artist/unverified",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .request(options)
      .then((res) => {
        setUartist(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const ApproveUserById = (userid) => {
    setLoading(true);
    const user = uartist.find(({ _id }) => _id === userid);
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    axios
      .patch(
        "https://emillionsnft.com/api/artist/unverified",
        { email: user.email },
        { headers: headers }
      )
      .then((res) => {
        setApprove(true);
        setLoading(false);
        toast.success(`${user?.username} Approved`);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  // const ApproveCard = () => {
  //   return (
  //     <div className="approve-card">
  //       <div className="approve-card-date">
  //         <h4>info@gmail.com</h4>
  //         <span>Jan 31, 2022</span>
  //       </div>
  //       <p className="message-card">
  //         Where the physical and the digital meet at the forefront of art value.
  //         Not just
  //       </p>
  //       <ul>
  //         <li>
  //           <a href="https://ipfs.infura.io/ipfs/QmchNjs6RU87aSukRu57y2JCB2uHKcJXonZWdWvk3tmRqG">
  //             <CustomButton size="md">Biography</CustomButton>{" "}
  //           </a>
  //         </li>
  //         <li>
  //           <a href="https://ipfs.infura.io/ipfs/QmchNjs6RU87aSukRu57y2JCB2uHKcJXonZWdWvk3tmRqG">
  //             <CustomButton size="md">Portfolio</CustomButton>{" "}
  //           </a>
  //         </li>
  //         <li>
  //           <a href="https://ipfs.infura.io/ipfs/QmchNjs6RU87aSukRu57y2JCB2uHKcJXonZWdWvk3tmRqG">
  //             <CustomButton size="md">Portfolio Link</CustomButton>{" "}
  //           </a>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // };

  useEffect(() => {
    fetchUartist();
  }, [approve]);

  return (
    <div className="admin">
      <div className="container">
        {uartist && uartist.length ? (
          <section style={{ padding: "100px 150px 50px" }}>
            <table>
              <thead>
                <tr>
                  <th>UserName</th>
                  <th>Email-id</th>
                  <th>Action</th>
                  <th>Portfolio</th>
                </tr>
              </thead>
              <tbody>
                {uartist?.map(({ email, username, _id, portfolio }) => (
                  <tr key={_id}>
                    <td>{username ? username : "..."}</td>
                    <td>{email ? email : "..."}</td>
                    <td>
                      <button onClick={() => ApproveUserById(_id)}>
                        Approve
                      </button>
                    </td>
                    <td>
                      <a href={portfolio}>
                        {" "}
                        <button>Download</button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : null}
      </div>
      {loading ? <LoadingSpinner /> : ""}
    </div>
  );
};

export default ArtistApprove;

{
  /* <div className="approve-collection">
                    {uartist && uartist.length ?
                        <>
                            {uartist.map((data, index) => (
                                <ApproveCard data={data} key={index} />
                            ))}
                        </>
                        : null
                    }
                </div> */
}
