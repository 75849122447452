import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/style/nft-marketplace.css";
import { AcceptBid, CancelBid } from "../../components/myContract";
import { useMarketplaceWriteContract } from "../../hooks/useContract";
import OrderedNftCard from "../../components/OrderedNftCard";
import { useWeb3React } from "@web3-react/core";
import { AiFillWarning } from "react-icons/ai";
import LoadingSpinner from "../../components/loading-spinner";

const MyOrders = () => {
  const [nfts, setNfts] = useState([]);
  const { account } = useWeb3React();

  const fetchOrderedNfts = () => {
    axios
      .get(`https://emillionsnft.com/api/order?seller=${account}`)
      .then((response) => {
        setNfts(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchOrderedNfts();
  }, [account]);

  return (
    <div>
      <div className="container">
        {nfts.length ? (
          nfts.map((nft) => (
            <div className="nft-marketplace-card" key={nft.tokenId}>
              <OrderedNftCard nftItem={nft} />
            </div>
          ))
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default MyOrders;
