import React, { useState, useRef} from 'react';
import Pattern from '../../assets/images/signup-hero-bg2.svg';
import Breadcrumb from '../../components/Breadcrumb';
import Gold from '../../assets/images/gold-single.svg'
import CustomButton from '../../components/CustomButton';
import { Link } from 'react-router-dom';
import { BsQuestionCircleFill } from 'react-icons/bs'
import { ReactComponent as FileUpload } from '../../assets/images/uploadFile.svg'

const DropfileInput = () => {
    const wrapperRef = useRef(null)
    const [fileList, setFileList] = useState([])

    const onDragEnter = () => {
    }
    const onDragLeave = () => {
    }
    const onDrop = () => { }
    const captureFile = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            const updatedList = [...fileList, newFile]
            setFileList(updatedList)
        }
    }
    return (
        <div
            ref={wrapperRef}
            className="form-input-asset"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            <input name='imageUpload' id="imageUpload" type="file" onChange={captureFile} required />
            <label htmlFor="imageUpload" className='select-asset-image'>
                <div className="content">
                    <FileUpload />
                    <p>Drag and drop or <span>browse</span> your files</p>
                </div>
            </label>
        </div>
    )
}

const ArtistBio = ({ location }) => {
    const onMatchedRoutes = (matchedRoutes) => {
        return [
            {
                route: {
                    path: `/`,
                    breadcrumbName: 'Home',
                },
            },
            ...matchedRoutes,
        ];
    };


    return (
        <div className='artist-bio' >
            <div className="sign-up-hero-img">
                <img src={Pattern} alt="" />
            </div>
            <div className="sign-up-container">
                <div className="sign-up-background">
                    <img src={Gold} alt="" />
                </div>
                <div className="sign-up-header">
                    <div className="mapping">
                        <Breadcrumb
                            locationPath={location?.pathname}
                            onMatchedRoutes={onMatchedRoutes}
                        />
                    </div>
                    <div className="title">Sign Up</div>
                    <div className="message">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.
                    </div>
                </div>
                <div className="sign-up-artistform">
                    <form>
                        <div className="form-group">
                            <label className="form-input-label" ><BsQuestionCircleFill />Biography</label>
                            <div className="form-input-bio">
                                <div className='browse'  ><CustomButton>Browse</CustomButton></div>
                                <div className='upload-status' >Biography.docx uploaded successfully</div>
                            </div>
                            <div className="form-input-type">
                                <DropfileInput />
                                Accepted filetypes: PDF, docs, and rtf
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-input-label" ><BsQuestionCircleFill />Portfolio</label>
                            <div className="form-input-type">
                                Accepted filetypes: PDF, docs, and rtf
                            </div>
                        </div>
                        <div className="form-group bold">
                            <label className="form-input-label" ><BsQuestionCircleFill />Portfolio Link</label>
                            <input className="form-input" type='url' placeholder='https://www.robinaustin.com' />
                        </div>
                        <div className="form-group bold">
                            <label className="form-input-label" ><BsQuestionCircleFill />Message to the EmillionsNFT Team</label>
                            <textarea name="description"></textarea>
                        </div>
                        <div className="form-group-submit">
                            <Link>Go Back</Link>
                            <CustomButton size="lg" >Submit</CustomButton>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ArtistBio;
