import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../../components/loading-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../store";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
toast.configure();
require('dotenv').config()

const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // Infura IPFS API

const Profile = () => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [profileImage, setProfileImage] = useState();
  const [error, setError] = useState("");

  const loginData = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const [profile, setProfile] = useState({
    firstName: loginData?.firstName,
    lastName: loginData?.lastName,
    aliasName: loginData?.aliasName,
    biography: loginData?.biography,
    profileImage: loginData?.profileImage,
  });

  const [loading, setLoading] = useState(false);
  const [sentStatus, setSentstatus] = useState(false);
  const [email, setEmail] = useState(loginData?.email);
  const [resetEmail, setResetEmail] = useState(loginData?.email);
  const [userDetails, setUserDetails] = useState({});
  const [otp, setOtp] = useState("");

  // const [resetCredential, setResetCredential] = useState({
  //   email: "",
  //   otp: "",
  // });

  const walletAddress = JSON.parse(localStorage.getItem("user")).walletAddress;

  const handleProfileSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await axios
      .patch("https://emillionsnft.com/api/artist", profile, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        toast.success("Successfull created!!");
        dispatch(loginAction.updateData(res.data));
      })
      .catch((err) => {
        toast.error(err.response.data.error || "Submission Failed!!");
        console.log(err.message);
      });
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const captureFile = async (e) => {
    setLoading(true);
    setProfileImage("");
    setFile("");
    setError("");
    let selected = e.target.files[0];
    const types = ["image/jpeg", "image/png"];
    if (e.target.files[0].type.indexOf("image") === -1) {
      alert("Invalid File Type");
      setLoading(false);
      return;
    }
    if (selected && types.includes(selected.type)) {
      setProfileImage(selected);
      setFile(selected);
      setProfileImage(() => (
        <span style={{ color: "#d79605" }}>File uploading...</span>
      ));
      const formData = new FormData();
      formData.append("file", selected);
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      try {
        const response = await axios.post(url, formData, {
          maxContentLength: "Infinity",
          headers: {
            "Content-Type": `multipart/form-data;boundary=${formData._boundary}`,
            pinata_api_key: '729a2bfac173c1f89a2f',
            pinata_secret_api_key: 'b921af42a341602efa779953e8f1c0009480b1c85be4bd58fa9bf50fa208c4e0',
          },
        });
        const ipfsId = response.data.IpfsHash
        setProfile({
          ...profile,
          profileImage: `https://emillionsnft.mypinata.cloud/ipfs/${ipfsId}`,
        });
        setProfileImage(`https://emillionsnft.mypinata.cloud/ipfs/${ipfsId}`);
      } catch (err) {
        console.error(err);
        setLoading(false);
        return;
      }
      setLoading(false)
    };
      // setError('');
    }

  const handleChangePassword = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("https://emillionsnft.com/api/forgotpassword", {
        email: email,
      })
      .then((res) => {
        toast.success(res.data.data);
        setEmail("");
        setLoading(false);
        window.location.href = "/login";
        dispatch(loginAction.removeData());
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.error || "Email sent failed!!");
      });
  };
  const handleResetEmail = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://emillionsnft.com/api/email/change`,
        { email: resetEmail },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success(res.data.data);
        setLoading(false);
        setSentstatus(true);
      })
      .catch((err) => {
        setResetEmail("");
        setLoading(false);
        toast.error(err.response.data.error || "Request failed!");
      });
  };
  const handleOTPVerification = (event) => {
    event.preventDefault();
    setLoading(true);
    if (sentStatus) {
      axios
        .patch(
          `https://emillionsnft.com/api/email/change`,
          { otp: otp },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast.success("Successfully verified!!");
          setResetEmail("");
          setOtp("");
          setLoading(false);
          window.location.href = "/login";
          dispatch(loginAction.removeData());
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.error || "Request failed!");
        });
    }
  };

  const userDetailsHandler = () => {
    axios
      .get(`https://emillionsnft.com/api/me`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setUserDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    userDetailsHandler();
  }, []);

  return (
    <div className="dashboard_profile-tab">
      {loading && <LoadingSpinner />}
      <section>
        <div className="title">Your Profile</div>
        <div
          className="update_profile"
          style={{ margin: "1rem 0", marginBottom: "2.5rem" }}
        >
          <h3>Wallet Address</h3>
          <p style={{ fontSize: "1.2rem", marginTop: "0.5rem" }}>
            {walletAddress}
          </p>
        </div>
        <div className="section_container">
          <div className="profile_left">
            <form onSubmit={handleChangePassword}>
              <div className="update_profile">
                <label>Change Password</label>
                <input
                  name="email"
                  value={email}
                  placeholder="janedoe@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  disabled={true}
                />
                <button type="submit" className="btn">
                  Send Code
                </button>
              </div>
            </form>
            <div>
              <form onSubmit={handleResetEmail}>
                <div className="update_profile">
                  <label>Change Email</label>
                  <input
                    name="email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    placeholder="newjane@gmail.com"
                    type="email"
                  />
                  <button type="submit" className="btn">
                    Send Code
                  </button>
                </div>
              </form>
              <form onSubmit={handleOTPVerification}>
                <div className="update_profile">
                  <label>Verification Code</label>
                  <input
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    type="number"
                    required
                  />
                  <button
                    type="submit"
                    className={`btn ${!sentStatus ? "disabled" : ""}`}
                    disabled={!sentStatus}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          {userDetails && userDetails.role && userDetails.role !== "buyer" && (
            <form onSubmit={handleProfileSubmit}>
              <div className="profile_right">
                <div className="column-2">
                  <div className="profile_form-group">
                    <label>Your First Name</label>
                    <input
                      name="firstName"
                      value={profile.firstName}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                  <div className="profile_form-group">
                    <label>Your Last Name</label>
                    <input
                      name="lastName"
                      value={profile.lastName}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="profile_form-group">
                  <label>
                    <ToolTip data="Alias will only be assigned to wallet transactions" />{" "}
                    Alias Name
                  </label>
                  <input
                    name="aliasName"
                    value={profile.aliasName}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
                <div className="profile_form-group">
                  <label>Biography</label>
                  <textarea
                    name="biography"
                    value={profile.biography}
                    onChange={handleInputChange}
                    type="text"
                    minLength="100"
                  ></textarea>
                </div>
                <div className="profile_form-group">
                  <div className="select-asset ">
                    <input
                      name="imageUpload"
                      id="imageUpload"
                      type="file"
                      accept="image/*"
                      onChange={captureFile}
                    />
                    <label htmlFor="imageUpload" className="select-asset-image">
                      {profile && profile.profileImage ? (
                        <div
                          className="select-asset-image"
                          style={{
                            backgroundImage: `url(${
                              profileImage || profile.profileImage
                            })`,
                          }}
                        ></div>
                      ) : (
                        <i className="fas fa-image"></i>
                      )}
                      {/* {profile&&profileImage ? (
                      ["image/jpeg", "image/png"].includes(file.type) ? (
                        <div
                          className="select-asset-image"
                          style={{ backgroundImage: `url(${profileImage})` }}
                        ></div>
                      ) : (
                        <p>
                          <span className="file-name">
                            {file.name.substring(0, 15)}
                          </span>{" "}
                          ...
                        </p>
                      )
                    ) : (
                      <i className="fas fa-image"></i>
                    )} */}
                    </label>
                  </div>
                  <div className="select-asset-error">{error}</div>
                </div>

                <div className="profile_submit">
                  <button type="submit">SUBMIT</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </section>
    </div>
  );
};

export default Profile;

const ToolTip = ({ data }) => {
  return (
    <div className="signup__tooltip">
      <BsQuestionCircleFill />
      <div className="tooltip__text">{data ? data : "No tool tip"}</div>
    </div>
  );
};
