import React, { useState, useEffect } from "react";
import Pattern from "../../assets/images/artist-hero.svg";
import Artwork from "./artwork";
import Purchases from "./purchases";
import Collections from "./collections";
import Profile from "./profile";
import { useWeb3React } from "@web3-react/core";
import MyWallet from "./myWallet";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const DashBoard = () => {
  const loginData = useSelector((state) => state.data);
  const [tab, setTab] = useState(1);
  var [role, setRole] = useState("");
  const [collections, setCollections] = useState();

  const { account } = useWeb3React();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const fetchCollection = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    axios
      .get(`https://emillionsnft.com/api/minted?ownerAddr=${account}`, {
        headers: headers,
      })
      .then((res) => {
        var data = res.data;
        const unique = [...new Set(data.map((item) => item.collections))];
        setCollections(unique?.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (account) {
      fetchCollection();
    }
  }, [account]);

  useEffect(() => {
    setTab(3);
    let user_role = parseJwt(localStorage.getItem("token"))?.role;
    if (user_role === "admin") {
      setTab(2);
    } else if (user_role === "buyer") {
      setTab(3);
    }
    setRole(user_role);
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-hero">
        <img src={Pattern} alt="" />
      </div>
      <div className="dashboard-container">
        <div className="dashboard-dashboard">
          <div className="title">Dashboard</div>
          {role !== "buyer" && (
            <div className="button">
              <button>
                <a
                  href={`${loginData.portfolioLink}`}
                  style={{ textDecoration: "none", color: "#fff" }}
                  target={"_blank"}
                >
                  View your portfolio
                </a>
              </button>
            </div>
          )}
        </div>
        <div className="dashboard-tabs">
          <div className="tabs-header">
            {role === "artist" || role === "both" ? (
              <div
                className={`dashboard-tab ${tab === 1 ? "active" : ""}`}
                onClick={() => setTab(1)}
              >
                Your Artwork
              </div>
            ) : null}
            {role !== "buyer" ? (
              <div
                className={`dashboard-tab ${tab === 2 ? "active" : ""}`}
                onClick={() => setTab(2)}
              >
                Your Collection
              </div>
            ) : null}
            <div
              className={`dashboard-tab ${tab === 3 ? "active" : ""}`}
              onClick={() => setTab(3)}
            >
              Your Profile
            </div>
            <div
              className={`dashboard-tab ${tab === 4 ? "active" : ""}`}
              onClick={() => setTab(4)}
            >
              Your Purchases
            </div>
            <div
              className={`dashboard-tab ${tab === 5 ? "active" : ""}`}
              onClick={() => setTab(5)}
            >
              Your Wallet
            </div>
          </div>
          <div className="tabs-content">
            {role === "artist" || role === "both"
              ? tab === 1 && <Artwork />
              : null}
            {role !== "buyer"
              ? tab === 2 && <Collections collections={collections} />
              : null}
            {tab === 3 && <Profile />}
            {tab === 4 && <Purchases />}
            {tab === 5 && <MyWallet />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
