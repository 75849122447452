import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/js/swiper.esm";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.min.css";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";

let params = {
  modules: [Pagination, Navigation],
  slidesPerView: 1,
  spaceBetween: 30,
  loop: false,
  pagination: { el: ".swiper-pagination", clickable: true },
  breakpoints: {
    1470: { slidesPerView: 3 },
    760: { slidesPerView: 2 },
    460: { slidesPerView: 1 },
  },
};

const PurchaseCard = ({ data }) => {
  return (
    <div className="purchase-card">
      <div className="background-image">
        {data?.fileType === "image" && <img src={data?.ipfsLink} alt="" />}
        {data?.fileType === "video" && (
          <video src={data?.ipfsLink} autoPlay loop></video>
        )}
      </div>
      <div className="card-info">
        <div className="card-title">{data?.title}</div>
        <div className="card-sub-title">{data?.collections}</div>
        <div className="card-para">
          {data?.description.length > 80
            ? `${data?.description.slice(0, 80)}...`
            : data?.description}{" "}
        </div>

        <div className="card-price">
          <button className="btn">Purchased</button>
          <p>{data?.nftOrderPrice} ETH</p>
        </div>
        {/* <div className="txn-details">
                    <button className="btn" >Transaction Details</button>
                </div> */}
      </div>
    </div>
  );
};

const Purchases = () => {
  const swiperRef = useRef(null);
  const { account } = useWeb3React();
  const [purchases, setPurchases] = useState();

  // const goNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext();
  //   }
  // };

  // const goPrev = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev();
  //   }
  // };

  const fetchPurchases = () => {
    axios
      .get(
        `https://emillionsnft.com/api/minted?status[ne]=mint&ownerAddr=${account}`
      )
      .then((response) => {
        setPurchases(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (account) fetchPurchases();
  }, [account]);

  return (
    <div className="dashboard_purchase-tab">
      <section>
        <div className="cards">
          <Swiper ref={swiperRef} {...params}>
            {purchases &&
              purchases.map((data, index) => (
                <div key={index}>
                  <PurchaseCard data={data} />
                </div>
              ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default Purchases;
